import { useEffect } from 'react';

import { getRootAppNode } from 'shared/utils/get-root-node';

const events = [
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'scroll'
];

export const useEventsTracking = (actionCallback, deps) => {
  useEffect(() => {
    events.forEach((item) =>
      getRootAppNode().addEventListener(item, actionCallback)
    );

    return () =>
      events.forEach((item) =>
        getRootAppNode().removeEventListener(item, actionCallback)
      );
  }, [deps, actionCallback]);
};
