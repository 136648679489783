export interface TreeAccessState {
  userAccessList: DTO.UserAccessData[];
  allUserList: DTO.UserAccessData[];
  isLoading: boolean;
  lastRequestTime: number | null;
}

export const GET_USER_ACCESS_DATA_PENDING = 'GET_USER_ACCESS_DATA_PENDING';
export const GET_USER_ACCESS_DATA_FULFILLED = 'GET_USER_ACCESS_DATA_FULFILLED';
export const GET_USER_ACCESS_DATA_REJECTED = 'GET_USER_ACCESS_DATA_REJECTED';

export const POST_TREE_ACCESS_PENDING = 'POST_TREE_ACCESS_PENDING';
export const POST_TREE_ACCESS_FULFILLED = 'POST_TREE_ACCESS_FULFILLED';
export const POST_TREE_ACCESS_REJECTED = 'POST_TREE_ACCESS_REJECTED';

export const SET_LAST_REQUEST_TIME = 'SET_LAST_REQUEST_TIME';

export type GetUserAccessDataAction = Store.PaylodedAction<{
  data: DTO.UserAccessData[];
  lastRequestTime: number;
}>;

export type SetLastRequestTimeAction = Store.PaylodedAction<number>;
export type TreeAccessActionTypes =
  | GetUserAccessDataAction
  | SetLastRequestTimeAction;
