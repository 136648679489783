import { RootState } from '../reducers/rootReducer';

export const selectTreeVersionInstance = ({ treeVersion }: RootState) =>
  treeVersion.treeVersionInstance;
export const selectTreeVersionInstanceLoading = ({ treeVersion }: RootState) =>
  treeVersion.isTreeInstanceLoading;
export const selectTreeVersionListByTreeId = ({ treeVersion }: RootState) =>
  treeVersion.treeVersionListByTreeId;
export const selectIsTreeVersionListByTreeIdLoaded = ({
  treeVersion
}: RootState) => treeVersion.isTreeVersionListByTreeIdLoaded;
export const selectIsTreeVersionListByTreeIdLoading = ({
  treeVersion
}: RootState) => treeVersion.isTreeVersionListByTreeIdLoading;
export const selectSavedTreeVersion = ({ treeVersion }: RootState) =>
  treeVersion.savedTreeVersion;
export const selectCountries = ({ treeVersion }: RootState) =>
  treeVersion.countries;
export const selectIsCountriesLoaded = ({ treeVersion }: RootState) =>
  treeVersion.isCountriesLoaded;
export const selectIsCountriesLoading = ({ treeVersion }: RootState) =>
  treeVersion.isCountriesLoading;
export const selectIsActivateVersionLoading = ({ treeVersion }: RootState) =>
  treeVersion.isActivateVersionLoading;
export const selectIsSavedTreeVersionLoading = ({ treeVersion }: RootState) =>
  treeVersion.isSavedTreeVersionLoading;

export const selectTreeVersionDraft = ({ treeVersion }: RootState) =>
  treeVersion.treeVersionDraft;

export const selectTreeVersionDraftLoading = ({ treeVersion }: RootState) =>
  treeVersion.treeVersionDraftLoading;
