import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { getRootAppNode } from 'shared/utils/get-root-node';

import { AppContainer } from './AppContainer';
import { authProvider } from './authProvider';
import './index.css';
import { store } from './store/configureStore';
// eslint-disable-next-line import/imports-first
import 'semantic-ui-css/semantic.min.css';
import './i18n';
import './styles/default.scss';

const root = ReactDOM.createRoot(getRootAppNode());

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MsalProvider instance={authProvider}>
        <AppContainer />
      </MsalProvider>
    </BrowserRouter>
  </Provider>
);
