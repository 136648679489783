import React, { FC } from 'react';

import { CommonIconPropsI } from './common-icon-props.type';

export const SplitIcon: FC<CommonIconPropsI> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.42085 7.1709L8.33752 4.25423C8.54002 4.05173 8.85434 4.02923 9.08169 4.18673L9.16248 4.25423L12.0791 7.1709C12.307 7.3987 12.307 7.76805 12.0791 7.99585C11.8767 8.19835 11.5623 8.22085 11.335 8.06335L11.2542 7.99585L9.33333 6.07487V9.33337C9.33333 11.3001 10.5797 13.6453 13.1072 16.3425L13.4167 16.6678L13.7261 16.3425C16.1483 13.7577 17.3939 11.4961 17.4935 9.58118L17.5 9.33337V6.07487L15.5791 7.99585C15.3767 8.19835 15.0623 8.22085 14.835 8.06335L14.7542 7.99585C14.5517 7.79336 14.5292 7.47903 14.6867 7.25168L14.7542 7.1709L17.6709 4.25423C17.8733 4.05173 18.1877 4.02923 18.415 4.18673L18.4958 4.25423L21.4125 7.1709C21.6403 7.3987 21.6403 7.76805 21.4125 7.99585C21.21 8.19835 20.8957 8.22085 20.6683 8.06335L20.5875 7.99585L18.6667 6.07487V9.33337C18.6667 11.7643 17.1533 14.4995 14.167 17.57L14 17.738V23.9167C14 24.2031 13.7936 24.4413 13.5215 24.4906L13.4167 24.5C13.1303 24.5 12.8921 24.2937 12.8427 24.0216L12.8333 23.9167L12.8326 17.7397C9.8468 14.7124 8.28072 12.0088 8.17267 9.59983L8.16667 9.33337V6.07487L6.24581 7.99585C6.04332 8.19835 5.72899 8.22085 5.50164 8.06335L5.42085 7.99585C5.21836 7.79336 5.19586 7.47903 5.35336 7.25168L5.42085 7.1709L8.33752 4.25423L5.42085 7.1709Z'
        fill='black'
      />
    </svg>
  );
};
