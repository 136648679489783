import {
  GET_USER_ACCESS_DATA_FULFILLED,
  GET_USER_ACCESS_DATA_PENDING,
  GET_USER_ACCESS_DATA_REJECTED,
  POST_TREE_ACCESS_FULFILLED,
  POST_TREE_ACCESS_PENDING,
  POST_TREE_ACCESS_REJECTED,
  SET_LAST_REQUEST_TIME
} from './treeAccess.types';
import { userApi, treeAccessApi } from '../../services';

import { handleErrors } from '../global/global.actions';

export const getUserDataListPending = () => ({
  type: GET_USER_ACCESS_DATA_PENDING
});

export const getUserDataListFulfilled = (payload: {
  data: DTO.UserAccessData;
  lastRequestTime: number;
}) => ({
  type: GET_USER_ACCESS_DATA_FULFILLED,
  payload
});

export const getUserDataListRejected = () => ({
  type: GET_USER_ACCESS_DATA_REJECTED
});

export const postTreeAccessPending = () => ({
  type: POST_TREE_ACCESS_PENDING
});

export const postTreeAccessFulfilled = () => ({
  type: POST_TREE_ACCESS_FULFILLED
});

export const postTreeAccessRejected = () => ({
  type: POST_TREE_ACCESS_REJECTED
});

export const setLastRequestTime = (payload) => ({
  type: SET_LAST_REQUEST_TIME,
  payload
});

export const getUserAccessDataList = (keyword?: string) => async (dispatch) => {
  try {
    dispatch(getUserDataListPending());
    const lastRequestTime = Date.now();
    dispatch(setLastRequestTime(lastRequestTime));
    const res = keyword
      ? await userApi.searchForUser(keyword)
      : await userApi.getApprovalUsers();
    dispatch(getUserDataListFulfilled({ data: res, lastRequestTime }));
  } catch (error) {
    dispatch(handleErrors(error));
    dispatch(getUserDataListRejected());
  }
};

export const postTreeAccess =
  (data: DTO.PostTreeAccessItem[], keyword?: string) => async (dispatch) => {
    try {
      dispatch(postTreeAccessPending());
      await treeAccessApi.postTreeAccess(data);
      dispatch(postTreeAccessFulfilled());
      await dispatch(getUserAccessDataList(keyword));
    } catch (error) {
      dispatch(handleErrors(error));
      dispatch(postTreeAccessRejected());
    }
  };
