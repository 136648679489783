import React, { FC } from 'react';
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';
import { Button, ButtonProps } from 'semantic-ui-react';
import './ThemedButtonOld.scss';

interface Props extends ButtonProps {
  onClick?: () => void;
  className?: string;
  size?: SemanticSIZES;
  isFilled?: boolean;
  disabled?: boolean;
  type?: string;
  isLoading?: boolean;
  analyticsAttribute?: Record<string, string>;
}

export const ThemedButtonOld: FC<Props> = ({
  className,
  isFilled,
  onClick = () => {},
  size = 'small',
  disabled,
  type,
  isLoading,
  analyticsAttribute,
  children,
  ...props
}) => {
  const defaultClassName = isFilled
    ? 'ui themed-button-old filled'
    : 'ui themed-button-old';

  const button = (
    <Button
      loading={isLoading}
      size={size || 'small'}
      className={`${defaultClassName} ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}
    >
      {children}
    </Button>
  );

  if (analyticsAttribute) {
    return <a {...analyticsAttribute}>{button}</a>;
  }

  return button;
};
