import { axiosService } from '../axios.service';

class countrySettingsApi {
  static getCountrySettings(): Promise<DTO.CountrySettings> {
    return axiosService
      .instance({
        url: 'countrySettings'
      })
      .then(({ data }) => data);
  }
}

export default countrySettingsApi;
