import { HubConnectionBuilder } from '@microsoft/signalr';

import { getWindow, getCookie } from '../shared/utils/commonUtils';
import { getFromSessionStorage } from '../shared/utils/storageUtils';
import {
  COUNTRY_COOKIE_KEY,
  TOKEN_KEY
} from '../shared/constants/commonConstants';

class SignalrService {
  getConnection = ({ url = '' }) => {
    const userCountry = getCookie(COUNTRY_COOKIE_KEY);

    const hubsUrl = userCountry
      ? getWindow().APP_CONFIG.COUNTRY_URLS[userCountry].HUBS
      : getWindow().APP_CONFIG.HUBS_URL;

    return new HubConnectionBuilder()
      .withUrl(`${hubsUrl}${url}`, {
        accessTokenFactory: () =>
          getFromSessionStorage<DTO.AuthTokenValue>(TOKEN_KEY)?.value ?? ''
      })
      .withAutomaticReconnect()
      .build();
  };
}

export const signalrService = new SignalrService();
