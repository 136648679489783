import { Reducer } from 'redux';

import {
  GET_USER_ACCESS_DATA_FULFILLED,
  GET_USER_ACCESS_DATA_PENDING,
  GET_USER_ACCESS_DATA_REJECTED,
  GetUserAccessDataAction,
  POST_TREE_ACCESS_FULFILLED,
  POST_TREE_ACCESS_PENDING,
  POST_TREE_ACCESS_REJECTED,
  SET_LAST_REQUEST_TIME,
  SetLastRequestTimeAction,
  TreeAccessActionTypes,
  TreeAccessState
} from './treeAccess.types';

const initialState: TreeAccessState = {
  userAccessList: [],
  lastRequestTime: null,
  isLoading: false,
  allUserList: []
};

const treeAccessReducer: Reducer<TreeAccessState, TreeAccessActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_USER_ACCESS_DATA_PENDING: {
      return { ...state, isLoading: true };
    }
    case GET_USER_ACCESS_DATA_FULFILLED: {
      const { data, lastRequestTime } = (action as GetUserAccessDataAction)
        .payload;
      if (lastRequestTime === state.lastRequestTime) {
        return {
          ...state,
          isLoading: false,
          userAccessList: data,
          allUserList: state.allUserList.length === 0 ? data : state.allUserList
        };
      }
      return state;
    }

    case GET_USER_ACCESS_DATA_REJECTED: {
      return { ...state, isLoading: false };
    }

    case POST_TREE_ACCESS_PENDING: {
      return { ...state, isLoading: true };
    }
    case POST_TREE_ACCESS_FULFILLED: {
      return { ...state, isLoading: false };
    }
    case POST_TREE_ACCESS_REJECTED: {
      return { ...state, isLoading: false };
    }
    case SET_LAST_REQUEST_TIME: {
      return {
        ...state,
        lastRequestTime: (action as SetLastRequestTimeAction).payload
      };
    }
    default: {
      return state;
    }
  }
};

export default treeAccessReducer;
