// TODO: get rid of this declaration
import { ThemedSelectOptionI } from '../../components/common/ThemedSelect';

declare const window: any;

export const TOKEN_KEY = 'accessToken';
export const COUNTRY_COOKIE_KEY = 'userCountry';
export const MAX_MEMO_FILES = 10;
export const MAX_MEMO_FILE_SIZE = 20971520;

export enum USER_TYPES {
  USER = 'user',
  ADMIN = 'admin',
  GLOBAL_ADMIN = 'globalAdmin'
}

export const tAdminTypes = {
  globalAdmin: 'globalAdmin',
  admin: 'countryAdmin'
};

export enum GLOBAL_SETTING_TYPE {
  UNKNOWN = 'Unknown',
  AUDIT = 'AccessAuditPractitioners'
}

export enum TABLE_TYPES {
  MEMO = 'memo',
  SIMILAR_MEMO = 'similarMemo',
  TREE = 'tree',
  TREE_VERSIONS = 'treeVersions',
  ACCOUNTING_STANDARD_ADMINISTRATION = 'accountingStandardAdministration',
  USER_ADMINISTRATION = 'userAdministration',
  GLOBAL_ADMIN_USER_ADMINISTRATION = 'globalAdminUserAdministration'
}

export enum TABLE_COLUMN_WIDTH {
  '5%' = 'cell-width-5',
  '10%' = 'cell-width-10',
  '15%' = 'cell-width-15',
  '20%' = 'cell-width-20',
  '25%' = 'cell-width-25',
  '30%' = 'cell-width-30',
  '50%' = 'cell-width-50'
}

export enum APPROVAL_STATUS {
  PENDING = '1',
  APPROVED = '2',
  REJECTED = '3'
}

export enum MEMO_STATUS {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'InProgress',
  ARCHIVED = 'Archived',
  DELETED = 'Deleted'
}

export enum MEMO_STATUS_SUMMARY {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress'
}

export enum AI_MEMO_STATUS {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export enum MENU_POSITION {
  BL = 'bottom-left',
  BR = 'bottom-right'
}

export enum WS_MEMO_TYPE {
  MEMO_UPDATED = 'memoUpdated',
  MEMO_COMPLETED = 'memoCompleted'
}

export const DocxType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const PREVENT_LOAD_DASHBOARD = 'PREVENT_LOAD_DASHBOARD';

export enum ACCESS_OPTIONS {
  OPEN = 'Open',
  RESTRICTED = 'Restricted'
}

export const accessModeOptions: ThemedSelectOptionI[] = [
  {
    label: 'MF Open Access',
    value: ACCESS_OPTIONS.OPEN
  },
  {
    label: 'MF Restricted Access',
    value: ACCESS_OPTIONS.RESTRICTED
  }
];

export const TEST_AZURE_APP_INSIGHTS_INSTRUMENTATION_KEY =
  '837cc044-9240-4865-852f-34e374ccfed2';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
