import React, { FC } from 'react';

import ErrorIcon from '../../../icons/ErrorIcon';
import InfoToastIcon from '../../../icons/InfoToastIcon';
import './Toast.scss';

interface Props {
  type: 'success' | 'error';
  message: string;
}

const Toast: FC<Props> = ({ type, message }) => {
  const iconTypeMap = {
    success: <InfoToastIcon />,
    error: <ErrorIcon />
  };
  return (
    <div className='toast'>
      <div className='toast__icon'>{iconTypeMap[type]}</div>
      {message}
    </div>
  );
};

export default Toast;
