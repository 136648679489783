import {
  FilterParams,
  SortParams
} from '../../components/common/ThemedTable/ThemedTableHelpers';

export const SET_TABLE_SORT_COLUMN = 'SET_TABLE_SORT_COLUMN';

export type TableState = Readonly<{
  filters: FilterParams[];
  sortColumn: SortParams | null;
}>;

export type LoadTableAction = Store.PaylodedAction<any>;

export type TableActionTypes = LoadTableAction;
