import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ReactPlugin,
  AppInsightsContext
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import { selectAppInsightsInstrumentationKey } from 'store/global/global.selectors';

const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();

const AppInsightsProvider: FC = ({ children }) => {
  const connectionString = useSelector(selectAppInsightsInstrumentationKey);
  useEffect(() => {
    if (connectionString) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history }
          },
          isCookieUseDisabled: true,
          enableSessionStorageBuffer: true,
          enableAutoRouteTracking: false, // option to log all route changes
          disableAjaxTracking: true,
          disableFetchTracking: true
        }
      });
      appInsights.loadAppInsights();
    }
  }, [connectionString]);
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default AppInsightsProvider;
