import { Reducer } from 'redux';

import {
  TableActionTypes,
  TableState,
  SET_TABLE_SORT_COLUMN
} from './table.types';

const initialState: TableState = {
  filters: [],
  sortColumn: null
};

const tableReducer: Reducer<TableState, TableActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TABLE_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.payload
      };

    default:
      return state;
  }
};

export default tableReducer;
