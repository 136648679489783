import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

import { authProvider } from '../../../authProvider';
import { userApi } from '../../../services';
import { axiosService } from '../../../services/axios.service';
import { TOKEN_KEY } from '../../../shared/constants/commonConstants';
import {
  POPUP_TIMEOUT,
  SESSION_EXPIRES_AT,
  SHOW_LOGOUT_POPUP_AT,
  TIMEOUT_TO_KEEP_ALIVE,
  USER_ACTIVITY
} from '../../../shared/constants/loginConstants';
import { useEventsTracking } from '../../../shared/hooks/useEventsTracking';
import { useOnMount } from '../../../shared/hooks/useOnMount';
import { useTimeInterval } from '../../../shared/hooks/useTimeInterval';
import {
  calculateEndTime,
  getDifferenceInSecondsFromNow,
  getFromLocalStorage,
  hasTimeExpired,
  removeFromLocalStorage,
  setToLocalStorage
} from '../../../shared/utils/storageUtils';

import './LogoutWarningPopup.scss';

interface Props {
  originalLogoutTimeout: number;
  originalPopupTimeout: number;
}

const LogoutWarningPopup: FC<Props> = ({
  originalLogoutTimeout,
  originalPopupTimeout
}) => {
  const [isLogoutPopupOpened, setIsLogoutPopupOpened] = useState(false);
  const { t } = useTranslation();

  const [popupTimeout, setPopupTimeout] = useState(originalPopupTimeout);
  const [, setTimeoutToKeepAlive] = useState(TIMEOUT_TO_KEEP_ALIVE);

  const countDownTimeoutToKeepAlive = () => {
    setTimeoutToKeepAlive((prevState) => {
      if (prevState > 0) {
        return prevState - 1;
      }

      handleSilentRenew();
      return prevState;
    });
  };

  const {
    startInterval: startActivityInterval,
    stopInterval: stopActivityInterval
  } = useTimeInterval({
    actionCallback: countDownTimeoutToKeepAlive
  });

  const renewActivityInterval = () => {
    removeFromLocalStorage(USER_ACTIVITY);
    startActivityInterval();
    setTimeoutToKeepAlive(TIMEOUT_TO_KEEP_ALIVE);
  };

  const logoutActionCallBack = (): void => {
    const storageLogoutTimeout =
      getFromLocalStorage<string>(SHOW_LOGOUT_POPUP_AT);
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime =
      getFromLocalStorage<string>(SESSION_EXPIRES_AT);
    const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);

    if (storageUserActivity) {
      renewActivityInterval();
    }

    if (!isLogoutPopupOpened) {
      logoutOnSessionExpiration();

      if (
        (storageLogoutTimeout && hasTimeExpired(storageLogoutTimeout)) ||
        storagePopupTimeout
      ) {
        handleOpenPopup();
      } else if (!storageLogoutTimeout || !storageSessionExpirationTime) {
        initTimeouts();
      }
    }
  };

  const popupActionCallback = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    logoutOnSessionExpiration();

    if (isLogoutPopupOpened) {
      if (popupTimeout !== 0) {
        if (storagePopupTimeout) {
          setPopupTimeout((prevState) => prevState - 1);
        } else {
          setIsLogoutPopupOpened(false);
          stopPopupInterval();
          setPopupTimeout(originalPopupTimeout);
          startCheckingInterval();
        }
      } else {
        handleLogout();
      }
    }
  };

  const {
    stopInterval: stopCheckingInterval,
    startInterval: startCheckingInterval
  } = useTimeInterval({
    actionCallback: logoutActionCallBack,
    timeout: 500
  });

  const { stopInterval: stopPopupInterval, startInterval: startPopupInterval } =
    useTimeInterval({
      actionCallback: popupActionCallback
    });

  const logoutOnSessionExpiration = () => {
    const storageSessionExpirationTime =
      getFromLocalStorage<string>(SESSION_EXPIRES_AT);
    if (
      storageSessionExpirationTime &&
      hasTimeExpired(storageSessionExpirationTime)
    ) {
      handleLogout();
    }
  };

  const initTimeouts = useCallback(() => {
    setToLocalStorage(
      SHOW_LOGOUT_POPUP_AT,
      calculateEndTime(originalLogoutTimeout - originalPopupTimeout)
    );

    setToLocalStorage(
      SESSION_EXPIRES_AT,
      calculateEndTime(originalLogoutTimeout)
    );
  }, [originalLogoutTimeout, originalPopupTimeout]);

  useOnMount(() => {
    const storageLogoutTimeout =
      getFromLocalStorage<string>(SHOW_LOGOUT_POPUP_AT);
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime =
      getFromLocalStorage<string>(SESSION_EXPIRES_AT);

    logoutOnSessionExpiration();

    if (storagePopupTimeout) {
      handleOpenPopup();
    } else {
      if (!storageLogoutTimeout && !storageSessionExpirationTime) {
        initTimeouts();
      }
      startCheckingInterval();
    }
  });

  const handleOpenPopup = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);

    if (!storagePopupTimeout) {
      setToLocalStorage(POPUP_TIMEOUT, calculateEndTime(originalPopupTimeout));
    } else {
      setPopupTimeout(getDifferenceInSecondsFromNow(storagePopupTimeout));
    }

    removeFromLocalStorage(SHOW_LOGOUT_POPUP_AT);
    stopCheckingInterval();
    setIsLogoutPopupOpened(true);
    startPopupInterval();
  };

  const logoutInTimeout = () => {
    setTimeout(() => authProvider.logoutRedirect(), 2000);
  };

  const handleLogout = useCallback(() => {
    setIsLogoutPopupOpened(false);
    setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime());
    logoutInTimeout();
  }, []);

  const requestKeepAlive = useCallback(async () => {
    stopActivityInterval();
    const response = await userApi.keepAlive();
    const accessToken = {
      value: response.accessToken,
      expiredAt: new Date(response.expiresAt).toISOString()
    };

    axiosService.setNewDefaults(response.accessToken);
    setToLocalStorage(TOKEN_KEY, accessToken);
  }, [stopActivityInterval]);

  const handleSilentRenew = useCallback(() => {
    requestKeepAlive();
    initTimeouts();
    startCheckingInterval();
  }, [initTimeouts, requestKeepAlive, startCheckingInterval]);

  const stopAllIntervals = useCallback(() => {
    stopCheckingInterval();
    stopPopupInterval();
  }, [stopCheckingInterval, stopPopupInterval]);

  const handleContinueSession = useCallback(() => {
    removeFromLocalStorage(POPUP_TIMEOUT);
    stopAllIntervals();
    setPopupTimeout(originalPopupTimeout);
    setIsLogoutPopupOpened(false);
    handleSilentRenew();
  }, [
    originalPopupTimeout,
    setIsLogoutPopupOpened,
    handleSilentRenew,
    stopAllIntervals
  ]);

  const handleEventsTracking = () => {
    const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);

    if (!storageUserActivity) {
      setToLocalStorage(USER_ACTIVITY, true);
    }
  };

  useEventsTracking(handleEventsTracking);

  return (
    <Modal
      basic={true}
      open={isLogoutPopupOpened}
      className='logout-warning-popup'
      onClose={handleContinueSession}
      dimmer={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100000000 }
      }}
      closeOnDimmerClick={false}
    >
      <h3 className='logout-warning-popup__title'>{t('logout popup title')}</h3>
      <button
        type='button'
        className='logout-warning-popup__close-button'
        onClick={handleContinueSession}
      >
        <svg viewBox='0 0 12 12' className='logout-warning-popup__close-icon'>
          <line x1='0' y1='0' x2='12' y2='12' />
          <line x1='12' y1='0' x2='0' y2='12' />
        </svg>
      </button>
      <p className='logout-warning-popup__message'>
        {t('logout popup message', { seconds: popupTimeout })}
      </p>
      <div className='logout-warning-popup__buttons-wrapper'>
        <Button className='logout-warning-popup__button' onClick={handleLogout}>
          {t('button text yes')}
        </Button>
        <Button
          className='logout-warning-popup__button logout-warning-popup__button_filled'
          onClick={handleContinueSession}
        >
          {t('button text no')}
        </Button>
      </div>
    </Modal>
  );
};

export default LogoutWarningPopup;
