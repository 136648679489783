export const FEATURE_LOADING = 'FEATURE_LOADING';
export const FEATURE_SUCCESS = 'FEATURE_SUCCESS';
export const FEATURE_REJECTED = 'FEATURE_REJECTED';
export const POST_FEATURE_LOADING = 'POST_FEATURE_LOADING';
export const POST_FEATURE_SUCCESS = 'POST_FEATURE_SUCCESS';
export const POST_FEATURE_REJECTED = 'POST_FEATURE_REJECTED';

export type FeatureState = Readonly<{
  isFeatureLoading: boolean;
  isPostFeatureLoading: boolean;
  featureList: DTO.Feature[];
}>;
