export enum USER_PATH {
  ROOT = '/',
  AUTH = '/authcallback',
  HOME = '/home',
  CREATE = '/create',
  MEMO = '/memo',
  EDIT_MEMO = '/memo/edit/:memoId',
  // TODO: remove last two
  EDIT_MEMO_VERSION = '/edit_memo/:memoId',
  EDIT_DRAFT_VERSION = '/edit_draft/:memoId',
  TREE = '/tree',
  ADMIN = '/admin',
  ERROR = '/error',
  TERMS = '/terms',
  WILDCARD = '*'
}
