import { Dispatch } from 'redux';

import { globalApi } from '../../services/api/globalApi';

import {
  START_REDIRECTION,
  END_REDIRECTION,
  SET_IS_FIRST_INIT,
  SET_GLOBAL_SETTING_PENDING,
  SET_GLOBAL_SETTING_FULFILLED,
  SET_GLOBAL_ENVIRONMENT_CONFIG,
  TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW
} from './global.types';
import { getUserAccessDataList } from '../treeAccess/treeAccess.actions';

export const enableRedirection = (payload: string) => ({
  type: START_REDIRECTION,
  payload
});

export const disableRedirection = {
  type: END_REDIRECTION
};

export const setGlobalEnvironmentConfig = (payload: DTO.EnvironmentConfig) => ({
  type: SET_GLOBAL_ENVIRONMENT_CONFIG,
  payload
});

export const setShouldGoToFirstTabOfThePage = () => ({
  type: TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW
});

export const handleErrors = (error: any) => (dispatch: Dispatch) => {
  const { response } = error;
  if (
    !response ||
    (response.status >= 400 && response.status <= 409) ||
    response.status >= 500
  ) {
    return dispatch(enableRedirection('/error'));
  }

  return null;
};

export const setIsFirstInit = (payload: boolean) => ({
  type: SET_IS_FIRST_INIT,
  payload
});

export const getMFAccessType = () => (dispatch) => {
  dispatch({ type: SET_GLOBAL_SETTING_PENDING });
  return globalApi
    .getMFAccessType()
    .then((res) => {
      return dispatch({
        type: SET_GLOBAL_SETTING_FULFILLED,
        payload: res.isActivated
      });
    })
    .catch((err) => handleErrors(err));
};

export const setMFAccessType =
  ({
    setting,
    isActivated,
    search
  }: {
    setting: DTO.GlobalSettingType;
    isActivated: boolean;
    search: string;
  }) =>
  (dispatch) => {
    dispatch({ type: SET_GLOBAL_SETTING_PENDING });
    return globalApi
      .setMFAccessType({ settingType: setting, isActivated })
      .then(() => {
        return dispatch({
          type: SET_GLOBAL_SETTING_FULFILLED,
          payload: isActivated
        });
      })
      .then(() => {
        if (!isActivated) {
          return dispatch(getUserAccessDataList(search));
        }

        return null;
      })
      .catch((err) => handleErrors(err));
  };

export const getEnvironmentConfig = () => (dispatch) => {
  return globalApi
    .getEnvironmentConfig()
    .then((config) => {
      dispatch(setGlobalEnvironmentConfig(config));
      return config;
    })
    .catch(() => {
      dispatch(setGlobalEnvironmentConfig({} as DTO.EnvironmentConfig));
    });
};
