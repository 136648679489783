import { useEffect, useCallback } from 'react';
import {
  useAppInsightsContext,
  ReactPlugin
} from '@microsoft/applicationinsights-react-js';

import {
  AppInsightsScreenTelemetryTypeE,
  AppInsightsEventTelemetryTypeE
} from './appInsights.enums';
import { getScreenTelemetry, getEventTelemetry } from './appInsights.helpers';

export const useAppInsights = () => useAppInsightsContext();

export const useAppInsightsTrackScreen = (
  screen: AppInsightsScreenTelemetryTypeE
): ReactPlugin => {
  const appInsights = useAppInsights();
  useEffect(() => {
    appInsights.trackPageView(getScreenTelemetry(screen));
  }, [appInsights, screen]);

  return appInsights;
};

export const useAppInsightsTrackEventAsScreen = (
  eventType: AppInsightsEventTelemetryTypeE,
  track: boolean
): ReactPlugin => {
  const appInsights = useAppInsights();
  useEffect(() => {
    if (track) {
      appInsights.trackEvent(getEventTelemetry(eventType));
    }
  }, [appInsights, eventType, track]);

  return appInsights;
};

export const useAppInsightsTrackEvent = (
  reactPlugin: ReactPlugin,
  eventType: AppInsightsEventTelemetryTypeE
) =>
  useCallback(
    () => reactPlugin.trackEvent(getEventTelemetry(eventType)),
    [reactPlugin, eventType]
  );
