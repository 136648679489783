import React, { FC } from 'react';
import { Image } from 'semantic-ui-react';

// import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

// import { selectCurrentUser } from '../../../store/selectors/userSelectors';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoUrl from '../../../images/logo.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import omniaLogo from '../../../images/Omnia-Green.svg';

import './HeaderOmnia.scss';
import UserMenu from './UserMenu';

export const HeaderOmnia: FC = () => {
  // const { user_type, is_TOU_accepted } = useSelector(selectCurrentUser);

  return (
    <div className='top-header'>
      <div className='top-header__nav'>
        <Link to='/memo'>
          <Image className='top-header__logo' src={logoUrl} />
        </Link>
        <Image className='top-header__logo' src={omniaLogo} />
        {/* {user_type &&
          is_TOU_accepted &&
          dataHandler.getMenuItems(user_type).map((item) => {
            return (
              <NavLink
                className='top-header__nav-link'
                key={item.key}
                to={item.url}
              >
                {item.name}
              </NavLink>
            );
          })} */}
      </div>

      <UserMenu />
    </div>
  );
};
