import { axiosService } from '../axios.service';

class memoSharingApi {
  static putMemoShare({
    userIds,
    memoId
  }: {
    userIds: string[];
    memoId: number;
  }): Promise<void> {
    return axiosService
      .instance({
        method: 'put',
        url: `memo/sharing/${memoId}`,
        data: userIds
      })
      .then(({ data }) => data);
  }

  static getMemoSharing(
    userId: string,
    memoId: number
  ): Promise<DTO.MemoSharing[]> {
    return axiosService
      .instance({
        url: `memo/sharing/?memo=${memoId}&user=${userId}`
      })
      .then(({ data }) => data);
  }

  static getMemoShareListByMemoId(memoId: number): Promise<DTO.MemoSharing[]> {
    return axiosService
      .instance({
        url: `memo/sharing/?memo=${memoId}`
      })
      .then(({ data }) => data);
  }

  static getAllMemoSharing(): Promise<DTO.MemoSharing[]> {
    return axiosService
      .instance({
        url: 'memo/sharing/'
      })
      .then(({ data }) => data);
  }
}

export default memoSharingApi;
