import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';

import './Loading.scss';

interface Props {
  text?: string;
  className?: string;
}

export const Loading: FC<Props> = ({ text = '', className = '' }) => {
  const { t } = useTranslation();

  return (
    <Dimmer active={true} inverted={true} className={className}>
      <Loader inverted={true}>{text || t('loading')}</Loader>
    </Dimmer>
  );
};
