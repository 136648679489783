export const SET_IS_LOADED_TOKEN = 'SET_IS_LOADED_TOKEN';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_APPROVAL_USERS_SUCCESS = 'LOAD_APPROVAL_USERS_SUCCESS';
export const CHECK_APPROVAL_USERS_LOADING = 'CHECK_APPROVAL_USERS_LOADING';
export const CHECK_USERS_LOADING = 'CHECK_USERS_LOADING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const LOAD_AUTHENTICATE_LOADING = 'LOAD_AUTHENTICATE_LOADING';
export const LOAD_AUTHENTICATE_SUCCESS = 'LOAD_AUTHENTICATE_SUCCESS';
export const LOAD_AUTHENTICATE_FAIL = 'LOAD_AUTHENTICATE_FAIL';

export const CREATE_TREE_ACCESS_SUCCESS = 'CREATE_TREE_ACCESS_SUCCESS';
export const CHECK_CREATE_TREE_ACCESS_LOADED =
  'CHECK_CREATE_TREE_ACCESS_LOADED';
export const DELETE_TREE_ACCESS_SUCCESS = 'DELETE_TREE_ACCESS_SUCCESS';
export const GET_ALL_TREE_ACCESS_SUCCESS = 'GET_ALL_TREE_ACCESS_SUCCESS';
export const CHECK_ALL_TREE_ACCESS_LOADED = 'CHECK_ALL_TREE_ACCESS_LOADED';
export const GET_TREE_ACCESS_SUCCESS = 'GET_TREE_ACCESS_SUCCESS';

export const LOAD_ALL_CLIENT_INDUSTRY_SUCCESS =
  'LOAD_ALL_CLIENT_INDUSTRY_SUCCESS';
export const LOAD_CLIENT_INDUSTRY_SUCCESS = 'LOAD_CLIENT_INDUSTRY_SUCCESS';
export const CHECK_ALL_CLIENT_INDUSTRY_LOADED =
  'CHECK_ALL_CLIENT_INDUSTRY_LOADED';
export const CREATE_CLIENT_INDUSTRY_SUCCESS = 'CREATE_CLIENT_INDUSTRY_SUCCESS';

export const LOAD_ADMIN_DATA_SUCCESS = 'LOAD_ADMIN_DATA_SUCCESS';
export const CHECK_ADMIN_DATA_LOADED = 'CHECK_ADMIN_DATA_LOADED';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const ONBOARD_USERS_SUCCESS = 'ONBOARD_USERS_SUCCESS';
export const CHECK_UPDATE_ADMIN_LOADED = 'CHECK_UPDATE_ADMIN_LOADED';
export const SET_ADMIN_SEARCH_VALUE = 'SET_ADMIN_SEARCH_VALUE';
export const SET_LAST_ADMIN_SEARCH_REQUEST_TIME =
  'SET_LAST_ADMIN_SEARCH_REQUEST_TIME';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_SETTINGS_LOADING = 'SET_USER_SETTINGS_LOADING';
