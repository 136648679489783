import { treeVersionApi } from '../../services';
import {
  ACTIVATE_VERSION_SUCCESS,
  CHECK_CREATE_TREE_VERSION_LOADED,
  CHECK_CREATE_TREE_VERSION_ERROR,
  CREATE_TREE_VERSION_SUCCESS,
  LOAD_ACTIVATE_VERSION_LOADING,
  LOAD_ACTIVE_TREE_VERSION_SUCCESS,
  LOAD_ALL_TREE_VERSIONS_SUCCESS,
  LOAD_COUNTRIES_SUCCESS,
  LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS,
  CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED,
  LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING,
  LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS,
  GET_TREE_VERSION_DRAFT_PENDING,
  GET_TREE_VERSION_DRAFT_FULFILLED,
  GET_TREE_VERSION_DRAFT_REJECTED,
  POST_TREE_VERSION_DRAFT_PENDING,
  POST_TREE_VERSION_DRAFT_FULFILLED,
  POST_TREE_VERSION_DRAFT_REJECTED,
  DELETE_TREE_VERSION_DRAFT_PENDING,
  DELETE_TREE_VERSION_DRAFT_FULFILLED,
  DELETE_TREE_VERSION_DRAFT_REJECTED,
  LOAD_COUNTRIES_PENDING
} from './treeVersion.types';
import { handleErrors } from '../global/global.actions';
import resources from '../../resources';
import { toastError, toastSuccess } from '../../shared/utils/toastUtils';
import {
  selectCountries,
  selectIsCountriesLoaded,
  selectIsCountriesLoading
} from './treeVersion.selectors';

export function loadAllTreeVersionsSuccess(treeVersions) {
  return { type: LOAD_ALL_TREE_VERSIONS_SUCCESS, payload: treeVersions };
}

export function loadTreeByTreeVersionIdSuccess(treeVersion) {
  return {
    type: LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS,
    payload: treeVersion
  };
}

export function checkTreeByTreeVersionIdLoaded() {
  return {
    type: CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED
  };
}

export function createTreeVersionSuccess(tree) {
  return { type: CREATE_TREE_VERSION_SUCCESS, payload: tree };
}

export function checkCreateTreeVersionLoaded() {
  return { type: CHECK_CREATE_TREE_VERSION_LOADED };
}

export function checkCreateTreeVersionError() {
  return { type: CHECK_CREATE_TREE_VERSION_ERROR };
}

export function loadActiveTreeVersionSuccess(treeVersion) {
  return { type: LOAD_ACTIVE_TREE_VERSION_SUCCESS, payload: treeVersion };
}

export function activateVersionSuccess(treeVersion) {
  return { type: ACTIVATE_VERSION_SUCCESS, payload: treeVersion };
}

export function checkActivateTreeVersionLoading() {
  return { type: LOAD_ACTIVATE_VERSION_LOADING };
}

export function getTreeVersionsByTreeIdSuccess(treeVersions) {
  return {
    type: LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS,
    payload: treeVersions
  };
}

export function checkTreeVersionsByTreeIdLoading() {
  return { type: LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING };
}

export function getCountriesPending(pending: boolean) {
  return {
    type: LOAD_COUNTRIES_PENDING,
    payload: pending
  };
}

export function getCountriesSuccess(countries) {
  return {
    type: LOAD_COUNTRIES_SUCCESS,
    payload: countries
  };
}

export const getTreeVersionDraftPending = () => ({
  type: GET_TREE_VERSION_DRAFT_PENDING
});

export const getTreeVersionDraftRejected = () => ({
  type: GET_TREE_VERSION_DRAFT_REJECTED
});

export const getTreeVersionDraftFulfilled = (
  payload: DTO.TreeVersionDraft
) => ({
  type: GET_TREE_VERSION_DRAFT_FULFILLED,
  payload
});

export const postTreeVersionDraftPending = () => ({
  type: POST_TREE_VERSION_DRAFT_PENDING
});

export const postTreeVersionDraftRejected = () => ({
  type: POST_TREE_VERSION_DRAFT_REJECTED
});

export const postTreeVersionDraftFulfilled = () => ({
  type: POST_TREE_VERSION_DRAFT_FULFILLED
});

export const deleteTreeVersionDraftPending = () => ({
  type: DELETE_TREE_VERSION_DRAFT_PENDING
});

export const deleteTreeVersionDraftRejected = () => ({
  type: DELETE_TREE_VERSION_DRAFT_REJECTED
});

export const deleteTreeVersionDraftFulfilled = () => ({
  type: DELETE_TREE_VERSION_DRAFT_FULFILLED
});

export function loadAllTreeVersions() {
  return (dispatch) => {
    return treeVersionApi
      .getAllTreeVersions()
      .then((treeVersions) =>
        dispatch(loadAllTreeVersionsSuccess(treeVersions))
      )
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function loadTreeVersionByTreeVersionId(treeVersionId) {
  return (dispatch) => {
    dispatch(checkTreeByTreeVersionIdLoaded());
    return treeVersionApi
      .getTreeVersionByTreeVersionId(treeVersionId)
      .then((treeVersion) =>
        dispatch(loadTreeByTreeVersionIdSuccess(treeVersion))
      )
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function loadActiveTreeVersion(treeId) {
  return (dispatch) => {
    return treeVersionApi
      .getActiveTreeVersionByTreeId(treeId)
      .then((treeVersion) =>
        dispatch(loadActiveTreeVersionSuccess(treeVersion))
      )
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export const saveTreeVersion = (tree, treeId: string) => async (dispatch) => {
  dispatch(checkCreateTreeVersionLoaded());
  try {
    const treeResponse = await treeVersionApi.postTree(tree);
    toastSuccess(resources.en.translations['success save']);
    dispatch(createTreeVersionSuccess(treeResponse));
    dispatch(loadTreeVersionsByTreeId(treeId));

    return treeResponse;
  } catch (error: any) {
    dispatch(checkCreateTreeVersionError());
    toastError(error.response.data);
    throw error;
  }
};

export function activateTreeVersion(treeVersionData) {
  return (dispatch) => {
    dispatch(checkActivateTreeVersionLoading());
    return treeVersionApi
      .activateTreeVersionByTreeVersionId(treeVersionData)
      .then((responseTree) => dispatch(activateVersionSuccess(responseTree)))
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function loadTreeVersionsByTreeId(treeId) {
  return (dispatch) => {
    dispatch(checkTreeVersionsByTreeIdLoading());
    return treeVersionApi
      .getTreeVersionsByTreeId(treeId)
      .then((responseTree) =>
        dispatch(getTreeVersionsByTreeIdSuccess(responseTree))
      )
      .catch((error) => {
        dispatch(handleErrors(error));
        throw error;
      });
  };
}

export function loadCountries() {
  return (dispatch, getState) => {
    const state = getState();
    const isCountriesLoaded = selectIsCountriesLoaded(state);
    const isCountriesLoading = selectIsCountriesLoading(state);
    const countries = selectCountries(state);
    if (
      !isCountriesLoading &&
      (!isCountriesLoaded || !countries || !Object.keys(countries).length)
    ) {
      dispatch(getCountriesPending(true));
      return treeVersionApi
        .getCountries()
        .then((countries) => dispatch(getCountriesSuccess(countries)))
        .catch((error) => {
          dispatch(getCountriesPending(false));
          dispatch(handleErrors(error));
        });
    }

    return countries;
  };
}

export const getTreeVersionDraft =
  (treeVersionDraftId: number) => async (dispatch) => {
    try {
      dispatch(getTreeVersionDraftPending());
      const { data } = await treeVersionApi.getTreeVersionDraft(
        treeVersionDraftId
      );
      dispatch(getTreeVersionDraftFulfilled(data));
    } catch (error) {
      dispatch(getTreeVersionDraftRejected());
      dispatch(handleErrors(error));
    }
  };

export const postTreeVersionDraft =
  (data: DTO.PostTreeVersionDraftBody) => async (dispatch) => {
    try {
      dispatch(postTreeVersionDraftPending());
      await treeVersionApi.postTreeVersionDraft(data);
      dispatch(postTreeVersionDraftFulfilled());
    } catch (error) {
      dispatch(postTreeVersionDraftRejected());
      dispatch(handleErrors(error));
    }
  };

export const deleteTreeVersionDraft =
  (treeVersionDraftId: number) => async (dispatch) => {
    try {
      dispatch(deleteTreeVersionDraftPending());
      await treeVersionApi.deleteTreeVersionDraft(treeVersionDraftId);
      dispatch(deleteTreeVersionDraftFulfilled());
    } catch (error) {
      dispatch(getTreeVersionDraftRejected());
      dispatch(handleErrors(error));
    }
  };
