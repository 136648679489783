import { Reducer } from 'redux';

import {
  CHECK_MEMO_FILES_LOADED,
  FileActionTypes,
  FileState,
  GET_ALL_FILES_SUCCESS,
  GET_MEMO_FILES_SUCCESS,
  GET_SUMMARY_REPORT_FULFILLED,
  GET_SUMMARY_REPORT_PENDING,
  GET_SUMMARY_REPORT_REJECTED,
  GET_MEMO_FILE_DOWNLOAD_PENDING,
  GET_MEMO_FILE_DOWNLOAD_SUCCESS,
  GET_MEMO_FILE_DOWNLOAD_REJECTED,
  DOWNLOAD_MEMO_FILE_SUCCESS,
  DOWNLOAD_MEMO_FILE_PENDING,
  DOWNLOAD_MEMO_FILE_REJECTED
} from './file.types';

const initialState: FileState = {
  fileList: [],
  isFileListLoading: false,
  allFileList: [],
  summaryReportLoading: false,
  memoFileDownloading: false,
  memoDocumentDownloading: false
};

const fileReducer: Reducer<FileState, FileActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_MEMO_FILES_SUCCESS:
      return { ...state, fileList: action.payload, isFileListLoading: false };
    case CHECK_MEMO_FILES_LOADED:
      return { ...state, isFileListLoading: true };
    case GET_ALL_FILES_SUCCESS:
      return { ...state, allFileList: action.payload };

    case GET_SUMMARY_REPORT_PENDING:
      return {
        ...state,
        summaryReportLoading: true
      };

    case GET_SUMMARY_REPORT_FULFILLED:
      return {
        ...state,
        summaryReportLoading: false
      };

    case GET_SUMMARY_REPORT_REJECTED:
      return {
        ...state,
        summaryReportLoading: false
      };
    case GET_MEMO_FILE_DOWNLOAD_PENDING:
      return {
        ...state,
        memoFileDownloading: true
      };
    case GET_MEMO_FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        memoFileDownloading: false
      };
    case GET_MEMO_FILE_DOWNLOAD_REJECTED:
      return {
        ...state,
        memoFileDownloading: false
      };

    case DOWNLOAD_MEMO_FILE_PENDING:
      return {
        ...state,
        memoDocumentDownloading: true
      };

    case DOWNLOAD_MEMO_FILE_REJECTED:
      return {
        ...state,
        memoDocumentDownloading: false
      };

    case DOWNLOAD_MEMO_FILE_SUCCESS:
      return {
        ...state,
        memoDocumentDownloading: false
      };

    default:
      return state;
  }
};

export default fileReducer;
