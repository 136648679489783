import { AxiosPromise } from 'axios';
import qs from 'querystring';

import { axiosService } from '../axios.service';
import { authProvider } from '../../authProvider';
import {
  setCookie,
  getCookie,
  isLocalhost
} from '../../shared/utils/commonUtils';
import {
  getFromSessionStorage,
  setToSessionStorage
} from '../../shared/utils/storageUtils';

import {
  TOKEN_KEY,
  COUNTRY_COOKIE_KEY
} from '../../shared/constants/commonConstants';

class userApi {
  static async checkAuthenticateUser(checkTokenCallback) {
    const accessToken =
      getFromSessionStorage<{ value: string; expiredAt: string }>(TOKEN_KEY);
    const userCountry = getCookie(COUNTRY_COOKIE_KEY);
    const isLocalDev = isLocalhost();

    if (
      accessToken?.value &&
      new Date(accessToken.expiredAt) > new Date() &&
      (isLocalDev || userCountry)
    ) {
      if (isLocalDev) {
        axiosService.setNewDefaults(accessToken.value);
      } else {
        axiosService.setNewDefaults(accessToken.value, userCountry);
      }
    } else {
      const { idToken } = await authProvider.acquireTokenSilent();
      await axiosService
        .instance({
          url: 'auth/token',
          method: 'POST',
          data: { token: idToken }
        })
        .then(({ data }) => {
          const accessToken = {
            value: data.accessToken,
            expiredAt: new Date(data.expiresAt).toISOString()
          };
          setToSessionStorage(TOKEN_KEY, accessToken);
          if (isLocalDev) {
            // INFO: prevent call geo related api by country code
            axiosService.setNewDefaults(data.accessToken);
          } else {
            setCookie(COUNTRY_COOKIE_KEY, data.country);
            axiosService.setNewDefaults(data.accessToken, data.country);
          }

          return data;
        });
    }

    if (checkTokenCallback) {
      await checkTokenCallback();
    }

    return axiosService
      .instance({
        url: 'user/current/'
      })
      .then(({ data }) => data);
  }

  static getApprovalUsers() {
    return axiosService.instance({ url: `users/` }).then(({ data }) => data);
  }

  static getAllUsers() {
    return axiosService
      .instance({
        url: `user/`
      })
      .then(({ data }) => data);
  }

  static getUsersForSharing() {
    return axiosService
      .instance({
        url: `user/shareList`
      })
      .then(({ data }) => data);
  }

  static searchForUser(keyword) {
    return axiosService
      .instance({
        url: `user/search`,
        params: { keyword }
      })
      .then(({ data }) => data);
  }

  static getUser(userUrl) {
    return axiosService
      .instance({
        url: `${userUrl}`
      })
      .then(({ data }) => data);
  }

  static createUser(user) {
    return axiosService
      .instance({
        method: 'post',
        url: 'user/',
        data: user
      })
      .then(({ data }) => data);
  }

  static deleteUser(user) {
    return axiosService
      .instance({
        method: 'delete',
        url: `user/${user.id || user.user_id}`
      })
      .then(({ data }) => data);
  }

  static deleteUsers(users: DTO.CommonUserType[]) {
    return axiosService
      .instance({
        method: 'post',
        url: `user/delete`,
        data: users.map(({ user_id }) => user_id)
      })
      .then(({ data }) => data);
  }

  static deleteAdmin(admin) {
    return axiosService
      .instance({
        method: 'delete',
        url: `admin/${admin.id || admin.user_id}`
      })
      .then(({ data }) => data);
  }

  static getAdminData() {
    return axiosService
      .instance({
        url: `admin`
      })
      .then(({ data }) => data);
  }

  static updateAdmin(admin) {
    return axiosService
      .instance({
        method: 'post',
        url: `admin/save`,
        data: admin
      })
      .then(({ data }) => data);
  }

  static createOPMUsers(users: DTO.OnboardUserData[]) {
    return axiosService
      .instance({
        method: 'post',
        url: `user/create`,
        data: users
      })
      .then(({ data }) => data);
  }

  static getOPMAdminData(keyword) {
    return axiosService
      .instance({
        url: `admin/search`,
        params: { keyword }
      })
      .then(({ data }) => data);
  }

  static keepAlive() {
    return axiosService
      .instance({
        method: 'POST',
        url: 'auth/keepalive'
      })
      .then(({ data }) => data);
  }

  static acceptTermsOfUse(id: number): AxiosPromise<void> {
    return axiosService.instance({
      method: 'POST',
      url: `user/TOU/${id}/accept`
    });
  }

  static getLatestTermsOfUse(): Promise<DTO.TermsOfUse> {
    return axiosService
      .instance({
        url: 'TOU/last'
      })
      .then(({ data }) => data);
  }

  static exportUsers(): AxiosPromise<Blob> {
    return axiosService.instance({
      url: 'users/report',
      responseType: 'blob'
    });
  }

  static exportAdmins(): AxiosPromise<Blob> {
    return axiosService.instance({
      url: 'admins/report',
      responseType: 'blob'
    });
  }

  static getUserSettings(
    userSetting: DTO.UserSettingsType
  ): Promise<
    typeof userSetting extends 'memoSectionAppearance'
      ? DTO.MemoSectionAppearanceData
      : never
  > {
    return axiosService
      .instance({
        method: 'get',
        url: 'settings/user',
        params: { userSetting },
        paramsSerializer: (parameters) => {
          return qs.stringify(parameters);
        }
      })
      .then(({ data }) => data);
  }
}

export default userApi;
