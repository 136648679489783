import { Reducer } from 'redux';

import {
  ACTIVATE_TREE_SUCCESS,
  CHECK_ACTIVATE_TREE_LOADED,
  CREATE_TREE_SUCCESS,
  CHECK_CREATE_TREE_LOADED,
  CHECK_CREATE_TREE_ERROR,
  LOAD_ALL_TREES_SUCCESS,
  CHECK_ALL_TREES_LOADED,
  LOAD_TREE_BY_TREE_ID_SUCCESS,
  LOAD_TREES_SUCCESS,
  CHECK_TREES_LOADING,
  LOAD_USER_TREES_SUCCESS,
  TreeActionTypes,
  TreeState,
  UPDATE_TREE_SUCCESS,
  CHECK_UPDATE_TREE_LOADED,
  CHECK_USER_TREES_LOADED,
  SELECT_TREE,
  DELETE_TREES_SUCCESS,
  CHECK_DELETE_TREES,
  SET_TREE_LIST_LOADED,
  GET_USER_TREES_PENDING,
  GET_USER_TREES_FULFILLED,
  GET_USER_TREES_REJECTED
} from './tree.types';

const initialState: TreeState = {
  createdTreeInstance: null,
  isCreatedTreeInstanceLoading: false,
  currentUserTreeList: [],
  isCurrentUserTreeListLoading: false,
  isCurrentUserTreeListLoaded: false,
  treeInstance: null,
  treeList: [],
  isTreeListLoaded: false,
  isTreeListLoading: false,
  userTreeList: [],
  isUserTreeListLoaded: false,
  isActivateTreeLoading: false,
  isUpdateTreeLoading: false,
  isTreesDeleteLoading: false,
  userTrees: [],
  userTreesLoading: false
};

const treeReducer: Reducer<TreeState, TreeActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case LOAD_TREES_SUCCESS:
      return {
        ...state,
        currentUserTreeList: action.payload,
        isCurrentUserTreeListLoading: false,
        isCurrentUserTreeListLoaded: true
      };
    case CHECK_TREES_LOADING:
      return {
        ...state,
        isCurrentUserTreeListLoading: true,
        isCurrentUserTreeListLoaded: false
      };
    case LOAD_ALL_TREES_SUCCESS:
      return {
        ...state,
        treeList: (action.payload as DTO.Tree[]).map((item) => {
          const oldItem = state.treeList.find(
            (tree) => tree.tree_id === item.tree_id
          );
          return { ...item, isChecked: oldItem?.isChecked || false };
        }),
        isTreeListLoaded: true,
        isTreeListLoading: false
      };
    case CHECK_ALL_TREES_LOADED:
      return {
        ...state,
        isTreeListLoaded: false,
        isTreeListLoading: true
      };
    case LOAD_USER_TREES_SUCCESS:
      return {
        ...state,
        userTreeList: action.payload,
        isUserTreeListLoaded: true
      };
    case CHECK_USER_TREES_LOADED:
      return {
        ...state,
        isUserTreeListLoaded: false
      };
    case LOAD_TREE_BY_TREE_ID_SUCCESS:
      return {
        ...state,
        treeInstance: action.payload
      };
    case CREATE_TREE_SUCCESS:
      return {
        ...state,
        createdTreeInstance: action.payload,
        isCreatedTreeInstanceLoading: false
      };
    case CHECK_CREATE_TREE_LOADED:
      return {
        ...state,
        isCreatedTreeInstanceLoading: true
      };
    case CHECK_CREATE_TREE_ERROR:
      return {
        ...state,
        isCreatedTreeInstanceLoading: false
      };
    case UPDATE_TREE_SUCCESS:
      return {
        ...state,
        isUpdateTreeLoading: false
      };
    case CHECK_UPDATE_TREE_LOADED:
      return {
        ...state,
        isUpdateTreeLoading: true
      };
    case ACTIVATE_TREE_SUCCESS:
      return {
        ...state,
        isActivateTreeLoading: false
      };
    case CHECK_ACTIVATE_TREE_LOADED:
      return {
        ...state,
        isActivateTreeLoading: true
      };

    case CHECK_DELETE_TREES:
      return {
        ...state,
        isTreesDeleteLoading: true
      };

    case DELETE_TREES_SUCCESS:
      return {
        ...state,
        isTreesDeleteLoading: false
      };

    case GET_USER_TREES_PENDING:
      return {
        ...state,
        userTreesLoading: true
      };

    case GET_USER_TREES_FULFILLED:
      return {
        ...state,
        userTreesLoading: false,
        userTrees: action.payload
      };

    case GET_USER_TREES_REJECTED:
      return {
        ...state,
        userTreesLoading: false
      };

    case SELECT_TREE: {
      const { payload } = action;
      const treeList = state.treeList.map((treeData) => {
        if (treeData.tree_id === payload.treeId) {
          return { ...treeData, isChecked: payload.isChecked };
        }
        return treeData;
      });
      return {
        ...state,
        treeList
      };
    }

    case SET_TREE_LIST_LOADED: {
      const { payload } = action;
      return { ...state, isTreeListLoaded: payload };
    }

    default:
      return state;
  }
};

export default treeReducer;
