import React, { FC } from 'react';

const InfoToastIcon: FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM17.5 13C17.7455 13 17.9496 13.1769 17.9919 13.4101L18 13.5V21H20.5C20.7148 21 20.8979 21.1354 20.9687 21.3255L20.9919 21.4101L21 21.5V24.5C21 24.7455 20.8231 24.9496 20.5899 24.9919L20.5 25H11.5C11.2545 25 11.0504 24.8231 11.0081 24.5899L11 24.5V21.5C11 21.2545 11.1769 21.0504 11.4101 21.0081L11.5 21H14V17H11.5C11.2852 17 11.1021 16.8646 11.0313 16.6745L11.0081 16.5899L11 16.5V13.5C11 13.2545 11.1769 13.0504 11.4101 13.0081L11.5 13H17.5ZM17 14H12V16H14.5C14.7148 16 14.8979 16.1354 14.9687 16.3255L14.9919 16.4101L15 16.5V21.5C15 21.7455 14.8231 21.9496 14.5899 21.9919L14.5 22H12V24H20V22H17.5C17.2852 22 17.1021 21.8646 17.0313 21.6745L17.0081 21.5899L17 21.5V14ZM15.5 6C16.8807 6 18 7.11929 18 8.5C18 9.88071 16.8807 11 15.5 11C14.1193 11 13 9.88071 13 8.5C13 7.11929 14.1193 6 15.5 6ZM15.5 7C14.6716 7 14 7.67157 14 8.5C14 9.32843 14.6716 10 15.5 10C16.3284 10 17 9.32843 17 8.5C17 7.67157 16.3284 7 15.5 7Z'
        fill='#007CB0'
      />
    </svg>
  );
};

export default InfoToastIcon;
