import { Reducer } from 'redux';

import {
  GlobalState,
  START_REDIRECTION,
  END_REDIRECTION,
  SET_IS_FIRST_INIT,
  GlobalActionTypes,
  SET_GLOBAL_SETTING_PENDING,
  SET_GLOBAL_SETTING_FULFILLED,
  SET_GLOBAL_SETTING_REJECTED,
  SET_GLOBAL_ENVIRONMENT_CONFIG,
  TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW
} from './global.types';

export const initialState: Readonly<GlobalState> = {
  redirect: false,
  redirectLink: '',
  isFirstInit: false,
  isAuditUserAccessAllowed: false,
  isUserAccessLoading: false,
  connectionString: '',
  trackGoToFirstTabByNavLink: false
};

const globalReducer: Reducer<GlobalState, GlobalActionTypes> = (
  state = initialState,
  action
): GlobalState => {
  switch (action.type) {
    case START_REDIRECTION: {
      const { payload } = action as Store.PaylodedAction<string>;

      return {
        ...state,
        redirect: true,
        redirectLink: payload
      };
    }

    case END_REDIRECTION:
      return {
        ...state,
        redirect: false,
        redirectLink: ''
      };

    case SET_IS_FIRST_INIT:
      return {
        ...state,
        isFirstInit: action.payload
      };

    case SET_GLOBAL_SETTING_PENDING:
      return {
        ...state,
        isUserAccessLoading: true
      };

    case SET_GLOBAL_SETTING_FULFILLED:
      return {
        ...state,
        isUserAccessLoading: false,
        isAuditUserAccessAllowed: action.payload
      };

    case SET_GLOBAL_SETTING_REJECTED:
      return {
        ...state,
        isUserAccessLoading: false
      };

    case SET_GLOBAL_ENVIRONMENT_CONFIG:
      return {
        ...state,
        ...action.payload
      };

    case TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW:
      return {
        ...state,
        trackGoToFirstTabByNavLink: !state.trackGoToFirstTabByNavLink
      };

    default:
      return { ...state };
  }
};

export default globalReducer;
