import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimeInterval = ({
  actionCallback = () => {},
  timeout = 1000
}) => {
  const [isIntervalActive, setIsIntervalActive] = useState(false);
  const savedCallback = useRef();
  const timerId = useRef();

  const stopInterval = useCallback(() => {
    setIsIntervalActive(false);
  }, []);

  const startInterval = useCallback(() => {
    setIsIntervalActive(true);
  }, []);

  useEffect(() => {
    savedCallback.current = actionCallback;
  }, [actionCallback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (isIntervalActive) {
      timerId.current = setInterval(tick, timeout);
    } else {
      clearTimeout(timerId.current);
    }
    return () => {
      clearTimeout(timerId.current);
    };
  }, [actionCallback, isIntervalActive, timeout]);

  return {
    stopInterval,
    startInterval
  };
};
