import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import { FC } from 'react';

import { LoginError } from 'components/common/LoginError/loginError';
import { SESSION_EXPIRES_AT } from 'shared/constants/loginConstants';
import { getFromLocalStorage, hasTimeExpired } from 'shared/utils/storageUtils';

import { App } from './App';
import { authProvider } from './authProvider';

export const AppContainer: FC = () => {
  const storageLogoutTimeout = getFromLocalStorage(SESSION_EXPIRES_AT);

  if (storageLogoutTimeout && hasTimeExpired(storageLogoutTimeout)) {
    authProvider.logoutRedirect();
    return null;
  }

  return (
    <>
      <AuthenticatedTemplate>
        <App />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginError />
      </UnauthenticatedTemplate>
    </>
  );
};
