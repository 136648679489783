import { Reducer } from 'redux';

import {
  LOAD_MEMO_SHARE_LIST_BY_MEMO_ID_SUCCESS,
  CHECK_IS_MEMO_SHARE_LIST_BY_USER_ID_LOADED,
  MemoSharingActionTypes,
  MemoSharingState,
  MEMO_SHARE_DATA_UPDATE_PENDING,
  MEMO_SHARE_DATA_UPDATE_SUCCESS,
  MEMO_SHARE_DATA_UPDATE_ERROR
} from './memoSharing.types';

const initialState: MemoSharingState = {
  memoSharingList: null,
  isMemoShareListByUserIdLoaded: false,
  isSharingDataUpdating: false
};

const memoSharingReducer: Reducer<MemoSharingState, MemoSharingActionTypes> = (
  state = initialState,
  action
): MemoSharingState => {
  switch (action.type) {
    case LOAD_MEMO_SHARE_LIST_BY_MEMO_ID_SUCCESS:
      return {
        ...state,
        memoSharingList: action.payload,
        isMemoShareListByUserIdLoaded: true
      };
    case CHECK_IS_MEMO_SHARE_LIST_BY_USER_ID_LOADED:
      return {
        ...state,
        isMemoShareListByUserIdLoaded: false
      };
    case MEMO_SHARE_DATA_UPDATE_PENDING:
      return {
        ...state,
        isSharingDataUpdating: true
      };
    case MEMO_SHARE_DATA_UPDATE_SUCCESS:
      return {
        ...state,
        isSharingDataUpdating: false
      };
    case MEMO_SHARE_DATA_UPDATE_ERROR:
      return {
        ...state,
        isSharingDataUpdating: false
      };
    default:
      return state;
  }
};

export default memoSharingReducer;
