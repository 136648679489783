export const LOAD_ALL_MEMOS_SUCCESS = 'LOAD_ALL_MEMOS_SUCCESS';
export const LOAD_MEMOS_SUCCESS = 'LOAD_MEMOS_SUCCESS';
export const CHECK_MEMOS_LOADED = 'CHECK_MEMOS_LOADED';
export const CHECK_ALL_MEMOS_LOADED = 'CHECK_ALL_MEMOS_LOADED';

export const LOAD_MEMO_VERSIONS_SUCCESS = 'LOAD_MEMO_VERSIONS_SUCCESS';
export const CHECK_MEMO_VERSIONS_LOADED = 'CHECK_MEMO_VERSIONS_LOADED';

export const CREATE_MEMO_SUCCESS = 'CREATE_MEMO_SUCCESS';
export const CREATE_MEMO_PENDING = 'CREATE_MEMO_PENDING';
export const CREATE_MEMO_FULFILLED = 'CREATE_MEMO_FULFILLED';
export const CREATE_MEMO_REJECTED = 'CREATE_MEMO_REJECTED';

export const UPDATE_MEMO_SHARING_LIST = 'UPDATE_MEMO_SHARING_LIST';

export const CREATE_MEMO_ADDITIONAL_INFO_SUCCESS =
  'CREATE_MEMO_ADDITIONAL_INFO_SUCCESS';
export const GET_ADDITIONAL_INFO_SUCCESS = 'GET_ADDITIONAL_INFO_SUCCESS';
export const GENERATE_MEMO_SUCCESS = 'GENERATE_MEMO_SUCCESS';
export const UPLOAD_MEMO_FILE_SUCCESS = 'UPLOAD_MEMO_FILE_SUCCESS';

export const LOAD_MEMO_APPROVALS_SUCCESS = 'LOAD_MEMO_APPROVALS_SUCCESS';
export const CHECK_MEMO_APPROVALS_LOADED = 'CHECK_MEMO_APPROVALS_LOADED';

export const CHANGE_MEMO_STATUS_LOADING = 'CHANGE_MEMO_STATUS_LOADING';
export const CHANGE_MEMO_STATUS_SUCCESS = 'CHANGE_MEMO_STATUS_SUCCESS';

export const LOAD_MEMO_REQUESTS_SUCCESS = 'LOAD_MEMO_REQUESTS_SUCCESS';
export const CHECK_MEMO_REQUESTS_LOADED = 'CHECK_MEMO_REQUESTS_LOADED';
export const LOAD_ALL_MEMO_APPROVALS_SUCCESS =
  'LOAD_ALL_MEMO_APPROVALS_SUCCESS';
export const CHECK_ALL_MEMO_APPROVALS_LOADED =
  'CHECK_ALL_MEMO_APPROVALS_LOADED';
export const LOAD_APPROVED_MEMO_APPROVALS = 'LOAD_APPROVED_MEMO_APPROVALS';
export const CHECK_APPROVED_MEMO_APPROVALS_LOADED =
  'CHECK_APPROVED_MEMO_APPROVALS_LOADED';
export const CREATE_MEMO_APPROVAL_SUCCESS = 'CREATE_MEMO_APPROVAL_SUCCESS';
export const CHECK_CREATE_MEMO_APPROVAL_LOADED =
  'CHECK_CREATE_MEMO_APPROVAL_LOADED';
export const UPDATE_MEMO_APPROVAL_SUCCESS = 'UPDATE_MEMO_APPROVAL_SUCCESS';
export const DELETE_APPROVAL_SUCCESS = 'DELETE_APPROVAL_SUCCESS';
export const LOAD_ALL_APPROVAL_STATUS_SUCCESS =
  'LOAD_ALL_APPROVAL_STATUS_SUCCESS';
export const CHECK_SIMILAR_MEMOS_LOADED = 'CHECK_SIMILAR_MEMOS_LOADED';

export const LOAD_FILES_SUCCESS = 'LOAD_FILES_SUCCESS';
export const LOAD_ALL_FILES_SUCCESS = 'LOAD_ALL_FILES_SUCCESS';

export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const LOAD_NOTES_SUCCESS = 'LOAD_NOTES_SUCCESS';
export const LOAD_NOTE_PENDING = 'LOAD_NOTE_PENDING';
export const POST_NOTE_PENDING = 'POST_NOTE_PENDING';
export const POST_NOTE_SUCCESS = 'POST_NOTE_SUCCESS';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';

export const CHECK_MEMO_SAVING = 'CHECK_MEMO_SAVING';
export const MEMO_SAVING_SUCCESS = 'MEMO_SAVING_SUCCESS';
export const MEMO_SAVING_ERROR = 'MEMO_SAVING_ERROR';

export const GET_MEMO_QUESTIONNAIRE_PENDING = 'GET_MEMO_QUESTIONNAIRE_PENDING';
export const GET_MEMO_QUESTIONNAIRE_FULFILLED =
  'GET_MEMO_QUESTIONNAIRE_FULFILLED';
export const GET_MEMO_QUESTIONNAIRE_REJECTED =
  'GET_MEMO_QUESTIONNAIRE_REJECTED';

export const SAVE_MEMO_QUESTIONNAIRE_PENDING =
  'SAVE_MEMO_QUESTIONNAIRE_PENDING';
export const SAVE_MEMO_QUESTIONNAIRE_FULFILLED =
  'SAVE_MEMO_QUESTIONNAIRE_FULFILLED';
export const SAVE_MEMO_QUESTIONNAIRE_REJECTED =
  'SAVE_MEMO_QUESTIONNAIRE_REJECTED';

export const GET_MEMO_DRAFTS_LOADING = 'GET_MEMO_DRAFTS_LOADING';
export const GET_MEMO_DRAFTS_SUCCESS = 'GET_MEMO_DRAFTS_SUCCESS';

export const DELETE_DRAFT_LOADING = 'DELETE_DRAFT_LOADING';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';

export const SUBMIT_DRAFT = 'SUBMIT_DRAFT';
export const SUBMIT_DRAFT_SUCCES = 'SUBMIT_DRAFT_SUCCES';

export const UPLOAD_MEMO_VERSION_PENDING = 'UPLOAD_MEMO_VERSION_PENDING';
export const UPLOAD_MEMO_VERSION_SUCCESS = 'UPLOAD_MEMO_VERSION_SUCCESS';
export const UPLOAD_MEMO_VERSION_REJECTED = 'UPLOAD_MEMO_VERSION_REJECTED';

export const PRACTITIONER_MEMOS_LOADING = 'PRACTITIONER_MEMOS_LOADING';
export const PRACTITIONER_MEMOS_SUCCESS = 'PRACTITIONER_MEMOS_SUCCESS';

export const ACTIVATE_MEMO_PENDING = 'ACTIVATE_MEMO_PENDING';
export const ACTIVATE_MEMO_FULFILLED = 'ACTIVATE_MEMO_FULFILLED';
export const ACTIVATE_MEMO_REJECTED = 'ACTIVATE_MEMO_REJECTED';

export const INACTIVATE_MEMO_PENDING = 'INACTIVATE_MEMO_PENDING';
export const INACTIVATE_MEMO_SUCCESS = 'INACTIVATE_MEMO_SUCCESS';
export const INACTIVATE_MEMO_REJECTED = 'INACTIVATE_MEMO_REJECTED';

export const CHECK_DASHBOARD_LOAD = 'CHECK_DASHBOARD_LOAD';
export const DASHBOARD_DATA_LOADED = 'DASHBOARD_DATA_LOADED';
export const GET_ACTIVE_MEMO_LIST_PENDING = 'GET_ACTIVE_MEMO_LIST_PENDING';
export const GET_ACTIVE_MEMO_LIST_FULFILLED = 'GET_ACTIVE_MEMO_LIST_FULFILLED';
export const GET_ACTIVE_MEMO_LIST_REJECTED = 'GET_ACTIVE_MEMO_LIST_REJECTED';

export const GET_FILTERS_DATA_PENDING = 'GET_FILTERS_DATA_PENDING';
export const GET_FILTERS_DATA_FULFILLED = 'GET_FILTERS_DATA_FULFILLED';

export const UPLOAD_MEMO_ATTACHMENT_PENDING = 'UPLOAD_MEMO_ATTACHMENT_PENDING';
export const UPLOAD_MEMO_ATTACHMENT_SUCCESS = 'UPLOAD_MEMO_ATTACHMENT_SUCCESS';
export const UPLOAD_MEMO_ATTACHMENT_REJECTED =
  'UPLOAD_MEMO_ATTACHMENT_REJECTED';

export const GET_MEMO_ATTACHMENT_LIST_PENDING =
  'GET_MEMO_ATTACHMENT_LIST_PENDING';
export const GET_MEMO_ATTACHMENT_LIST_SUCCESS =
  'GET_MEMO_ATTACHMENT_LIST_SUCCESS';
export const GET_MEMO_ATTACHMENT_LIST_REJECTED =
  'GET_MEMO_ATTACHMENT_LIST_REJECTED';

export const DELETE_MEMO_ATTACHMENT_PENDING = 'DELETE_MEMO_ATTACHMENT_PENDING';
export const DELETE_MEMO_ATTACHMENT_SUCCESS = 'DELETE_MEMO_ATTACHMENT_SUCCESS';
export const DELETE_MEMO_ATTACHMENT_REJECTED =
  'DELETE_MEMO_ATTACHMENT_REJECTED';

export const GET_MEMO_FILES_LIST_PENDING = 'GET_MEMO_FILES_LIST_PENDING';
export const GET_MEMO_FILES_LIST_SUCCESS = 'GET_MEMO_FILES_LIST_SUCCESS';
export const GET_MEMO_FILES_LIST_REJECTED = 'GET_MEMO_FILES_LIST_REJECTED';

export const DELETE_MEMO_FILE_PENDING = 'DELETE_MEMO_FILE_PENDING';
export const DELETE_MEMO_FILE_FULFILLED = 'DELETE_MEMO_FILE_FULFILLED';
export const DELETE_MEMO_FILE_REJECTED = 'DELETE_MEMO_FILE_REJECTED';

export const SET_ACTIVE_MEMO_PARAMS = 'SET_ACTIVE_MEMO_PARAMS';

export const SET_FILE_LIMIT_POPUP = 'SET_FILE_LIMIT_POPUP';

export const POST_ACTIVE_MEMO_STATUS_PENDING =
  'POST_ACTIVE_MEMO_STATUS_PENDING';
export const POST_ACTIVE_MEMO_STATUS_SUCCESS =
  'POST_ACTIVE_MEMO_STATUS_SUCCESS';
export const POST_ACTIVE_MEMO_STATUS_REJECTED =
  'POST_ACTIVE_MEMO_STATUS_REJECTED';

export const GET_MEMO_SUMMARY_PENDING = 'GET_MEMO_SUMMARY_PENDING';
export const GET_MEMO_SUMMARY_SUCCESS = 'GET_MEMO_SUMMARY_SUCCESS';
export const GET_MEMO_SUMMARY_REJECTED = 'GET_MEMO_SUMMARY_REJECTED';

export const GET_RECYCLED_MEMO_LIST_PENDING = 'GET_RECYCLED_MEMO_LIST_PENDING';
export const GET_RECYCLED_MEMO_LIST_SUCCESS = 'GET_RECYCLED_MEMO_LIST_SUCCESS';
export const GET_RECYCLED_MEMO_LIST_REJECTED =
  'GET_RECYCLED_MEMO_LIST_REJECTED';

export const GET_RECYCLED_MEMO_FILTER_PENDING =
  'GET_RECYCLED_MEMO_FILTER_PENDING';
export const GET_RECYCLED_MEMO_FILTER_SUCCESS =
  'GET_RECYCLED_MEMO_FILTER_SUCCESS';
export const GET_RECYCLED_MEMO_FILTER_REJECTED =
  'GET_RECYCLED_MEMO_FILTER_REJECTED';

export const SET_ACTIVE_FILE_LIST_MEMO_DATA = 'SET_ACTIVE_FILE_LIST_MEMO_DATA';
export const SET_MEMO_FILE_SCAN_STATUS = 'SET_MEMO_FILE_SCAN_STATUS';
export const RESET_MEMO_SUMMARY_DATA = 'RESET_MEMO_SUMMARY_DATA';

export type MemoState = Readonly<{
  memoVersions: [];
  isMemoVersionsLoaded: boolean;
  memoList: DTO.Memo[];
  isMemoListLoaded: boolean;
  allMemoList: DTO.Memo[];
  isLoadedAllMemoList: boolean;
  isLoadingAllMemoList: boolean;
  similarMemoList: any;
  isLoadedSimilarMemoList: boolean;
  isLoadingSimilarMemoList: boolean;
  files: DTO.File[];
  allFiles: DTO.File[];
  memoApprovalInstance: DTO.MemoApproval | null;
  isMemoApprovalInstanceLoaded: boolean;
  memoApprovalList: DTO.MemoApproval[];
  isMemoApprovalsListLoaded: boolean;
  memoRequestList: DTO.MemoApproval[];
  isMemoRequestsListLoaded: boolean;
  approvalStatusList: DTO.ApprovalStatus[];
  allMemoApprovalList: DTO.MemoApproval[];
  isAllMemoApprovalListLoaded: boolean;
  isAllMemoApprovalListLoading: boolean;
  approvedMemoApprovalList: DTO.MemoApproval[];
  isApprovedMemoApprovalListLoaded: boolean;
  createdMemoInstance: any;
  additionalInfoInstance: any;
  generatedMemoInstance: any;
  generatedFileInstance: any;
  savedAdditionalInfoInstance: any;
  memoQuestionnaire: DTO.MemoQuestionnaire | null;
  isMemoQuestionnaireLoading: boolean;
  isLoadingMemoNotes: boolean;
  isPostingMemoNotes: boolean;
  isChangingMemoApproval: boolean;
  memoNotes: DTO.MemoNote[];
  memoVersionFile: File;
  isMemoVersionFileLoading: boolean;
  isPractitionerMemosLoading: boolean;
  isActiveMemoLoading: boolean;
  isLoadingDashboard: boolean;
  dashboardData: DTO.DashboardData | null;
  practitionerMemos: DTO.PractitionerMemos[];
  activeMemoList: DTO.MemoTableResponse;
  activeMemoListLoading: boolean;
  activeMemoParams: DTO.GetActiveMemoQueryParams;
  filtersData: DTO.TableFiltersData;
  isLoadingFilters: boolean;
  uploadMemoAttachmentLoading: boolean;
  memoAttachmentList: DTO.MemoFile[];
  memoAttachmentListLoading: boolean;
  memoAttachmentDeleting: boolean;
  memoFilesList: DTO.MemoFile[];
  activeFileListMemoData: DTO.WSSomeMemoFilesScan | null;
  memoFilesListLoading: boolean;
  showFileLimitPopup: boolean;
  createMemoLoading: boolean;
  saveMemoQuestionnaireLoading: boolean;
  activeMemoStatusChangeLoading: boolean;
  memoSummaryLoading: boolean;
  memoSummaryData: DTO.Summary;
  recycledMemoTotalCount: number;
  recycledMemoList: DTO.RecycledMemo[];
  recycledMemoListLoading: boolean;
  recycledMemoFilter: DTO.RecycledMemoFilter;
  recycledMemoFilterLoading: boolean;
}>;

export type LoadMemosAction = Store.PaylodedAction<any>;

export type MemoActionTypes = LoadMemosAction;
