import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/rootReducer';

function configureStore() {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25
  });
  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk)
      // other store enhancers if any
    )
  );
}

export const store = configureStore();
