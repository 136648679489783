export interface UserSettingsI {
  memoAppearance: DTO.MemoSectionAppearanceData;
}

const initialState = {
  clientIndustry: {
    clientIndustryList: [],
    isLoadedClientIndustryList: false,
    isLoadingClientIndustryList: false
  },
  user: {
    isTokenLoaded: false,
    isLoading: false,
    isCurrentUserLoaded: false,
    errorStatus: null,
    currentUser: {},
    userDataList: [],
    isUserDataListLoading: false,
    isUserDataListLoaded: false,
    filteredUsers: [],
    adminDataList: [],
    isAdminDataListLoading: false,
    isAdminDataListLoaded: false,
    isUpdateAdminLoading: false,
    adminSearchValue: '',
    lastAdminSearchRequestTime: '',
    settings: {
      memoAppearanceLoading: false,
      memoAppearance: {
        showAppendixATable: false,
        showAppendixBTable: false,
        showAppendixCTable: false,
        showAdditionalInfoLink: false,
        showComments: false,
        showSummaryGuidance: false,
        showExecutiveSummary: false,
        showAdditionalInfo: false,
        showConclusion: false,
        showQuestionResponses: false
      }
    } as UserSettingsI
  },
  treeAccess: {
    treeAccessList: [],
    isTreeAccessListLoaded: false,
    isTreeAccessListLoading: false,
    treeAccessInstance: null,
    isTreeAccessInstanceLoading: false
  },
  tree: {
    treeList: [],
    isLoadedTreeList: false,
    currentUserTreeList: null,
    createdTreeInstance: null,
    userTreeList: [],
    treeListAccessByUser: []
  },
  treeVersion: {
    isActivateVersionLoading: false,
    treeVersionInstance: [],
    treeVersionListByTreeId: [],
    isTreeVersionListByTreeIdLoaded: false,
    isTreeVersionListByTreeIdLoading: false,
    savedTreeVersion: null,
    isSavedTreeVersionLoading: false,
    countries: [],
    isCountriesLoaded: false
  },
  formData: {},
  table: {
    filters: [],
    sortColumn: null
  }
};

export default initialState;
