import React, { FC } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import classNames from 'classnames';

import './ThemedButton.scss';

export type ThemedButtonVariant =
  | 'split-blue'
  | 'solid-blue'
  | 'text-blue'
  | 'text-red'
  | 'outline-grey'
  | 'with-icon'
  | 'solid-red'
  | 'outline-blue';

interface Props extends ButtonProps {
  variant: ThemedButtonVariant;
  size: 'large' | 'medium' | 'small';
  icon?: React.ReactElement;
  iconPosition?: 'left' | 'right';
  upperCase?: boolean;
  capitalize?: boolean;
}

const ThemedButton: FC<Props> = ({
  size = 'small',
  variant = 'solid_blue',
  icon,
  iconPosition = 'left',
  className,
  children,
  upperCase = true,
  capitalize,
  ...props
}) => {
  return (
    <Button
      size={size}
      className={classNames(
        'themed-button',
        `themed-button__${variant}`,
        `themed-button__${variant}__${iconPosition}`,
        className,
        {
          'themed-button__uppercase': upperCase,
          'themed-button__capitalize': capitalize
        }
      )}
      {...props}
    >
      {variant === 'with-icon' && (
        <span className='themed-button__icon-wrapper'>{icon}</span>
      )}
      {children}
    </Button>
  );
};

export default ThemedButton;
