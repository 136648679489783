import { AxiosPromise } from 'axios';

import { axiosService } from '../axios.service';

class treeApi {
  static getTrees(userId: string): Promise<DTO.Tree[]> {
    return axiosService
      .instance({
        url: `tree/?userId=${userId}&isActive=true`
      })
      .then(({ data }) => data);
  }

  // INFO: AVAILABLE ONLY FOR GLOBAL ADMIN
  static getAllTrees(): Promise<DTO.Tree[]> {
    return axiosService
      .instance({
        url: 'tree/'
      })
      .then(({ data }) => data);
  }

  // INFO: AVAILABLE FOR GLOBAL AND CONTENT ADMIN
  // returns the same as getAllTrees
  static getGrantAccessTreesList(): Promise<DTO.Tree[]> {
    return axiosService
      .instance({
        url: 'tree/grantAccessList'
      })
      .then(({ data }) => data);
  }

  static getUserTrees(): Promise<DTO.Tree[]> {
    return axiosService
      .instance({
        url: 'tree/active'
      })
      .then(({ data }) => data);
  }

  static deleteTrees(trees: DTO.Tree[]): Promise<DTO.Tree[]> {
    return axiosService
      .instance({
        url: 'tree/',
        method: 'DELETE',
        data: [...trees.map((tree) => tree.tree_id)]
      })
      .then(({ data }) => data);
  }

  static getSummaryOrder(
    treePath: string,
    treeVersionId: string
  ): Promise<string> {
    return axiosService
      .instance({
        method: 'post',
        url: 'tree_path_ordered/',
        data: { treePath, treeVersionId }
      })
      .then(({ data }) => data);
  }

  static createTree(data: DTO.TreeCreationData): Promise<DTO.Tree> {
    return axiosService
      .instance({
        method: 'post',
        url: 'tree/',
        data
      })
      .then((response) => response.data);
  }

  static activateTreeByTreeId(tree: DTO.Tree): Promise<void> {
    return axiosService
      .instance({
        method: 'put',
        url: `tree/${tree.tree_id}/`,
        data: {
          tree_name: tree.tree_name,
          tree_creator_user_id: tree.tree_creator_user_id,
          tree_description: tree.tree_description,
          tree_is_active: !tree.tree_is_active,
          tree_created_timestamp: tree.tree_created_timestamp
        }
      })
      .then(({ data }) => data);
  }

  static updateTree(tree: DTO.Tree): Promise<void> {
    const treeData = {
      tree_name: tree.tree_name,
      tree_creator_user_id: tree.tree_creator_user_id,
      tree_description: tree.tree_description,
      tree_is_active: tree.tree_is_active,
      tree_created_timestamp: tree.tree_created_timestamp
    };

    return axiosService
      .instance({
        method: 'put',
        url: `tree/${tree.tree_id}/`,
        data: treeData
      })
      .then(({ data }) => data);
  }

  static bulkChangeTreeActiveStatus(
    data: DTO.BulkChangeTreeActiveStatusParams
  ): AxiosPromise<void> {
    return axiosService.instance({
      method: 'post',
      url: 'tree/activate',
      data
    });
  }

  static exportTrees(): AxiosPromise<Blob> {
    return axiosService.instance({
      url: 'tree/report',
      responseType: 'blob'
    });
  }
}

export default treeApi;
