import { axiosService } from '../axios.service';

class dataRetentionApi {
  static getDataRetention(): Promise<DTO.DataRetentionSettings> {
    return axiosService
      .instance({
        url: `settings/dataRetention`
      })
      .then(({ data }) => data);
  }

  static updateDataRetentionPolicy(
    data: Pick<
      DTO.DataRetentionSettings,
      'memberFirmsSettings' | 'defaultSetting'
    >
  ) {
    const result = data;

    if (!result.memberFirmsSettings?.length) {
      result.memberFirmsSettings = null as any;
    }

    return axiosService
      .instance({
        url: `settings/dataRetention/`,
        method: 'PUT',
        data: result
      })
      .then((value) => value);
  }
}

export default dataRetentionApi;
