import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShieldIcon } from '../../../icons/ShieldIcon';
import ThemedButton from '../ThemedButton/ThemedButton';
import './ToastScanFailed.scss';

interface Props {
  message: string;
  onApply: () => void;
}

const ToastScanFailed: FC<Props> = ({ message, onApply }) => {
  const { t } = useTranslation();
  return (
    <div className='toast'>
      <div className='toast__content__wrapper'>
        <div className='toast__icon'>
          <ShieldIcon />
        </div>
        <div className='toast__content'>
          <span className='toast__content__title'>{t('scan failed')}</span>
          <span className='toast__content__message'>{t(message)}</span>
        </div>
      </div>
      <div className='toast__actions'>
        <ThemedButton variant='outline-grey' size='small'>
          {t('cancel')}
        </ThemedButton>
        <ThemedButton variant='solid-red' size='small' onClick={onApply}>
          {t('replace file')}
        </ThemedButton>
      </div>
    </div>
  );
};

export default ToastScanFailed;
