import { FC } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { authProvider } from '../../../authProvider';

export const LoginError: FC = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (
    !isAuthenticated &&
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    authProvider.loginRedirect();
  }

  return null;
};
