import { Reducer } from 'redux';

import {
  SET_TREE_VERSION_INSTANCE,
  MemoCreationActionTypes,
  MemoCreationState,
  UPDATE_ADDITIONAL_INFO_INSTANCE,
  SET_CURRENT_TREE_NAME,
  SET_ADDITIONAL_INFO_INSTANCE
} from './memoCreation.types';
import { initialTreeVersionInstance } from '../../shared/constants/treeConstants';
import {
  getAdditionalMemoInstance,
  updateAdditionalInfoInstanceByKey
} from '../../shared/utils/memoCreationUtils';

const initialState: MemoCreationState = {
  treeVersionInstance: initialTreeVersionInstance,
  additionalInfoInstance: {},
  currentTreeName: ''
};

const memoCreationReducer: Reducer<MemoCreationState, MemoCreationActionTypes> =
  (state = initialState, action) => {
    switch (action.type) {
      case SET_TREE_VERSION_INSTANCE:
        return {
          ...state,
          treeVersionInstance: action.payload,
          additionalInfoInstance: getAdditionalMemoInstance(action.payload)
        };
      case UPDATE_ADDITIONAL_INFO_INSTANCE: {
        const { nodeNumber, key } = action.payload;
        return {
          ...state,
          additionalInfoInstance: updateAdditionalInfoInstanceByKey(
            state.additionalInfoInstance,
            nodeNumber,
            key
          )
        };
      }
      case SET_ADDITIONAL_INFO_INSTANCE: {
        return {
          ...state,
          additionalInfoInstance: action.payload
        };
      }
      case SET_CURRENT_TREE_NAME:
        return {
          ...state,
          currentTreeName: action.payload
        };
      default:
        return state;
    }
  };

export default memoCreationReducer;
