import { axiosService } from '../axios.service';

class treeAccessApi {
  static postTreeAccess(body) {
    return axiosService
      .instance({
        method: 'post',
        url: 'tree/access/',
        data: body
      })
      .then(({ data }) => data);
  }

  static getAllTreeAccess() {
    return axiosService
      .instance({
        url: 'tree/access/'
      })
      .then(({ data }) => data);
  }

  static getTreeAccess(userAccessId) {
    return axiosService
      .instance({
        url: `tree/access/${userAccessId}`
      })
      .then(({ data }) => data);
  }

  static deleteTreeAccess(treeAccessId) {
    return axiosService
      .instance({
        method: 'delete',
        url: `tree/access/${treeAccessId}/`
      })
      .then(({ data }) => data);
  }
}

export default treeAccessApi;
