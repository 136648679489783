import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { AppInsightsProvider } from 'telemetry/appInsights';
import { getCurrentUser } from './store/actions/userActions';
import { Loading } from './components/common/Loading';
import LogoutWarningPopup from './components/common/LogoutWarningPopup/LogoutWarningPopup';

import { Routes } from './Routes';
import { useOnMount } from './shared/hooks/useOnMount';
import {
  selectCurrentUser,
  selectErrorStatus,
  selectIsUserLoaded
} from './store/selectors/userSelectors';
import { USER_PATH } from './shared/constants/routesConstants';
import AppLayout from './components/common/AppLayout/AppLayout';
import {
  setToLocalStorage,
  getFromLocalStorage
} from './shared/utils/storageUtils';
import { USER_AUTHORIZED } from './shared/constants/loginConstants';
import { USER_TYPES } from './shared/constants/commonConstants';
import { useGlobalMemoFileScanListener } from './shared/hooks/useGlobalMemoFileScanListener';

import './App.scss';

export const App: FC = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentUser = useSelector(selectCurrentUser);
  const errorStatus = useSelector(selectErrorStatus);
  const isCurrentUserLoaded = useSelector(selectIsUserLoaded);

  useGlobalMemoFileScanListener(currentUser);

  const { beforeLogOutPopUpTime, idleTimeToLogOut } =
    currentUser as DTO.CurrentUser;

  useOnMount(async () => {
    dispatch(getCurrentUser());
  });

  useEffect(() => {
    if (
      currentUser.user_type &&
      currentUser.user_type !== USER_TYPES.GLOBAL_ADMIN
    ) {
      if (!getFromLocalStorage(USER_AUTHORIZED)) {
        setToLocalStorage(USER_AUTHORIZED, true);
      }
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (
      isCurrentUserLoaded &&
      !_.isEmpty(currentUser) &&
      !currentUser.is_TOU_accepted &&
      !(
        location.pathname === USER_PATH.TERMS ||
        location.pathname === USER_PATH.ERROR
      )
    ) {
      history.push(USER_PATH.TERMS);
    } else if (location.pathname !== USER_PATH.ERROR && errorStatus) {
      history.push(USER_PATH.ERROR);
    }
  }, [
    currentUser,
    errorStatus,
    history,
    isCurrentUserLoaded,
    location.pathname
  ]);

  if (_.isEmpty(currentUser) && !isCurrentUserLoaded) {
    return <Loading />;
  }

  return (
    <AppInsightsProvider>
      <div className='app'>
        {beforeLogOutPopUpTime && idleTimeToLogOut && (
          <LogoutWarningPopup
            originalLogoutTimeout={idleTimeToLogOut}
            originalPopupTimeout={beforeLogOutPopUpTime}
          />
        )}
        <AppLayout>
          <Routes />
        </AppLayout>
      </div>
      <ToastContainer
        position='top-right'
        hideProgressBar={true}
        autoClose={10000}
        closeButton={<div className='app__toast-close-btn' />}
        toastClassName='app__toast'
        icon={false}
      />
    </AppInsightsProvider>
  );
});
