class LoadOneTrustCookieScript {
  link = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

  loadScript() {
    const script = document.createElement('script');
    script.setAttribute('src', this.link);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute(
      'data-domain-script',
      window.APP_CONFIG?.ENV === 'PROD'
        ? '4d88704a-36de-4a06-b30a-024aca092601'
        : '4d88704a-36de-4a06-b30a-024aca092601-test'
    );
    document.head.prepend(script);
  }
}

export default new LoadOneTrustCookieScript();
