import { axiosService } from '../axios.service';

export class globalApi {
  static setMFAccessType(settings: DTO.GlobalSetting): Promise<null> {
    return axiosService
      .instance({
        url: `settings/global/`,
        method: 'PUT',
        data: settings
      })
      .then(({ data }) => data);
  }

  static getMFAccessType(): Promise<DTO.GlobalSetting> {
    return axiosService
      .instance({
        url: `settings/global?settingType=accessAuditPractitioners`
      })
      .then(({ data }) => data);
  }

  static getEnvironmentConfig(): Promise<DTO.EnvironmentConfig> {
    return axiosService
      .instance({
        url: 'environment'
      })
      .then(({ data }) => {
        // INFO: For local env resultData.connectionString always will be null and tracking will not work
        // You need to add the next line to the AppSettings.json file (root level) in API app files:
        // "ApplicationInsights:InstrumentationKey":  "your key here",
        return data || {};
      });
  }
}
