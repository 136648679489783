export const LOAD_MEMO_SHARE_LIST_BY_MEMO_ID_SUCCESS =
  'LOAD_MEMO_SHARE_LIST_BY_MEMO_ID_SUCCESS';
export const CHECK_IS_MEMO_SHARE_LIST_BY_USER_ID_LOADED =
  'CHECK_IS_MEMO_SHARE_LIST_BY_USER_ID_LOADED';

export const MEMO_SHARE_DATA_UPDATE_PENDING = 'MEMO_SHARE_DATA_UPDATE_PENDING';
export const MEMO_SHARE_DATA_UPDATE_SUCCESS = 'MEMO_SHARE_DATA_UPDATE_SUCCESS';
export const MEMO_SHARE_DATA_UPDATE_ERROR = 'MEMO_SHARE_DATA_UPDATE_ERROR';

export type MemoSharingState = Readonly<{
  memoSharingList: null | DTO.MemoSharing[];
  isMemoShareListByUserIdLoaded: boolean;
  isSharingDataUpdating: boolean;
}>;

export type MemoSharingActionTypes = any;
