export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS';

export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS';
export const GET_MEMO_FILES_SUCCESS = 'GET_MEMO_FILES_SUCCESS';
export const CHECK_MEMO_FILES_LOADED = 'CHECK_MEMO_FILES_LOADED';
export const GET_ALL_FILES_SUCCESS = 'GET_ALL_FILES_SUCCESS';

export const GET_SUMMARY_REPORT_PENDING = 'GET_SUMMARY_REPORT_PENDING';
export const GET_SUMMARY_REPORT_FULFILLED = 'GET_SUMMARY_REPORT_FULFILLED';
export const GET_SUMMARY_REPORT_REJECTED = 'GET_SUMMARY_REPORT_REJECTED';
export const DOWNLOAD_MEMO_FILE_SUCCESS = 'DOWNLOAD_MEMO_FILE_SUCCESS';
export const DOWNLOAD_MEMO_FILE_PENDING = 'DOWNLOAD_MEMO_FILE_PENDING';
export const DOWNLOAD_MEMO_FILE_REJECTED = 'DOWNLOAD_MEMO_FILE_REJECTED';

export const GET_MEMO_FILE_DOWNLOAD_PENDING = 'GET_MEMO_FILE_DOWNLOAD_PENDING';
export const GET_MEMO_FILE_DOWNLOAD_SUCCESS = 'GET_MEMO_FILE_DOWNLOAD_SUCCESS';
export const GET_MEMO_FILE_DOWNLOAD_REJECTED =
  'GET_MEMO_FILE_DOWNLOAD_REJECTED';

export type FileState = Readonly<{
  fileList: any[];
  isFileListLoading: boolean;
  allFileList: any[];
  summaryReportLoading: boolean;
  memoFileDownloading: boolean;
  memoDocumentDownloading: boolean;
}>;

export type FileActionTypes = any;
