import { axiosService } from '../axios.service';

class featureApi {
  static getFeature(): Promise<DTO.Feature[]> {
    return axiosService
      .instance({
        url: 'feature'
      })
      .then(({ data }) => data);
  }

  static postFeature(id: string, keys: string[]): Promise<DTO.Feature[]> {
    return axiosService
      .instance({
        method: 'post',
        url: `feature/${id}`,
        data: {
          countries: keys
        }
      })
      .then(({ data }) => data);
  }
}

export default featureApi;
