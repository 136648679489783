import { Reducer } from 'redux';

import {
  CountrySettingsState,
  COUNTRY_SETTING_ERROR,
  COUNTRY_SETTING_LOADING,
  COUNTRY_SETTING_SUCCESS
} from './country-settings.types';

const initialState: CountrySettingsState = {
  loading: false,
  data: null
};

const countrySettingsReducer: Reducer<CountrySettingsState> = (
  state = initialState,
  action
): CountrySettingsState => {
  switch (action.type) {
    case COUNTRY_SETTING_LOADING:
      return {
        ...state,
        loading: true
      };
    case COUNTRY_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case COUNTRY_SETTING_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default countrySettingsReducer;
