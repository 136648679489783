import React from 'react';

const ErrorIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM16.378 5.17227L16.4319 5.24806L26.9319 23.2481C27.1114 23.5558 26.9203 23.9361 26.5863 23.9928L26.5 24H5.5C5.14379 24 4.91152 23.6433 5.03083 23.3263L5.06811 23.2481L15.5681 5.24806C15.745 4.94487 16.1613 4.91961 16.378 5.17227ZM16 6.491L6.369 23H25.63L16 6.491ZM16 19C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20C15 19.4477 15.4477 19 16 19ZM16 12C16.5082 12 16.9201 12.412 16.9201 12.9201L16.917 12.9965L16.5382 17.5415C16.5166 17.8007 16.3 18 16.0399 18H15.9601C15.7 18 15.4834 17.8007 15.4618 17.5415L15.083 12.9965C15.0408 12.4901 15.4172 12.0454 15.9236 12.0032C15.949 12.0011 15.9745 12 16 12Z'
        fill='#DA291C'
      />
    </svg>
  );
};

export default ErrorIcon;
