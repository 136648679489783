import { Reducer } from 'redux';

import {
  FeatureState,
  FEATURE_LOADING,
  FEATURE_SUCCESS,
  FEATURE_REJECTED,
  POST_FEATURE_LOADING,
  POST_FEATURE_SUCCESS,
  POST_FEATURE_REJECTED
} from './feature.types';

const initialState: FeatureState = {
  isFeatureLoading: false,
  isPostFeatureLoading: false,
  featureList: []
};

const featureReducer: Reducer<FeatureState> = (
  state = initialState,
  action
): FeatureState => {
  switch (action.type) {
    case FEATURE_LOADING:
      return {
        ...state,
        isFeatureLoading: true
      };
    case FEATURE_SUCCESS:
      return {
        ...state,
        isFeatureLoading: false,
        featureList: action.payload
      };
    case FEATURE_REJECTED:
      return {
        ...state,
        isFeatureLoading: false
      };
    case POST_FEATURE_LOADING:
      return {
        ...state,
        isFeatureLoading: true
      };
    case POST_FEATURE_SUCCESS:
      return {
        ...state,
        isFeatureLoading: false
      };
    case POST_FEATURE_REJECTED:
      return {
        ...state,
        isFeatureLoading: false
      };
    default:
      return state;
  }
};

export default featureReducer;
