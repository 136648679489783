export enum AppInsightsEventCategoryE {
  SCREEN = 'screen',
  EVENT = 'event'
}

export enum AppInsightsScreenTelemetryTypeE {
  yourMemosView = 'Memo List',
  recycleBinView = 'Recycle Bin',
  practitionerMemosView = 'Practitioner Memos',
  editMemoView = 'Edit Memo',
  adminUserAdministrationView = 'User List',
  globalAdminUserAdministrationView = 'Admin User List',
  accountingStandardAdministrationView = 'Standard List',
  featureAdministrationView = 'Feature List',
  treeManagementView = 'Tree List',
  treeEditorView = 'Tree Editor'
}

export enum AppInsightsEventTelemetryTypeE {
  memoCreateOpen = 'Create Memo',
  memoCreateConfirm = 'Add Memo',
  memoEditOpen = 'Edit Memo',
  memoEditConfirm = 'Save Memo',
  memoEditSaveAndLeave = 'Save Memo Changes And Leave',
  memoEditDiscardAndLeave = 'Discard Memo Changes And Leave',
  memoRemoveOpen = 'Remove Memo',
  memoRemoveConfirm = 'Delete Memo',
  memoRestoreOpen = 'Restore Memo',
  memoRestoreConfirm = 'Recover Memo',
  memoFilesOpen = 'Memo Files',
  memoFileDeleteOpen = 'Remove Memo File',
  memoFileDeleteConfirm = 'Delete Memo File',
  memoFileDownloadOpen = 'Download Memo File',
  memoFileDownloadConfirm = 'Confirm and Download Memo File',
  memoAttachmentDeleteOpen = 'Remove Memo Supporting Document',
  memoAttachmentDeleteConfirm = 'Delete Memo Supporting Document',
  memoAttachmentDownload = 'Download Memo Supporting Document',
  memoFilesUpload = 'Upload Memo Files',
  memoAttachmentsUpload = 'Upload Memo Supporting Documents',
  memoDownloadOpen = 'Download Memo',
  memoDownloadConfirm = 'Confirm and Download Memo',
  memoShareOpen = 'Share Memo',
  memoShareConfirm = 'Add User To Memo',
  memoShareRemove = 'Remove User From Memo',
  practitionerMemoActivateDeactivate = 'Change Practitioner Memo Status',
  practitionerMemoActivateDeactivateBulk = 'Bulk Change Practitioner Memo Status',
  answerEditOpen = 'Edit Answer',
  answerEditConfirm = 'Save Answer',
  answerEditDiscard = 'Discard Answer Changes',
  accessModeChange = 'Change Restricted Mode State',
  accessModeConfirmRestrictedMode = 'Set Restricted Mode',
  userGrantAccessOpen = 'Grant User Access',
  userGrantAccessConfirm = 'Save User Access',
  exportUserList = 'Export User List',
  exportAdminList = 'Export Admin User List',
  exportStandardList = 'Export Standard List',
  exportStatisticReport = 'Download Statistic Report',
  userRemoveOpen = 'Remove User',
  userRemoveConfirm = 'Delete User',
  userOnboardOpen = 'Onboard User',
  userOnboardConfirm = 'Add User',
  adminRemoveOpen = 'Remove Admin User',
  adminRemoveConfirm = 'Delete Admin User',
  adminOnboardOpen = 'Onboard Admin User',
  adminOnboardConfirm = 'Add Admin User',
  adminChangeRole = 'Change Admin User Role',
  adminActivationStateToggle = 'Change Admin User State',
  accountingStandardCreateOpen = 'Create Standard',
  accountingStandardCreateConfirm = 'Add Standard',
  accountingStandardDeactivateConfirm = 'Deactivate Standard',
  accountingStandardDeleteOpen = 'Remove Standard',
  accountingStandardDeleteConfirm = 'Delete Standard',
  accountingStandardToggleActiveState = 'Change Standard State',
  accountingStandardBulkActivate = 'Bulk Activate Standards',
  accountingStandardBulkDeactivate = 'Bulk Deactivate Standards',
  accountingStandardBulkDelete = 'Bulk Delete Standards',
  featureEnableUploadConfirm = 'Enable Feature To Country',
  treeEditOpen = 'Edit Tree',
  treeEditConfirm = 'Save Tree',
  treeVersionsOpenView = 'Tree Version List',
  treeVersionsEditAccessOpen = 'Activate Tree Version',
  treeVersionsEditAccessConfirm = 'Add Tree Version To Country',
  treeEditorExport = 'Export Tree',
  treeEditorImport = 'Import Tree',
  treeEditorSaveAsNewOpen = 'Save Tree',
  treeEditorSaveAsNewConfirm = 'Confirm Save Tree',
  treeEditorSaveAsDraft = 'Save Tree As Draft',
  treeEditorAddNodeParent = 'Add Parent Node',
  treeEditorAddNodeChild = 'Add Child Node',
  treeEditorAddNodeStatement = 'Add Statement Node'
}
