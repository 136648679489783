import { AxiosPromise } from 'axios';
import qs from 'querystring';

import { getAllAPIs } from 'shared/utils/commonUtils';
import { APPROVAL_STATUS } from 'shared/constants/commonConstants';

import resources from '../../resources';
import { axiosService } from '../axios.service';
import { toastSuccess } from '../../shared/utils/toastUtils';

class memoApi {
  private static ALL_GEO_APIs = getAllAPIs();

  static deleteDraft(draftId: number) {
    return axiosService
      .instance({ url: `/memo_draft/${draftId}`, method: 'DELETE' })
      .then(({ data }) => data);
  }

  static changeMemoApprovalStatus(memoId: string, status: string) {
    return axiosService
      .instance({
        url:
          status === APPROVAL_STATUS.APPROVED
            ? `/memo_approval_approve`
            : `/memo_approval_reject`,
        method: 'POST',
        data: {
          memo_approval_id: memoId
        }
      })
      .then(({ data }) => data);
  }

  static getMemoVersions(memoId: string) {
    return axiosService
      .instance({ url: `/memo_versions?memoId=${memoId}` })
      .then(({ data }) => data);
  }

  static deleteNote(approvalId: string) {
    return axiosService
      .instance({
        url: `/memo_approval_note?memoApprovalNoteId=${approvalId}`,
        method: 'delete'
      })
      .then(({ data }) => data);
  }

  static editMemoNote(
    memoApprovaNotelId: number,
    note: string,
    approvalId: number,
    userId: string
  ) {
    return axiosService
      .instance({
        method: 'put',
        url: `/memo_approval_note/`,
        data: {
          memo_approval_note_id: memoApprovaNotelId,
          memo_approval_note: note,
          memo_approval: approvalId,
          user: userId
        }
      })
      .then(({ data }) => data);
  }

  static postMemoNote(memoApprovalId: number, note: string, userId: string) {
    return axiosService
      .instance({
        method: 'post',
        url: `/memo_approval_note/`,
        data: {
          memo_approval: memoApprovalId,
          memo_approval_note: note,
          user: userId
        }
      })
      .then(({ data }) => data);
  }

  static getMemoNotes(userId: string, memoApprovalId: number) {
    return axiosService
      .instance({
        url: `/memo_approval_note?user=${userId}&memo_approval=${memoApprovalId}`
      })
      .then(({ data }) => data);
  }

  static getMemoDrafts(userId: string) {
    return axiosService
      .instance({
        url: `/memo_draft/${userId}`
      })
      .then(({ data }) => data);
  }

  static getMemos(userId: string): Promise<DTO.Memo[]> {
    return axiosService
      .instance({
        url: `/memo_involved/${userId}/`
      })
      .then(({ data }) => data);
  }

  static getAllMemos(): Promise<DTO.Memo[]> {
    return axiosService
      .instance({
        url: '/memo/'
      })
      .then(({ data }) => data);
  }

  static getMemoTableFiltersData(): Promise<DTO.TableFiltersData> {
    return axiosService
      .instance({
        url: '/filters/'
      })
      .then(({ data }) => data);
  }

  static getDashboardData(): Promise<DTO.DashboardData> {
    return axiosService
      .instance({
        url: '/dashboard/'
      })
      .then(({ data }) => data);
  }

  static getSimilarMemos(
    memoId: number,
    treeVersionId: string,
    clientIndustryId: number
  ): Promise<DTO.Memo[]> {
    return axiosService
      .instance({
        url: `/memo/similar/${memoId}/${treeVersionId}/${clientIndustryId}`
      })
      .then(({ data }) => data);
  }

  static searchForClientName(
    clientName: string
  ): Promise<DTO.ClientNameSearchResult[]> {
    return axiosService
      .instance({
        url: `/client/${clientName}`
      })
      .then(({ data }) => data);
  }

  static getAllMemoApprovals(): Promise<DTO.MemoApproval[]> {
    return axiosService
      .instance({
        url: `/memo_approval/`
      })
      .then(({ data }) => data);
  }

  static getApproverMemos(userId: string): Promise<DTO.MemoApproval[]> {
    return axiosService
      .instance({
        url: `/memo_approval?approval_status=1&approver=${userId}`
      })
      .then(({ data }) => data);
  }

  static getApprovedMemoApprovals(userId: string): Promise<DTO.MemoApproval[]> {
    return axiosService
      .instance({
        url: `/memo_approval/?approval_status=2&requestor=${userId}`
      })
      .then(({ data }) => data);
  }

  static getRequestedMemos(userId: string): Promise<DTO.MemoApproval[]> {
    return axiosService
      .instance({
        url: `/memo_approval/?requestor=${userId}`
      })
      .then(({ data }) => data);
  }

  static deleteMemoApproval(approvalId: string) {
    return axiosService
      .instance({
        url: `/memo_approval?memoApprovalId=${approvalId}`,
        method: 'delete'
      })
      .then(({ data }) => data);
  }

  static getAllApprovalStatus(): Promise<DTO.ApprovalStatus[]> {
    return axiosService
      .instance({
        url: '/approval_status/'
      })
      .then(({ data }) => data);
  }

  static getMemoFiles(memoId: number): Promise<DTO.File[]> {
    return axiosService
      .instance({
        url: `/file/?memo=${memoId}`
      })
      .then(({ data }) => data);
  }

  static getAllMemoFiles(): Promise<DTO.File[]> {
    return axiosService
      .instance({
        url: '/file/'
      })
      .then(({ data }) => data);
  }

  static postMemoApproval(
    data: DTO.MemoApprovalBody
  ): Promise<DTO.MemoApproval> {
    const { approval_status, approver, memo, requestor } = data;
    const postData = {
      approval_status,
      approver,
      memo,
      requestor
    };

    return axiosService
      .instance({
        method: 'post',
        url: '/memo_approval/',
        data: postData
      })
      .then((response) => response.data);
  }

  static updateMemoApproval(
    memoApprovalData: DTO.MemoApprovalBody,
    memoApprovalId: number
  ): Promise<DTO.MemoApproval> {
    return axiosService
      .instance({
        method: 'put',
        url: `/memo_approval/${memoApprovalId}/`,
        data: memoApprovalData
      })
      .then((response) => {
        const { data } = response;
        return data;
      });
  }

  //TODO: refactor next API's
  static uploadMemoFile(memoId: number, data) {
    const formData = new FormData();
    formData.append('file_data', data.file_data);
    formData.append('file_name', data.file_name);
    formData.append('memo', data.memo);
    return axiosService
      .instance({
        method: 'post',
        url: `/file/?memo=${memoId}`,
        data: formData
      })
      .then((response) => response.data);
  }

  static async postDraft(
    body: Partial<DTO.PostMemoBody>,
    isEdit?: boolean
  ): Promise<DTO.Memo> {
    return axiosService
      .instance({
        method: isEdit ? 'put' : 'post',
        url: '/memo_draft',
        data: body
      })
      .then((response) => {
        toastSuccess(resources.en.translations['success create']);
        const { data } = response;
        return data;
      });
  }

  static async postMemo(body: DTO.PostMemoBody): Promise<DTO.Memo> {
    return axiosService
      .instance({
        method: 'post',
        url: '/memo/',
        data: body
      })
      .then((response) => {
        toastSuccess(resources.en.translations['success create']);
        const { data } = response;
        return data;
      });
  }

  static postAdditionalInfo(additionalInfo) {
    return axiosService.instance({
      method: 'post',
      url: '/memo/',
      data: {
        memo_id: additionalInfo.memo_id,
        additional_info_id: additionalInfo.additional_info_id
      }
    });
  }

  static getNotes(memoApprovalId) {
    return axiosService
      .instance({
        url: `/memo_approval_note/?memo_approval=${memoApprovalId}`
      })
      .then(({ data }) => data);
  }

  static postNote(data) {
    return axiosService.instance({
      method: 'post',
      url: '/memo_approval_note/',
      data
    });
  }

  static updateNote(data, id) {
    return axiosService.instance({
      method: 'put',
      url: `/memo_approval_note/${id}/`,
      data
    });
  }

  static generateMemo(memo) {
    return axiosService
      .instance({
        method: 'post',
        url: '/generate_memo/',
        data: memo,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        return response;
      });
  }

  static getMemoQuestionnaire(
    memoId: string
  ): AxiosPromise<DTO.MemoQuestionnaire> {
    return axiosService.instance({
      method: 'get',
      url: `/memo/${memoId}/questionnaire`
    });
  }

  static postMemoQuestionnaire(
    data: DTO.MemoQuestionnaire
  ): AxiosPromise<DTO.MemoQuestionnaire> {
    return axiosService.instance({
      method: 'post',
      url: '/memo/questionnaire',
      data
    });
  }

  static postMemoFile(files: File[], memoId: number) {
    const formData = new FormData();
    files.forEach((file) => formData.append('file_data', file));
    return axiosService
      .instance({
        method: 'post',
        url: `/memo/${memoId}/upload`,
        data: formData
      })
      .then(({ data }) => {
        toastSuccess(resources.en.translations['success upload with scan']);
        return data;
      });
  }

  static getPractitionerMemos() {
    return axiosService
      .instance({ url: '/memo/practitioner' })
      .then(({ data }) => data);
  }

  static activateMemo(data: DTO.ActivateMemo) {
    return axiosService.instance({
      method: 'post',
      url: `memo/activate`,
      data
    });
  }

  static inactivateMemo(data: number[]) {
    return axiosService.instance({
      method: 'post',
      url: `memo/inactivate`,
      data
    });
  }

  static getActiveMemoList(
    params?: DTO.GetActiveMemoQueryParams
  ): AxiosPromise<DTO.ActiveMemo[]> {
    return axiosService.instance({
      method: 'get',
      url: 'memo/active',
      params,
      paramsSerializer: (parameters) => {
        return qs.stringify(parameters);
      }
    });
  }

  static postMemoAttachment(files: File[], memoId: number) {
    const formData = new FormData();
    files.forEach((file) => formData.append('file_data', file));
    return axiosService
      .instance({
        method: 'post',
        url: `memo/${memoId}/attachment`,
        data: formData
      })
      .then(({ data }) => {
        toastSuccess(resources.en.translations['success upload with scan']);
        return data;
      });
  }

  static getMemoAttachmentList(memoId: number) {
    return axiosService
      .instance({
        method: 'get',
        url: `memo/${memoId}/attachment`
      })
      .then(({ data }) => data);
  }

  static deleteMemoAttachment(attachmentId: string) {
    return axiosService
      .instance({
        method: 'delete',
        url: `attachment/${attachmentId}`
      })
      .then(({ data }) => data);
  }

  static getMemoFilesList(memoId: number): AxiosPromise<DTO.MemoFile[]> {
    return axiosService.instance({
      url: `memo/${memoId}/file`
    });
  }

  static deleteMemoFile(fileId: string) {
    return axiosService.instance({
      method: 'delete',
      url: `memo/file/${fileId}`
    });
  }

  static createMemo(
    data: DTO.CreateMemoBody
  ): AxiosPromise<DTO.CreateMemoResponse> {
    return axiosService.instance({
      url: 'memo',
      method: 'POST',
      data
    });
  }

  static postActiveMemoStatus(memoId: number, status: string) {
    return axiosService.instance({
      url: `/memo/${memoId}/status`,
      method: 'POST',
      data: JSON.stringify(status)
    });
  }

  static getMemoSummary(memoId: string) {
    return axiosService
      .instance({
        url: `/memo/${memoId}/summary`,
        method: 'GET'
      })
      .then(({ data }) => data);
  }

  static getRecycledMemoList(
    params?: Partial<DTO.GetRecycledMemoListQueryParams>
  ): AxiosPromise<DTO.RecycledMemoListResponse> {
    return axiosService.instance({
      url: 'memo/recycled',
      method: 'GET',
      params,
      paramsSerializer: (parameters) => {
        return qs.stringify(parameters);
      }
    });
  }

  static getRecycledMemoFilter(): AxiosPromise<DTO.RecycledMemoFilter> {
    return axiosService.instance({
      url: '/filters/recycled',
      method: 'GET'
    });
  }

  static postRestoreMemo(memoId: number): AxiosPromise<DTO.RecycledMemoFilter> {
    return axiosService.instance({
      url: `/api/memo/${memoId}/restore`,
      method: 'POST'
    });
  }

  static getSummaryReportData(
    startDate: string,
    endDate: string
  ): Promise<DTO.SummaryReport[]> {
    const promises = memoApi.ALL_GEO_APIs.map((api) =>
      axiosService
        .instance({
          method: 'get',
          baseURL: api,
          url: 'memo/reportSummary',
          params: { startDate, endDate },
          paramsSerializer: (parameters) => {
            return qs.stringify(parameters);
          }
        })
        .then(({ data }) => data as any[])
    );

    // eslint-disable-next-line compat/compat
    return Promise.all(promises).then((result) => result.flat());
  }
}

export default memoApi;
