import { RootState } from '../reducers/rootReducer';

export const selectRedirection = ({ global }: RootState) => global.redirect;
export const selectRedirectLink = ({ global }: RootState) =>
  global.redirectLink;
export const selectIsUserAccessLoading = ({ global }: RootState) =>
  global.isUserAccessLoading;
export const selectIsAuditUserAccessAllowed = ({ global }: RootState) =>
  global.isAuditUserAccessAllowed;
export const selectIsFirstInit = ({ global }: RootState) => global.isFirstInit;
export const selectAppInsightsInstrumentationKey = ({ global }: RootState) =>
  global.connectionString;
export const selectShouldRedirectToFirstTabOfThePage = ({
  global
}: RootState) => global.trackGoToFirstTabByNavLink;
