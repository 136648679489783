import { RootState } from '../reducers/rootReducer';
import {
  LOAD_MEMOS_SUCCESS,
  CHECK_MEMOS_LOADED,
  CHECK_ALL_MEMOS_LOADED,
  CHECK_SIMILAR_MEMOS_LOADED,
  LOAD_ALL_MEMOS_SUCCESS,
  LOAD_ALL_MEMO_APPROVALS_SUCCESS,
  CHECK_ALL_MEMO_APPROVALS_LOADED,
  CREATE_MEMO_APPROVAL_SUCCESS,
  CHECK_CREATE_MEMO_APPROVAL_LOADED,
  UPDATE_MEMO_APPROVAL_SUCCESS,
  CREATE_MEMO_SUCCESS,
  GET_ADDITIONAL_INFO_SUCCESS,
  CREATE_MEMO_ADDITIONAL_INFO_SUCCESS,
  GENERATE_MEMO_SUCCESS,
  UPLOAD_MEMO_FILE_SUCCESS,
  CHANGE_MEMO_STATUS_SUCCESS,
  LOAD_MEMO_APPROVALS_SUCCESS,
  CHECK_MEMO_APPROVALS_LOADED,
  LOAD_MEMO_REQUESTS_SUCCESS,
  CHECK_MEMO_REQUESTS_LOADED,
  LOAD_FILES_SUCCESS,
  LOAD_ALL_FILES_SUCCESS,
  LOAD_ALL_APPROVAL_STATUS_SUCCESS,
  LOAD_APPROVED_MEMO_APPROVALS,
  CHECK_APPROVED_MEMO_APPROVALS_LOADED,
  CREATE_NOTE_SUCCESS,
  UPDATE_NOTE_SUCCESS,
  LOAD_NOTES_SUCCESS,
  CHECK_MEMO_SAVING,
  MEMO_SAVING_SUCCESS,
  MEMO_SAVING_ERROR,
  LOAD_MEMO_VERSIONS_SUCCESS,
  CHECK_MEMO_VERSIONS_LOADED,
  GET_MEMO_QUESTIONNAIRE_PENDING,
  GET_MEMO_QUESTIONNAIRE_REJECTED,
  GET_MEMO_QUESTIONNAIRE_FULFILLED,
  LOAD_NOTE_PENDING,
  DELETE_NOTE_SUCCESS,
  POST_NOTE_SUCCESS,
  POST_NOTE_PENDING,
  GET_MEMO_DRAFTS_SUCCESS,
  DELETE_DRAFT_LOADING,
  DELETE_DRAFT_SUCCESS,
  GET_MEMO_DRAFTS_LOADING,
  DELETE_APPROVAL_SUCCESS,
  CHANGE_MEMO_STATUS_LOADING,
  UPLOAD_MEMO_VERSION_SUCCESS,
  UPLOAD_MEMO_VERSION_PENDING,
  UPLOAD_MEMO_VERSION_REJECTED,
  PRACTITIONER_MEMOS_LOADING,
  PRACTITIONER_MEMOS_SUCCESS,
  ACTIVATE_MEMO_PENDING,
  INACTIVATE_MEMO_PENDING,
  INACTIVATE_MEMO_SUCCESS,
  INACTIVATE_MEMO_REJECTED,
  CHECK_DASHBOARD_LOAD,
  DASHBOARD_DATA_LOADED,
  GET_ACTIVE_MEMO_LIST_PENDING,
  GET_ACTIVE_MEMO_LIST_FULFILLED,
  GET_ACTIVE_MEMO_LIST_REJECTED,
  GET_FILTERS_DATA_PENDING,
  GET_FILTERS_DATA_FULFILLED,
  UPLOAD_MEMO_ATTACHMENT_PENDING,
  UPLOAD_MEMO_ATTACHMENT_SUCCESS,
  UPLOAD_MEMO_ATTACHMENT_REJECTED,
  GET_MEMO_ATTACHMENT_LIST_PENDING,
  GET_MEMO_ATTACHMENT_LIST_SUCCESS,
  GET_MEMO_ATTACHMENT_LIST_REJECTED,
  DELETE_MEMO_ATTACHMENT_PENDING,
  DELETE_MEMO_ATTACHMENT_SUCCESS,
  DELETE_MEMO_ATTACHMENT_REJECTED,
  SET_ACTIVE_MEMO_PARAMS,
  GET_MEMO_FILES_LIST_REJECTED,
  GET_MEMO_FILES_LIST_PENDING,
  GET_MEMO_FILES_LIST_SUCCESS,
  DELETE_MEMO_FILE_PENDING,
  DELETE_MEMO_FILE_FULFILLED,
  DELETE_MEMO_FILE_REJECTED,
  SET_FILE_LIMIT_POPUP,
  CREATE_MEMO_PENDING,
  CREATE_MEMO_FULFILLED,
  CREATE_MEMO_REJECTED,
  SAVE_MEMO_QUESTIONNAIRE_PENDING,
  SAVE_MEMO_QUESTIONNAIRE_FULFILLED,
  SAVE_MEMO_QUESTIONNAIRE_REJECTED,
  POST_ACTIVE_MEMO_STATUS_PENDING,
  POST_ACTIVE_MEMO_STATUS_SUCCESS,
  POST_ACTIVE_MEMO_STATUS_REJECTED,
  GET_MEMO_SUMMARY_PENDING,
  GET_MEMO_SUMMARY_SUCCESS,
  GET_MEMO_SUMMARY_REJECTED,
  GET_RECYCLED_MEMO_LIST_SUCCESS,
  GET_RECYCLED_MEMO_LIST_PENDING,
  GET_RECYCLED_MEMO_LIST_REJECTED,
  GET_RECYCLED_MEMO_FILTER_PENDING,
  GET_RECYCLED_MEMO_FILTER_SUCCESS,
  GET_RECYCLED_MEMO_FILTER_REJECTED,
  ACTIVATE_MEMO_FULFILLED,
  ACTIVATE_MEMO_REJECTED,
  UPDATE_MEMO_SHARING_LIST,
  SET_ACTIVE_FILE_LIST_MEMO_DATA,
  SET_MEMO_FILE_SCAN_STATUS,
  RESET_MEMO_SUMMARY_DATA
} from './memo.types';
import { memoApi } from '../../services';
import additionalInfoApi from '../../services/api/additionalInfoApi';
import resources from '../../resources';
import { enableRedirection, handleErrors } from '../global/global.actions';
import { loadTreeVersionByTreeVersionId } from '../treeVersion/treeVersion.actions';
import { toastError, toastSuccess } from '../../shared/utils/toastUtils';

export const loadMemoVersionsSuccess = (versions) => {
  return { type: LOAD_MEMO_VERSIONS_SUCCESS, payload: versions };
};
export const checkMemoVersionsLoaded = () => {
  return { type: CHECK_MEMO_VERSIONS_LOADED };
};

export const loadMemosSuccess = (memos) => {
  return { type: LOAD_MEMOS_SUCCESS, payload: memos };
};

export const checkMemosLoaded = () => {
  return { type: CHECK_MEMOS_LOADED };
};

export const checkAllMemosLoaded = () => {
  return { type: CHECK_ALL_MEMOS_LOADED };
};

export const loadAllMemosSuccess = (memos) => {
  return { type: LOAD_ALL_MEMOS_SUCCESS, payload: memos };
};

export const checkSimilarMemosLoaded = () => {
  return { type: CHECK_SIMILAR_MEMOS_LOADED };
};

export const loadAllMemoApprovalsSuccess = (memos) => {
  return { type: LOAD_ALL_MEMO_APPROVALS_SUCCESS, payload: memos };
};

export const checkAllMemoApprovalsLoaded = () => {
  return { type: CHECK_ALL_MEMO_APPROVALS_LOADED };
};

export const createMemoApprovalSuccess = (memoApproval) => {
  return { type: CREATE_MEMO_APPROVAL_SUCCESS, payload: memoApproval };
};

export const checkCreateMemoApprovalLoaded = () => {
  return { type: CHECK_CREATE_MEMO_APPROVAL_LOADED };
};

export const updateMemoApprovalSuccess = (memos) => {
  return { type: UPDATE_MEMO_APPROVAL_SUCCESS, payload: memos };
};

export const createMemoSuccess = (memo) => {
  return { type: CREATE_MEMO_SUCCESS, payload: memo };
};

export const getAdditionalInfoInstanceSuccess = (memos) => {
  return { type: GET_ADDITIONAL_INFO_SUCCESS, payload: memos };
};

export const saveAdditionalInfoForCreatedMemoSuccess = (memos) => {
  return { type: CREATE_MEMO_ADDITIONAL_INFO_SUCCESS, payload: memos };
};

export const generateMemoForCreatedMemoSuccess = (memo) => {
  return { type: GENERATE_MEMO_SUCCESS, payload: memo };
};

export const uploadMemoFileForCreatedMemoSuccess = (memo) => {
  return { type: UPLOAD_MEMO_FILE_SUCCESS, payload: memo };
};

export const loadMemoApprovalsSuccess = (memo) => {
  return { type: LOAD_MEMO_APPROVALS_SUCCESS, payload: memo };
};
export const checkMemoApprovalsLoaded = () => {
  return { type: CHECK_MEMO_APPROVALS_LOADED };
};

export const loadMemoRequestsSuccess = (memo) => {
  return { type: LOAD_MEMO_REQUESTS_SUCCESS, payload: memo };
};
export const checkMemoRequestsLoaded = () => {
  return { type: CHECK_MEMO_REQUESTS_LOADED };
};

export const uploadMemoFileSuccess = (memo) => {
  return { type: UPLOAD_MEMO_FILE_SUCCESS, payload: memo };
};

export const getMemoFilesSuccess = (files) => {
  return { type: LOAD_FILES_SUCCESS, payload: files };
};

export const getAllMemoFilesSuccess = (files) => {
  return { type: LOAD_ALL_FILES_SUCCESS, payload: files };
};

export const getAllApprovalStatusSuccess = (status) => {
  return { type: LOAD_ALL_APPROVAL_STATUS_SUCCESS, payload: status };
};

export const loadApprovedMemoApprovalsSuccess = (status) => {
  return { type: LOAD_APPROVED_MEMO_APPROVALS, payload: status };
};

export const checkApprovedMemoApprovalsLoaded = () => {
  return { type: CHECK_APPROVED_MEMO_APPROVALS_LOADED };
};

export const createNoteSuccess = (data) => {
  return { type: CREATE_NOTE_SUCCESS, payload: data };
};

export const updateNoteSuccess = (data) => {
  return { type: UPDATE_NOTE_SUCCESS, payload: data };
};

export const loadNotesSuccess = (data) => {
  return { type: LOAD_NOTES_SUCCESS, payload: data };
};

export const checkMemoSaving = () => {
  return { type: CHECK_MEMO_SAVING };
};

export const memoSavingSuccess = () => {
  return { type: MEMO_SAVING_SUCCESS };
};

export const memoSavingError = () => {
  return { type: MEMO_SAVING_ERROR };
};

export const checkMemoNotes = () => {
  return { type: LOAD_NOTE_PENDING };
};

export const postNotePending = () => {
  return { type: POST_NOTE_PENDING };
};

export const postNoteSuccess = () => {
  return { type: POST_NOTE_SUCCESS };
};

export const changeMemoStatusLoading = () => {
  return { type: CHANGE_MEMO_STATUS_LOADING };
};

export const changeMemoStatusMemoSuccess = () => {
  return { type: CHANGE_MEMO_STATUS_SUCCESS };
};

export const checkDashboardLoad = () => {
  return { type: CHECK_DASHBOARD_LOAD };
};

export const dashboardDataLoaded = (payload: DTO.DashboardData) => {
  return { type: DASHBOARD_DATA_LOADED, payload };
};

export const changeMemoStatus =
  (memoId: string, status: string) => (dispatch) => {
    dispatch(changeMemoStatusLoading());
    return memoApi
      .changeMemoApprovalStatus(memoId, status)
      .then(() => {
        return dispatch(changeMemoStatusMemoSuccess());
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };

export const memoNotesSuccess = (notes) => {
  return { type: LOAD_NOTES_SUCCESS, payload: notes };
};

export const memoDeleteNoteSuccess = (noteId: string) => {
  return { type: DELETE_NOTE_SUCCESS, payload: noteId };
};

export const loadMemoDraftsSuccess = (drafts: DTO.Draft[]) => {
  return { type: GET_MEMO_DRAFTS_SUCCESS, payload: drafts };
};

export const loadDrafts = () => {
  return { type: GET_MEMO_DRAFTS_LOADING };
};

export const loadDeleteDraft = () => {
  return { type: DELETE_DRAFT_LOADING };
};

export const deleteDraftSuccess = (draftId: number) => {
  return { type: DELETE_DRAFT_SUCCESS, payload: draftId };
};
export const memoDeleteApprovalSuccess = (approvalId: string) => {
  return { type: DELETE_APPROVAL_SUCCESS, payload: approvalId };
};

export const checkFiltersData = () => {
  return { type: GET_FILTERS_DATA_PENDING };
};

export const loadedFiltersData = (data: DTO.TableFiltersData) => {
  return { type: GET_FILTERS_DATA_FULFILLED, payload: data };
};

export const setFileLimitPopupOpen = (isOpen: boolean) => {
  return { type: SET_FILE_LIMIT_POPUP, payload: isOpen };
};

export const loadMemoNotes = (userId: string, memoId: number) => (dispatch) => {
  dispatch(checkMemoNotes());
  return memoApi
    .getMemoNotes(userId, memoId)
    .then((notes) => {
      return dispatch(memoNotesSuccess(notes));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const postMemoNotes =
  (memoApprovalId: number, note: string, userId: string) => (dispatch) => {
    dispatch(postNotePending());
    return memoApi
      .postMemoNote(memoApprovalId, note, userId)
      .then(() => {
        return dispatch(postNoteSuccess());
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };

export const deleteNote = (memoApprovalNoteId: string) => (dispatch) => {
  dispatch(checkMemoNotes());
  return memoApi
    .deleteNote(memoApprovalNoteId)
    .then(() => {
      return dispatch(memoDeleteNoteSuccess(memoApprovalNoteId));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const deleteDraft = (draftId: number) => (dispatch) => {
  dispatch(loadDeleteDraft());
  return memoApi
    .deleteDraft(draftId)
    .then(() => {
      return dispatch(deleteDraftSuccess(draftId));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const editMemoNote =
  (noteId: number, noteText: string, approvalId: number, userId: string) =>
  (dispatch) => {
    dispatch(postNotePending());
    return memoApi
      .editMemoNote(noteId, noteText, approvalId, userId)
      .then(() => {
        return dispatch(postNoteSuccess());
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };

export const loadMemoFiltersData = () => (dispatch) => {
  dispatch(checkFiltersData());
  return memoApi
    .getMemoTableFiltersData()
    .then((filtersData) => {
      return dispatch(loadedFiltersData(filtersData));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadMemoVersions = (memoId: string) => (dispatch) => {
  dispatch(checkMemoVersionsLoaded());
  return memoApi
    .getMemoVersions(memoId)
    .then((versions) => {
      return dispatch(loadMemoVersionsSuccess(versions));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadMemos = (userId: string) => (dispatch) => {
  dispatch(checkMemosLoaded());
  return memoApi
    .getMemos(userId)
    .then((memos) => {
      return dispatch(loadMemosSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadAllMemos = () => (dispatch) => {
  dispatch(checkAllMemosLoaded());
  return memoApi
    .getAllMemos()
    .then((memos) => {
      return dispatch(loadAllMemosSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadDashboardData = () => (dispatch) => {
  dispatch(checkDashboardLoad());
  return memoApi
    .getDashboardData()
    .then((data) => {
      return dispatch(dashboardDataLoaded(data));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadAllMemoApprovals = () => (dispatch) => {
  dispatch(checkAllMemoApprovalsLoaded());
  return memoApi
    .getAllMemoApprovals()
    .then((memos) => {
      return dispatch(loadAllMemoApprovalsSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const deleteMemoApproval = (approvalId: string) => (dispatch) => {
  dispatch(checkMemoRequestsLoaded());
  return memoApi
    .deleteMemoApproval(approvalId)
    .then(() => {
      return dispatch(memoDeleteApprovalSuccess(approvalId));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const createMemoApproval =
  (memoApprovalData, params: DTO.GetActiveMemoQueryParams) => (dispatch) => {
    dispatch(checkCreateMemoApprovalLoaded());
    return memoApi
      .postMemoApproval(memoApprovalData)
      .then((memos) => {
        toastSuccess(resources.en.translations['success submit']);
        return dispatch(createMemoApprovalSuccess(memos));
      })
      .then(() => dispatch(getActiveMemoList(params)))
      .catch(() => {
        toastError(resources.en.translations['error submit']);
      });
  };

export const changeMemoApproval =
  (memoApprovalData, memoApprovalId) => (dispatch) => {
    return memoApi
      .updateMemoApproval(memoApprovalData, memoApprovalId)
      .then((memos) => {
        toastSuccess(resources.en.translations['success update']);
        return dispatch(updateMemoApprovalSuccess(memos));
      })
      .catch((error) => {
        toastError(resources.en.translations['error update']);
        dispatch(handleErrors(error));
      });
  };

export const loadMemoApprovals = (userId) => (dispatch) => {
  dispatch(checkMemoApprovalsLoaded());
  return memoApi
    .getApproverMemos(userId)
    .then((memos) => {
      return dispatch(loadMemoApprovalsSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadApprovedMemoApprovals = (userId: string) => (dispatch) => {
  dispatch(checkApprovedMemoApprovalsLoaded());
  return memoApi
    .getApprovedMemoApprovals(userId)
    .then((memos) => {
      return dispatch(loadApprovedMemoApprovalsSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadMemoRequests = (userId) => (dispatch) => {
  dispatch(checkMemoRequestsLoaded());
  return memoApi
    .getRequestedMemos(userId)
    .then((memos) => {
      return dispatch(loadMemoRequestsSuccess(memos));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

//TODO: remove
export const createMemoOld =
  (data: DTO.PostMemoBody, memoAdditionalInfoItems) => async (dispatch) => {
    dispatch(checkMemoSaving());
    try {
      const memoResponse = await memoApi.postMemo(data);

      dispatch(createMemoSuccess(memoResponse));

      const memoAdditionalInfoList: MEMO.MemoAdditionalInfo[] =
        memoAdditionalInfoItems.map((additionalInfoId) => {
          return {
            additional_info: additionalInfoId,
            memo: memoResponse.memo_id
          };
        });

      await dispatch(saveAdditionalInfoForCreatedMemo(memoAdditionalInfoList));
      await dispatch(generateMemoForCreatedMemo(memoResponse));
      dispatch(memoSavingSuccess());
    } catch (error) {
      dispatch(memoSavingError());
      toastError(resources.en.translations['memo name exist']);
      throw error;
    }
  };

const createMemoPending = () => ({ type: CREATE_MEMO_PENDING });
const createMemoFulfilled = () => ({ type: CREATE_MEMO_FULFILLED });
const createMemoRejected = () => ({ type: CREATE_MEMO_REJECTED });

export const createMemo =
  (data: DTO.CreateMemoBody, redirectCallback: (memoId: number) => void) =>
  async (dispatch) => {
    try {
      dispatch(createMemoPending());
      const res = await memoApi.createMemo(data);
      redirectCallback(res.data.memoId);
      dispatch(createMemoFulfilled());
    } catch (e) {
      dispatch(createMemoRejected());
    }
  };

export const getAdditionalInfoInstance = (key, nodeId) => (dispatch) => {
  return additionalInfoApi
    .getAdditionalInfo(key, nodeId)
    .then((additionalInfoInstance) => {
      return dispatch(getAdditionalInfoInstanceSuccess(additionalInfoInstance));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const saveAdditionalInfoForCreatedMemo =
  (memoAdditionalInfoList: MEMO.MemoAdditionalInfo[]) => (dispatch) => {
    return additionalInfoApi
      .saveMemoAdditionalInfo(memoAdditionalInfoList)
      .then((savedInstance) => {
        return dispatch(saveAdditionalInfoForCreatedMemoSuccess(savedInstance));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };

export const generateMemoForCreatedMemo = (memo) => (dispatch) => {
  return memoApi
    .generateMemo(memo)
    .then((memoInstance) => {
      dispatch(generateMemoForCreatedMemoSuccess(memoInstance));
      return toastSuccess(resources.en.translations['success upload']);
    })
    .catch(() => {
      toastError(resources.en.translations['error generate']);
    });
};

export const uploadMemoFileForCreatedMemo = (memoId, data) => (dispatch) => {
  return memoApi
    .uploadMemoFile(memoId, data)
    .then((fileResponse) => {
      toastSuccess(resources.en.translations['success upload']);
      return dispatch(uploadMemoFileForCreatedMemoSuccess(fileResponse));
    })
    .catch(() => {
      toastError(resources.en.translations['error upload']);
    });
};

export const uploadMemo = (memoId, data) => (dispatch) => {
  return memoApi
    .uploadMemoFile(memoId, data)
    .then((responseMemo) => {
      return dispatch(uploadMemoFileSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const viewFilesMemo = (memoId) => (dispatch) => {
  return memoApi
    .getMemoFiles(memoId)
    .then((responseMemo) => {
      return dispatch(getMemoFilesSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadAllMemoFiles = () => (dispatch) => {
  return memoApi
    .getAllMemoFiles()
    .then((responseMemo) => {
      return dispatch(getAllMemoFilesSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadApprovalStatus = () => (dispatch) => {
  return memoApi
    .getAllApprovalStatus()
    .then((responseMemo) => {
      return dispatch(getAllApprovalStatusSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const createNote = (data) => (dispatch) => {
  return memoApi
    .postNote(data)
    .then((responseMemo) => {
      return dispatch(createNoteSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const updateNote = (data, id) => (dispatch) => {
  return memoApi
    .updateNote(data, id)
    .then((responseMemo) => {
      return dispatch(updateNoteSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const loadNotes = (memoApprovalId) => (dispatch) => {
  return memoApi
    .getNotes(memoApprovalId)
    .then((responseMemo) => {
      return dispatch(loadNotesSuccess(responseMemo));
    })
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export const getMemoQuestionnairePending = () => ({
  type: GET_MEMO_QUESTIONNAIRE_PENDING
});

export const getMemoQuestionnaireFulfilled = (
  payload: DTO.MemoQuestionnaire
) => ({
  type: GET_MEMO_QUESTIONNAIRE_FULFILLED,
  payload
});

export const getMemoQuestionnaireRejected = () => ({
  type: GET_MEMO_QUESTIONNAIRE_REJECTED
});

export const resetMemoQuestionnaireData = getMemoQuestionnaireRejected;

export const getMemoQuestionnaire =
  (memoId: string, treeVersionId?: string | null) => async (dispatch) => {
    try {
      dispatch(getMemoQuestionnairePending());
      if (treeVersionId) {
        dispatch(loadTreeVersionByTreeVersionId(treeVersionId));
      }
      const { data } = await memoApi.getMemoQuestionnaire(memoId);
      if (!treeVersionId) {
        dispatch(loadTreeVersionByTreeVersionId(data.treeVersionId));
      }
      return dispatch(getMemoQuestionnaireFulfilled(data));
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        dispatch(setFileLimitPopupOpen(true));
        return dispatch(enableRedirection('/memo'));
      }
      dispatch(getMemoQuestionnaireRejected());
      return dispatch(handleErrors(error));
    }
  };

const saveMemoQuestionnairePending = () => ({
  type: SAVE_MEMO_QUESTIONNAIRE_PENDING
});
const saveMemoQuestionnaireFulfilled = () => ({
  type: SAVE_MEMO_QUESTIONNAIRE_FULFILLED
});
const saveMemoQuestionnaireRejected = () => ({
  type: SAVE_MEMO_QUESTIONNAIRE_REJECTED
});

export const saveMemoQuestionnaire =
  (data: DTO.MemoQuestionnaire, memoId: string, updateSummary?: boolean) =>
  async (dispatch) => {
    try {
      dispatch(saveMemoQuestionnairePending());
      await memoApi.postMemoQuestionnaire(data);
      dispatch(saveMemoQuestionnaireFulfilled());
      dispatch(getMemoQuestionnaireFulfilled(data));
      updateSummary && (await dispatch(getMemoSummary(memoId)));
    } catch (e) {
      dispatch(saveMemoQuestionnaireRejected());
      dispatch(handleErrors(e));
    }
  };

export const uploadMemoVersionPending = () => {
  return { type: UPLOAD_MEMO_VERSION_PENDING };
};

export const uploadMemoVersionSuccess = (file) => {
  return { type: UPLOAD_MEMO_VERSION_SUCCESS, payload: file };
};

export const uploadMemoVersionRejected = () => {
  return { type: UPLOAD_MEMO_VERSION_REJECTED };
};

export const uploadMemoVersion = (
  files: File[],
  memoId: number,
  params: DTO.GetActiveMemoQueryParams
) => {
  return (dispatch) => {
    dispatch(uploadMemoVersionPending());
    return memoApi
      .postMemoFile(files, memoId)
      .then((tree) => dispatch(uploadMemoVersionSuccess(tree)))
      .then(() => dispatch(getMemoFilesList(memoId)))
      .then(() => dispatch(getActiveMemoList(params)))
      .catch(({ response }) => {
        dispatch(uploadMemoVersionRejected());
        toastError(response.data);
        return response.status;
      });
  };
};

const practitionerMemosLoading = () => {
  return { type: PRACTITIONER_MEMOS_LOADING };
};

const practitionerMemosSuccess = (memos) => {
  return { type: PRACTITIONER_MEMOS_SUCCESS, payload: memos };
};

export const loadPractitionerMemos = () => (dispatch) => {
  dispatch(practitionerMemosLoading());
  return memoApi
    .getPractitionerMemos()
    .then((memos) => dispatch(practitionerMemosSuccess(memos)))
    .catch((error) => dispatch(handleErrors(error)));
};

const activateMemoPending = () => ({
  type: ACTIVATE_MEMO_PENDING
});

const activateMemoFulfilled = () => ({
  type: ACTIVATE_MEMO_FULFILLED
});

const activateMemoRejected = () => ({
  type: ACTIVATE_MEMO_REJECTED
});

export const activateMemo =
  (isActivate: boolean, memoIds: number[]) => async (dispatch) => {
    dispatch(activateMemoPending());

    try {
      await memoApi.activateMemo({
        is_activate: isActivate,
        memo_ids: memoIds
      });
      toastSuccess(
        resources.en.translations[isActivate ? 'memo restored' : 'memo deleted']
      );
      dispatch(activateMemoFulfilled());
      dispatch(loadPractitionerMemos());
    } catch (error) {
      dispatch(activateMemoRejected());
      dispatch(handleErrors(error));
    }
  };

export const restoreMemo =
  (memoId: number, params: Partial<DTO.GetRecycledMemoListQueryParams>) =>
  async (dispatch) => {
    try {
      dispatch(activateMemoPending());
      await memoApi.activateMemo({ memo_ids: [memoId], is_activate: true });
      dispatch(activateMemoFulfilled());
      dispatch(getRecycledMemoList(params));
    } catch (e) {
      dispatch(activateMemoRejected());
      dispatch(handleErrors(e));
    }
  };

const inactivateMemoPending = () => ({
  type: INACTIVATE_MEMO_PENDING
});

const inactivateMemoSuccess = () => ({
  type: INACTIVATE_MEMO_SUCCESS
});

const inactivateMemoRejected = () => ({
  type: INACTIVATE_MEMO_REJECTED
});

export const inactivateMemo =
  (memoIds: number[], successCb: () => void) => async (dispatch) => {
    dispatch(inactivateMemoPending());

    try {
      await memoApi.inactivateMemo(memoIds);
      dispatch(inactivateMemoSuccess());
      successCb();
    } catch (error) {
      dispatch(inactivateMemoRejected());
      dispatch(handleErrors(error));
    }
  };

const getActiveMemoListPending = () => ({
  type: GET_ACTIVE_MEMO_LIST_PENDING
});

const getActiveMemoListFulfilled = (payload) => ({
  type: GET_ACTIVE_MEMO_LIST_FULFILLED,
  payload
});

const getActiveMemoListRejected = () => ({
  type: GET_ACTIVE_MEMO_LIST_REJECTED
});

export const getActiveMemoList =
  (params?: DTO.GetActiveMemoQueryParams) => async (dispatch) => {
    dispatch(getActiveMemoListPending());
    try {
      const { data } = await memoApi.getActiveMemoList(params);
      dispatch(getActiveMemoListFulfilled(data));
    } catch (error) {
      dispatch(getActiveMemoListRejected());
      handleErrors(error);
    }
  };

export const setActiveMemoParams = (params: DTO.GetActiveMemoQueryParams) => {
  return {
    type: SET_ACTIVE_MEMO_PARAMS,
    payload: params
  };
};

export const uploadMemoAttachmentPending = () => {
  return { type: UPLOAD_MEMO_ATTACHMENT_PENDING };
};

export const uploadMemoAttachmentSuccess = () => {
  return { type: UPLOAD_MEMO_ATTACHMENT_SUCCESS };
};

export const uploadMemoAttachmentRejected = () => {
  return { type: UPLOAD_MEMO_ATTACHMENT_REJECTED };
};

export const uploadMemoAttachment = (
  files: File[],
  memoId: number,
  params: DTO.GetActiveMemoQueryParams
) => {
  return (dispatch) => {
    dispatch(uploadMemoAttachmentPending());
    return memoApi
      .postMemoAttachment(files, memoId)
      .then(() => dispatch(uploadMemoAttachmentSuccess()))
      .then(() => dispatch(getMemoAttachmentList(memoId)))
      .then(() => dispatch(getActiveMemoList(params)))
      .catch(({ response }) => {
        dispatch(uploadMemoAttachmentRejected());
        toastError(response.data);
      });
  };
};

export const getMemoAttachmentListPending = () => {
  return { type: GET_MEMO_ATTACHMENT_LIST_PENDING };
};

export const getMemoAttachmentListSuccess = (files) => {
  return { type: GET_MEMO_ATTACHMENT_LIST_SUCCESS, payload: files };
};

export const getMemoAttachmentListRejected = () => {
  return { type: GET_MEMO_ATTACHMENT_LIST_REJECTED };
};

export const getMemoAttachmentList = (memoId: number) => {
  return (dispatch) => {
    dispatch(getMemoAttachmentListPending());
    return memoApi
      .getMemoAttachmentList(memoId)
      .then((data) => dispatch(getMemoAttachmentListSuccess(data)))
      .catch(({ response }) => {
        dispatch(getMemoAttachmentListRejected());
        if (response?.data) {
          toastError(response.data);
        }
      });
  };
};

export const deleteMemoAttachmentPending = () => {
  return { type: DELETE_MEMO_ATTACHMENT_PENDING };
};

export const deleteMemoAttachmentSuccess = () => {
  return { type: DELETE_MEMO_ATTACHMENT_SUCCESS };
};

export const deleteMemoAttachmentRejected = () => {
  return { type: DELETE_MEMO_ATTACHMENT_REJECTED };
};

export const deleteMemoAttachment =
  (attachmentId: string, memoId: number) => (dispatch) => {
    dispatch(deleteMemoAttachmentPending());
    return memoApi
      .deleteMemoAttachment(attachmentId)
      .then(() => dispatch(deleteMemoAttachmentSuccess()))
      .then(() => dispatch(getMemoAttachmentList(memoId)))
      .catch((error) => {
        dispatch(deleteMemoAttachmentRejected());
        dispatch(handleErrors(error));
      });
  };

export const getMemoFilesListPending = () => {
  return { type: GET_MEMO_FILES_LIST_PENDING };
};

export const getMemoFilesListSuccess = (payload: DTO.MemoFile[]) => {
  return { type: GET_MEMO_FILES_LIST_SUCCESS, payload };
};

export const getMemoFilesListRejected = () => {
  return { type: GET_MEMO_FILES_LIST_REJECTED };
};

export const getMemoFilesList = (memoId: number) => async (dispatch) => {
  try {
    dispatch(getMemoFilesListPending());
    const { data } = await memoApi.getMemoFilesList(memoId);
    return dispatch(getMemoFilesListSuccess(data));
  } catch (error) {
    dispatch(getMemoFilesListRejected());
    return dispatch(handleErrors(error));
  }
};

export const updateMemoSharingList = (memoId: number, userNames: string[]) => {
  return {
    type: UPDATE_MEMO_SHARING_LIST,
    payload: { memoId, userNames }
  };
};

export const deleteMemoFilePending = () => ({
  type: DELETE_MEMO_FILE_PENDING
});

export const deleteMemoFileFulfilled = () => ({
  type: DELETE_MEMO_FILE_FULFILLED
});

export const deleteMemoFileRejected = () => ({
  type: DELETE_MEMO_FILE_REJECTED
});

export const deleteMemoFile =
  (memoId: number, fileId: string) => async (dispatch) => {
    try {
      dispatch(deleteMemoFilePending());
      await memoApi.deleteMemoFile(fileId);
      dispatch(deleteMemoFileFulfilled());
      dispatch(getMemoFilesList(memoId));
    } catch (error) {
      dispatch(deleteMemoFileRejected());
      dispatch(handleErrors(error));
    }
  };

export const changeActiveMemoStatusPending = () => ({
  type: POST_ACTIVE_MEMO_STATUS_PENDING
});

export const changeActiveMemoStatusSuccess = () => ({
  type: POST_ACTIVE_MEMO_STATUS_SUCCESS
});

export const changeActiveMemoStatusRejected = () => ({
  type: POST_ACTIVE_MEMO_STATUS_REJECTED
});

export const changeActiveMemoStatus =
  (memoId: number, status: string, params: DTO.GetActiveMemoQueryParams) =>
  (dispatch) => {
    dispatch(changeActiveMemoStatusPending());
    return memoApi
      .postActiveMemoStatus(memoId, status)
      .then(() => dispatch(changeActiveMemoStatusSuccess()))
      .then(() => dispatch(getActiveMemoList(params)))
      .catch((error) => {
        dispatch(changeActiveMemoStatusRejected());
        dispatch(handleErrors(error));
      });
  };

export const getMemoSummaryPending = () => ({
  type: GET_MEMO_SUMMARY_PENDING
});

export const getMemoSummarySuccess = (data) => ({
  type: GET_MEMO_SUMMARY_SUCCESS,
  payload: data
});

export const getMemoSummaryRejected = () => ({
  type: GET_MEMO_SUMMARY_REJECTED
});

export const resetMemoSummaryData = () => ({
  type: RESET_MEMO_SUMMARY_DATA
});

export const getMemoSummary = (memoId: string) => (dispatch) => {
  dispatch(getMemoSummaryPending());
  return memoApi
    .getMemoSummary(memoId)
    .then((data) => dispatch(getMemoSummarySuccess(data)))
    .catch((error) => {
      dispatch(getMemoSummaryRejected());
      dispatch(handleErrors(error));
    });
};

export const getRecycledMemoListPending = () => ({
  type: GET_RECYCLED_MEMO_LIST_PENDING
});
export const getRecycledMemoListSuccess = (
  payload: DTO.RecycledMemoListResponse
) => ({
  type: GET_RECYCLED_MEMO_LIST_SUCCESS,
  payload
});
export const getRecycledMemoListRejected = () => ({
  type: GET_RECYCLED_MEMO_LIST_REJECTED
});

export const getRecycledMemoList =
  (params?: Partial<DTO.GetRecycledMemoListQueryParams>) =>
  async (dispatch) => {
    try {
      dispatch(getRecycledMemoListPending());
      const { data } = await memoApi.getRecycledMemoList(params);
      dispatch(getRecycledMemoListSuccess(data));
    } catch (e) {
      dispatch(getRecycledMemoListRejected());
      dispatch(handleErrors(e));
    }
  };

export const getRecycledMemoFilterPending = () => ({
  type: GET_RECYCLED_MEMO_FILTER_PENDING
});
export const getRecycledMemoFilterSuccess = (
  payload: DTO.RecycledMemoFilter
) => ({
  type: GET_RECYCLED_MEMO_FILTER_SUCCESS,
  payload
});
export const getRecycledMemoFilterRejected = () => ({
  type: GET_RECYCLED_MEMO_FILTER_REJECTED
});

export const getRecycledMemoFilter = () => async (dispatch) => {
  try {
    dispatch(getRecycledMemoFilterPending());
    const { data } = await memoApi.getRecycledMemoFilter();
    dispatch(getRecycledMemoFilterSuccess(data));
  } catch (e) {
    dispatch(getRecycledMemoFilterRejected());
    dispatch(handleErrors(e));
  }
};

export const setActiveFileListMemoData = (
  payload: DTO.WSSomeMemoFilesScan | null
) => ({
  type: SET_ACTIVE_FILE_LIST_MEMO_DATA,
  payload
});

export const setMemoFileScanStatus = (payload: DTO.WSCurrentMemoFilesScan) => ({
  type: SET_MEMO_FILE_SCAN_STATUS,
  payload
});

export const updateMemoFileScanStatus =
  (newFileData: DTO.WSCurrentMemoFilesScan) => async (dispatch, getState) => {
    const isAttachments = newFileData.file_type === 'attachment';
    const memoState = (getState() as RootState).memo;
    const memoData = memoState.activeFileListMemoData;

    if (!memoData) return;

    let files: DTO.MemoFile[];
    let loading: boolean;

    if (isAttachments) {
      files = memoState.memoAttachmentList;
      loading = memoState.memoAttachmentListLoading;
    } else {
      files = memoState.memoFilesList;
      loading = memoState.memoFilesListLoading;
    }

    const fileToUpdate = files.find(
      ({ id }) => id === newFileData.file_metadata_id
    );

    if (fileToUpdate) {
      if (fileToUpdate.scan_status !== newFileData.scan_status) {
        dispatch(setMemoFileScanStatus(newFileData));
      }
    } else if (!loading) {
      if (isAttachments) {
        dispatch(getMemoAttachmentList(memoData.memo_id));
      } else {
        dispatch(
          getMemoFilesList(memoData.memo_version_id || memoData.memo_id)
        );
      }
    }
  };

export const clearMemoFilesAndAttachments = () => (dispatch) => {
  dispatch(getMemoFilesListSuccess([]));
  dispatch(getMemoAttachmentListSuccess([]));
};
