import React, { FC, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'store/selectors/userSelectors';
import Footer from '../Footer/Footer';
import './AppLayout.scss';
import { HeaderOmnia } from '../HeaderOmnia/HeaderOmnia';
import dataHandler from '../../../common/dataHandler';
import { SidebarMenu } from '../SidebarMenu/SidebarMenu';

interface Props {
  children: ReactNode;
}

const AppLayout: FC<Props> = (props) => {
  const { children } = props;
  const currentUser = useSelector(selectCurrentUser);

  const menuItems = useMemo(() => {
    if (currentUser.user_type && currentUser.is_TOU_accepted) {
      return dataHandler.getMenuItems(currentUser.user_type);
    }
    return [];
  }, [currentUser.is_TOU_accepted, currentUser.user_type]);

  return (
    <div className='memo-page-wrapper'>
      <HeaderOmnia />
      <div className='memo-page-wrapper__app-name'>Memo Logic</div>
      {currentUser && Object.keys(currentUser).length !== 0 ? (
        <>
          <SidebarMenu menuItems={menuItems} />
          <div className='memo-page-wrapper__content'>
            {children}
            <Footer />
          </div>
        </>
      ) : (
        <>
          <div>{children}</div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default AppLayout;
