export const TIMEOUT_TO_KEEP_ALIVE = 5;

export const POPUP_TIMEOUT = 'POPUP_TIMEOUT';

export const SHOW_LOGOUT_POPUP_AT = 'SHOW_LOGOUT_POPUP_AT';

export const USER_ACTIVITY = 'USER_ACTIVITY';

export const SESSION_EXPIRES_AT = 'SESSION_EXPIRES_AT';

export const USER_AUTHORIZED = 'USER_AUTHORIZED';
