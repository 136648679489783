import React from 'react';
import { Trans } from 'react-i18next';

import './Footer.scss';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className='memo-footer'>
      <p className='memo-footer__copyright'>
        <Trans
          i18nKey='footer copyright'
          values={{ currentYear }}
          components={[
            <a
              rel='noreferrer'
              download='Privacy.pdf'
              href='/static/documents/Privacy.pdf'
              key={0}
              aria-label='Privacy link'
            />,
            <a
              rel='noreferrer'
              download='Notices.pdf'
              href='/static/documents/Notices.pdf'
              key={1}
              aria-label='Notices pdf'
            />
          ]}
        />
      </p>
      <p className='memo-footer__text'>
        <Trans
          i18nKey='footer'
          components={[
            <a
              rel='noreferrer'
              target='_blank'
              href='https://www.deloitte.com/about'
              key={0}
              aria-label='https://www.deloitte.com/about'
            />
          ]}
        />
      </p>
    </footer>
  );
};

export default Footer;
