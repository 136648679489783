import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getRootAppNode } from 'shared/utils/get-root-node';
import { selectCurrentUser } from '../../../store/selectors/userSelectors';
import './UserMenu.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { authProvider } from '../../../authProvider';
import headset from '../../../images/Headset.svg';
import { SESSION_EXPIRES_AT } from '../../../shared/constants/loginConstants';
import {
  calculateEndTime,
  setToLocalStorage
} from '../../../shared/utils/storageUtils';
import { ThemedButtonOld } from '../ThemedButtonOld/ThemedButtonOld';

const UserMenu = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getFirstLetter = (word: string) => {
    return word?.toUpperCase().charAt(0);
  };

  const handleLogout = useCallback(() => {
    setOpened(false);
    setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime());
    setTimeout(() => authProvider.logoutRedirect(), 2000);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        opened
      ) {
        setOpened(false);
      }
    };
    getRootAppNode().addEventListener('click', handleClickOutside);

    return () => {
      getRootAppNode().removeEventListener('click', handleClickOutside);
    };
  }, [opened]);

  const userIcon = (
    <span className='user-menu__toggle-text'>
      {getFirstLetter(currentUser.first_name)}
      {getFirstLetter(currentUser.last_name)}
    </span>
  );

  return (
    <div className='user-menu'>
      <button
        className={classNames('user-menu__toggle', {
          'user-menu__toggle--active': opened
        })}
        type='button'
        onClick={() => setOpened(true)}
      >
        {userIcon}
      </button>
      <div
        ref={dropdownRef}
        className={classNames('user-menu__dropdown', {
          'user-menu__dropdown--active': opened
        })}
      >
        <div className='user-menu__item'>
          <div className='user-menu__icon'>{userIcon}</div>
          <div>
            <p className='user-menu__name'>
              {currentUser.first_name} {currentUser.last_name}
            </p>
            <p className='user-menu__email'>{currentUser.user_email}</p>
          </div>
        </div>
        <div className='user-menu__item'>
          <a
            href='https://deloitteus.service-now.com/sp?id=sp_kb_article&sys_id=3c465ab71be62894739a64207e4bcb56'
            target='_blank'
            rel='noreferrer'
            className='user-menu__contact-support-link'
          >
            <img
              src={headset}
              alt='headset'
              className='user-menu__contact-support-icon'
            />
            {t('contact support')}
          </a>
        </div>
        <div className='user-menu__item'>
          <a digitaldata-logout=''>
            <ThemedButtonOld
              className='user-menu__logout-btn'
              onClick={handleLogout}
            >
              {t('log out')}
            </ThemedButtonOld>
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
