import { Reducer } from 'redux';

import {
  MemoActionTypes,
  MemoState,
  LOAD_MEMOS_SUCCESS,
  CHECK_MEMOS_LOADED,
  CHECK_ALL_MEMOS_LOADED,
  CHECK_SIMILAR_MEMOS_LOADED,
  LOAD_ALL_MEMOS_SUCCESS,
  LOAD_ALL_MEMO_APPROVALS_SUCCESS,
  CREATE_MEMO_APPROVAL_SUCCESS,
  CREATE_MEMO_SUCCESS,
  GET_ADDITIONAL_INFO_SUCCESS,
  CREATE_MEMO_ADDITIONAL_INFO_SUCCESS,
  GENERATE_MEMO_SUCCESS,
  UPLOAD_MEMO_FILE_SUCCESS,
  LOAD_MEMO_APPROVALS_SUCCESS,
  CHECK_CREATE_MEMO_APPROVAL_LOADED,
  LOAD_MEMO_REQUESTS_SUCCESS,
  CHECK_MEMO_REQUESTS_LOADED,
  LOAD_FILES_SUCCESS,
  LOAD_ALL_FILES_SUCCESS,
  LOAD_ALL_APPROVAL_STATUS_SUCCESS,
  LOAD_APPROVED_MEMO_APPROVALS,
  CHECK_APPROVED_MEMO_APPROVALS_LOADED,
  CREATE_NOTE_SUCCESS,
  UPDATE_NOTE_SUCCESS,
  LOAD_NOTES_SUCCESS,
  CHECK_ALL_MEMO_APPROVALS_LOADED,
  LOAD_MEMO_VERSIONS_SUCCESS,
  CHECK_MEMO_VERSIONS_LOADED,
  GET_MEMO_QUESTIONNAIRE_PENDING,
  GET_MEMO_QUESTIONNAIRE_FULFILLED,
  GET_MEMO_QUESTIONNAIRE_REJECTED,
  DELETE_NOTE_SUCCESS,
  POST_NOTE_SUCCESS,
  POST_NOTE_PENDING,
  LOAD_NOTE_PENDING,
  DELETE_APPROVAL_SUCCESS,
  CHANGE_MEMO_STATUS_SUCCESS,
  CHANGE_MEMO_STATUS_LOADING,
  UPLOAD_MEMO_VERSION_PENDING,
  UPLOAD_MEMO_VERSION_SUCCESS,
  UPLOAD_MEMO_VERSION_REJECTED,
  PRACTITIONER_MEMOS_LOADING,
  PRACTITIONER_MEMOS_SUCCESS,
  ACTIVATE_MEMO_PENDING,
  ACTIVATE_MEMO_FULFILLED,
  ACTIVATE_MEMO_REJECTED,
  INACTIVATE_MEMO_PENDING,
  INACTIVATE_MEMO_SUCCESS,
  INACTIVATE_MEMO_REJECTED,
  CHECK_DASHBOARD_LOAD,
  DASHBOARD_DATA_LOADED,
  GET_ACTIVE_MEMO_LIST_PENDING,
  GET_ACTIVE_MEMO_LIST_FULFILLED,
  GET_ACTIVE_MEMO_LIST_REJECTED,
  GET_FILTERS_DATA_PENDING,
  GET_FILTERS_DATA_FULFILLED,
  UPLOAD_MEMO_ATTACHMENT_PENDING,
  UPLOAD_MEMO_ATTACHMENT_SUCCESS,
  UPLOAD_MEMO_ATTACHMENT_REJECTED,
  GET_MEMO_ATTACHMENT_LIST_PENDING,
  GET_MEMO_ATTACHMENT_LIST_SUCCESS,
  GET_MEMO_ATTACHMENT_LIST_REJECTED,
  DELETE_MEMO_ATTACHMENT_PENDING,
  DELETE_MEMO_ATTACHMENT_SUCCESS,
  DELETE_MEMO_ATTACHMENT_REJECTED,
  SET_ACTIVE_MEMO_PARAMS,
  GET_MEMO_FILES_LIST_PENDING,
  GET_MEMO_FILES_LIST_SUCCESS,
  GET_MEMO_FILES_LIST_REJECTED,
  DELETE_MEMO_FILE_PENDING,
  DELETE_MEMO_FILE_FULFILLED,
  DELETE_MEMO_FILE_REJECTED,
  SET_FILE_LIMIT_POPUP,
  CREATE_MEMO_PENDING,
  CREATE_MEMO_FULFILLED,
  CREATE_MEMO_REJECTED,
  SAVE_MEMO_QUESTIONNAIRE_PENDING,
  SAVE_MEMO_QUESTIONNAIRE_FULFILLED,
  SAVE_MEMO_QUESTIONNAIRE_REJECTED,
  POST_ACTIVE_MEMO_STATUS_PENDING,
  POST_ACTIVE_MEMO_STATUS_SUCCESS,
  POST_ACTIVE_MEMO_STATUS_REJECTED,
  GET_MEMO_SUMMARY_PENDING,
  GET_MEMO_SUMMARY_SUCCESS,
  GET_MEMO_SUMMARY_REJECTED,
  GET_RECYCLED_MEMO_LIST_PENDING,
  GET_RECYCLED_MEMO_LIST_SUCCESS,
  GET_RECYCLED_MEMO_LIST_REJECTED,
  GET_RECYCLED_MEMO_FILTER_PENDING,
  GET_RECYCLED_MEMO_FILTER_SUCCESS,
  GET_RECYCLED_MEMO_FILTER_REJECTED,
  UPDATE_MEMO_SHARING_LIST,
  SET_ACTIVE_FILE_LIST_MEMO_DATA,
  SET_MEMO_FILE_SCAN_STATUS,
  RESET_MEMO_SUMMARY_DATA
} from './memo.types';

const initialRecycledMemoFilter = {
  clients: {},
  statuses: {},
  industries: {},
  accountingStandards: []
};

const initialState: MemoState = {
  memoVersions: [],
  isMemoVersionsLoaded: false,
  memoList: [],
  isMemoListLoaded: false,
  allMemoList: [],
  isLoadedAllMemoList: false,
  isLoadingAllMemoList: false,
  similarMemoList: null,
  isLoadedSimilarMemoList: false,
  isLoadingSimilarMemoList: false,
  files: [],
  allFiles: [],
  memoApprovalInstance: null,
  isMemoApprovalInstanceLoaded: false,
  memoApprovalList: [],
  isMemoApprovalsListLoaded: false,
  memoRequestList: [],
  isMemoRequestsListLoaded: false,
  approvalStatusList: [],
  allMemoApprovalList: [],
  isAllMemoApprovalListLoaded: false,
  isAllMemoApprovalListLoading: false,
  approvedMemoApprovalList: [],
  isApprovedMemoApprovalListLoaded: false,
  createdMemoInstance: null,
  additionalInfoInstance: null,
  generatedMemoInstance: null,
  generatedFileInstance: null,
  savedAdditionalInfoInstance: null,
  memoQuestionnaire: null,
  isMemoQuestionnaireLoading: false,
  isLoadingMemoNotes: false,
  isPostingMemoNotes: false,
  memoNotes: [],
  isChangingMemoApproval: false,
  memoVersionFile: {} as File,
  isMemoVersionFileLoading: false,
  isPractitionerMemosLoading: false,
  practitionerMemos: [],
  dashboardData: null,
  isLoadingDashboard: false,
  activeMemoList: { totalCount: 0, pagedRecords: [] },
  activeMemoParams: {},
  activeMemoListLoading: false,
  filtersData: {
    clients: {},
    industries: {},
    statuses: {
      notSubmitted: '',
      draft: '',
      pending: '',
      approved: '',
      rejected: ''
    },
    accountingStandards: []
  },
  isLoadingFilters: false,
  isActiveMemoLoading: false,
  uploadMemoAttachmentLoading: false,
  memoAttachmentList: [],
  memoAttachmentListLoading: false,
  memoAttachmentDeleting: false,
  memoFilesList: [],
  memoFilesListLoading: false,
  showFileLimitPopup: false,
  createMemoLoading: false,
  saveMemoQuestionnaireLoading: false,
  activeMemoStatusChangeLoading: false,
  memoSummaryLoading: false,
  memoSummaryData: {} as DTO.Summary,
  recycledMemoList: [],
  recycledMemoListLoading: false,
  recycledMemoTotalCount: 0,
  recycledMemoFilter: initialRecycledMemoFilter,
  recycledMemoFilterLoading: false,
  // INFO: this key uses to open memo files modal by selecting memo from table or by ws if file scan failed
  // We need this as object (not memo_id only) because there is no api for get memo by id - so we can't get memo data from modal for now
  activeFileListMemoData: null
};
//TODO: DELETE OLD FUNCTIONALITY
const memoReducer: Reducer<MemoState, MemoActionTypes> = (
  state = initialState,
  action
): MemoState => {
  switch (action.type) {
    case LOAD_MEMO_VERSIONS_SUCCESS:
      return {
        ...state,
        memoVersions: action.payload,
        isMemoVersionsLoaded: true
      };
    case CHECK_MEMO_VERSIONS_LOADED:
      return { ...state, isMemoVersionsLoaded: false };
    case LOAD_MEMOS_SUCCESS:
      return { ...state, memoList: action.payload, isMemoListLoaded: true };
    case CHECK_MEMOS_LOADED:
      return { ...state, isMemoListLoaded: false };
    case CHECK_ALL_MEMOS_LOADED:
      return {
        ...state,
        isLoadedAllMemoList: false,
        isLoadingAllMemoList: true
      };
    case LOAD_ALL_MEMOS_SUCCESS:
      return {
        ...state,
        allMemoList: action.payload,
        isLoadedAllMemoList: true,
        isLoadingAllMemoList: false
      };
    case CHECK_SIMILAR_MEMOS_LOADED:
      return {
        ...state,
        isLoadedSimilarMemoList: false,
        isLoadingSimilarMemoList: true
      };
    case LOAD_FILES_SUCCESS:
      return { ...state, files: action.payload };
    case LOAD_ALL_FILES_SUCCESS:
      return { ...state, allFiles: action.payload };
    case CHANGE_MEMO_STATUS_LOADING: {
      return { ...state, isChangingMemoApproval: true };
    }
    case CHANGE_MEMO_STATUS_SUCCESS: {
      return { ...state, isChangingMemoApproval: false };
    }
    case CREATE_MEMO_APPROVAL_SUCCESS:
      return {
        ...state,
        memoApprovalInstance: action.payload,
        isMemoApprovalInstanceLoaded: true
      };
    case CHECK_CREATE_MEMO_APPROVAL_LOADED:
      return {
        ...state,
        isMemoApprovalInstanceLoaded: false
      };
    case LOAD_MEMO_APPROVALS_SUCCESS:
      return {
        ...state,
        memoApprovalList: action.payload,
        isMemoApprovalsListLoaded: true
      };
    case LOAD_MEMO_REQUESTS_SUCCESS:
      return {
        ...state,
        memoRequestList: action.payload,
        isMemoRequestsListLoaded: true
      };
    case CHECK_MEMO_REQUESTS_LOADED:
      return {
        ...state,
        isMemoRequestsListLoaded: false
      };
    case LOAD_ALL_APPROVAL_STATUS_SUCCESS:
      return { ...state, approvalStatusList: action.payload };
    case LOAD_ALL_MEMO_APPROVALS_SUCCESS:
      return {
        ...state,
        allMemoApprovalList: action.payload,
        isAllMemoApprovalListLoaded: true,
        isAllMemoApprovalListLoading: false
      };
    case DELETE_APPROVAL_SUCCESS:
      return {
        ...state,
        isMemoRequestsListLoaded: true,
        allMemoApprovalList: state.allMemoApprovalList.filter(
          (memo) => memo.memo_approval_id.toString() !== action.payload
        )
      };
    case CHECK_ALL_MEMO_APPROVALS_LOADED:
      return {
        ...state,
        isAllMemoApprovalListLoaded: false,
        isAllMemoApprovalListLoading: true
      };
    case LOAD_APPROVED_MEMO_APPROVALS:
      return {
        ...state,
        approvedMemoApprovalList: action.payload,
        isApprovedMemoApprovalListLoaded: true
      };
    case CHECK_APPROVED_MEMO_APPROVALS_LOADED:
      return { ...state, isApprovedMemoApprovalListLoaded: false };
    case CREATE_MEMO_SUCCESS:
      return { ...state, createdMemoInstance: action.payload };

    case CREATE_MEMO_PENDING:
      return { ...state, createMemoLoading: true };
    case CREATE_MEMO_FULFILLED:
      return { ...state, createMemoLoading: false };
    case CREATE_MEMO_REJECTED:
      return { ...state, createMemoLoading: false };

    case GET_ADDITIONAL_INFO_SUCCESS:
      return { ...state, additionalInfoInstance: action.payload };
    case CREATE_MEMO_ADDITIONAL_INFO_SUCCESS:
      return { ...state, savedAdditionalInfoInstance: action.payload };
    case GENERATE_MEMO_SUCCESS:
      return { ...state, generatedMemoInstance: action.payload };
    case UPLOAD_MEMO_FILE_SUCCESS:
      return { ...state, generatedFileInstance: action.payload };

    case CREATE_NOTE_SUCCESS:
      return { ...state, isLoadingMemoNotes: false };
    case POST_NOTE_PENDING:
      return { ...state, isPostingMemoNotes: true };
    case LOAD_NOTE_PENDING:
      return { ...state, isLoadingMemoNotes: true };
    case POST_NOTE_SUCCESS:
      return { ...state, isPostingMemoNotes: false };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        isLoadingMemoNotes: false,
        memoNotes: state.memoNotes.filter(
          (note) => note.memo_approval_note_id.toString() !== action.payload
        )
      };
    case UPDATE_NOTE_SUCCESS:
      return { ...state, isLoadingMemoNotes: false };
    case LOAD_NOTES_SUCCESS:
      return { ...state, memoNotes: action.payload, isLoadingMemoNotes: false };

    case GET_FILTERS_DATA_PENDING: {
      return { ...state, isLoadingFilters: true };
    }
    case GET_FILTERS_DATA_FULFILLED: {
      return { ...state, isLoadingFilters: false, filtersData: action.payload };
    }
    case GET_MEMO_QUESTIONNAIRE_PENDING:
      return {
        ...state,
        isMemoQuestionnaireLoading: true,
        memoQuestionnaire: null
      };
    case GET_MEMO_QUESTIONNAIRE_FULFILLED: {
      const { payload } = action;

      return {
        ...state,
        isMemoQuestionnaireLoading: false,
        memoQuestionnaire: payload
      };
    }
    case GET_MEMO_QUESTIONNAIRE_REJECTED:
      return {
        ...state,
        isMemoQuestionnaireLoading: false,
        memoQuestionnaire: null
      };

    case SAVE_MEMO_QUESTIONNAIRE_PENDING:
      return {
        ...state,
        saveMemoQuestionnaireLoading: true
      };
    case SAVE_MEMO_QUESTIONNAIRE_FULFILLED:
      return {
        ...state,
        saveMemoQuestionnaireLoading: false
      };
    case SAVE_MEMO_QUESTIONNAIRE_REJECTED:
      return {
        ...state,
        saveMemoQuestionnaireLoading: false
      };

    case UPLOAD_MEMO_VERSION_PENDING:
      return {
        ...state,
        isMemoVersionFileLoading: true
      };
    case UPLOAD_MEMO_VERSION_SUCCESS:
      return {
        ...state,
        memoVersionFile: action.payload,
        isMemoVersionFileLoading: false
      };
    case UPLOAD_MEMO_VERSION_REJECTED:
      return {
        ...state,
        isMemoVersionFileLoading: false
      };
    case PRACTITIONER_MEMOS_LOADING:
      return {
        ...state,
        isPractitionerMemosLoading: true
      };
    case PRACTITIONER_MEMOS_SUCCESS:
      return {
        ...state,
        isPractitionerMemosLoading: false,
        practitionerMemos: action.payload
      };

    case ACTIVATE_MEMO_PENDING:
      return {
        ...state,
        isPractitionerMemosLoading: true,
        isActiveMemoLoading: true
      };

    case ACTIVATE_MEMO_FULFILLED:
      return {
        ...state,
        isPractitionerMemosLoading: false,
        isActiveMemoLoading: false
      };

    case ACTIVATE_MEMO_REJECTED:
      return {
        ...state,
        isPractitionerMemosLoading: false,
        isActiveMemoLoading: false
      };

    case INACTIVATE_MEMO_PENDING:
      return {
        ...state,
        isActiveMemoLoading: true
      };

    case INACTIVATE_MEMO_SUCCESS:
      return {
        ...state,
        isActiveMemoLoading: false
      };

    case INACTIVATE_MEMO_REJECTED:
      return {
        ...state,
        isActiveMemoLoading: false
      };

    case SET_FILE_LIMIT_POPUP: {
      return {
        ...state,
        showFileLimitPopup: action.payload
      };
    }

    case CHECK_DASHBOARD_LOAD: {
      return { ...state, isLoadingDashboard: true };
    }
    case DASHBOARD_DATA_LOADED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardData: action.payload
      };
    case GET_ACTIVE_MEMO_LIST_PENDING:
      return {
        ...state,
        activeMemoListLoading: true
      };
    case GET_ACTIVE_MEMO_LIST_FULFILLED: {
      return {
        ...state,
        activeMemoList: action.payload,
        activeMemoListLoading: false
      };
    }
    case GET_ACTIVE_MEMO_LIST_REJECTED: {
      return {
        ...state,
        activeMemoList: action.payload,

        activeMemoListLoading: false
      };
    }
    case SET_ACTIVE_MEMO_PARAMS: {
      return {
        ...state,
        activeMemoParams: action.payload
      };
    }
    case UPLOAD_MEMO_ATTACHMENT_PENDING:
      return {
        ...state,
        uploadMemoAttachmentLoading: true
      };
    case UPLOAD_MEMO_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        uploadMemoAttachmentLoading: false
      };
    }
    case UPLOAD_MEMO_ATTACHMENT_REJECTED: {
      return {
        ...state,
        uploadMemoAttachmentLoading: false
      };
    }
    case GET_MEMO_ATTACHMENT_LIST_PENDING:
      return {
        ...state,
        memoAttachmentListLoading: true
      };
    case GET_MEMO_ATTACHMENT_LIST_SUCCESS: {
      return {
        ...state,
        memoAttachmentListLoading: false,
        memoAttachmentList: action.payload
      };
    }
    case GET_MEMO_ATTACHMENT_LIST_REJECTED: {
      return {
        ...state,
        memoAttachmentListLoading: false
      };
    }
    case DELETE_MEMO_ATTACHMENT_PENDING:
      return {
        ...state,
        memoAttachmentDeleting: true
      };
    case DELETE_MEMO_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        memoAttachmentDeleting: false
      };
    }
    case DELETE_MEMO_ATTACHMENT_REJECTED: {
      return {
        ...state,
        memoAttachmentDeleting: false
      };
    }
    case GET_MEMO_FILES_LIST_PENDING: {
      return {
        ...state,
        memoFilesList: [],
        memoFilesListLoading: true
      };
    }
    case GET_MEMO_FILES_LIST_SUCCESS: {
      return {
        ...state,
        memoFilesList: action.payload,
        memoFilesListLoading: false
      };
    }
    case GET_MEMO_FILES_LIST_REJECTED: {
      return {
        ...state,
        memoFilesList: [],
        memoFilesListLoading: false
      };
    }

    case DELETE_MEMO_FILE_PENDING: {
      return { ...state, memoFilesListLoading: true };
    }

    case DELETE_MEMO_FILE_FULFILLED: {
      return { ...state, memoFilesListLoading: false };
    }

    case DELETE_MEMO_FILE_REJECTED: {
      return { ...state, memoFilesListLoading: false };
    }

    case POST_ACTIVE_MEMO_STATUS_PENDING: {
      return { ...state, activeMemoStatusChangeLoading: true };
    }
    case POST_ACTIVE_MEMO_STATUS_SUCCESS: {
      return { ...state, activeMemoStatusChangeLoading: false };
    }
    case POST_ACTIVE_MEMO_STATUS_REJECTED: {
      return { ...state, activeMemoStatusChangeLoading: false };
    }

    case GET_MEMO_SUMMARY_PENDING: {
      return { ...state, memoSummaryLoading: true };
    }

    case GET_MEMO_SUMMARY_SUCCESS: {
      return {
        ...state,
        memoSummaryLoading: false,
        memoSummaryData: action.payload
      };
    }

    case RESET_MEMO_SUMMARY_DATA: {
      return {
        ...state,
        memoSummaryLoading: false,
        memoSummaryData: initialState.memoSummaryData
      };
    }

    case GET_MEMO_SUMMARY_REJECTED: {
      return { ...state, memoSummaryLoading: false };
    }

    case GET_RECYCLED_MEMO_LIST_PENDING: {
      return {
        ...state,
        recycledMemoTotalCount: 0,
        recycledMemoList: [],
        recycledMemoListLoading: true
      };
    }
    case GET_RECYCLED_MEMO_LIST_SUCCESS: {
      return {
        ...state,
        recycledMemoTotalCount: action.payload.totalCount,
        recycledMemoList: action.payload.pagedRecords,
        recycledMemoListLoading: false
      };
    }
    case GET_RECYCLED_MEMO_LIST_REJECTED: {
      return {
        ...state,
        recycledMemoTotalCount: 0,
        recycledMemoList: [],
        recycledMemoListLoading: false
      };
    }

    case GET_RECYCLED_MEMO_FILTER_PENDING: {
      return {
        ...state,
        recycledMemoFilter: initialRecycledMemoFilter,
        recycledMemoFilterLoading: true
      };
    }
    case GET_RECYCLED_MEMO_FILTER_SUCCESS: {
      return {
        ...state,
        recycledMemoFilter: action.payload,
        recycledMemoFilterLoading: false
      };
    }
    case GET_RECYCLED_MEMO_FILTER_REJECTED: {
      return {
        ...state,
        recycledMemoFilter: initialRecycledMemoFilter,
        recycledMemoFilterLoading: false
      };
    }

    case UPDATE_MEMO_SHARING_LIST: {
      const { memoId, userNames } = action.payload;
      return {
        ...state,
        activeMemoList: {
          ...state.activeMemoList,
          pagedRecords: state.activeMemoList.pagedRecords.map(
            (memo: DTO.ActiveMemo) => {
              if (memo.memo_id !== memoId) return memo;

              return { ...memo, assigned_users: userNames };
            }
          )
        }
      };
    }

    case SET_ACTIVE_FILE_LIST_MEMO_DATA: {
      return {
        ...state,
        activeFileListMemoData: action.payload
      };
    }

    case SET_MEMO_FILE_SCAN_STATUS: {
      const {
        file_metadata_id: id,
        scan_status,
        file_type
      } = action.payload as DTO.WSCurrentMemoFilesScan;

      const getNewList = (oldList: DTO.MemoFile[]): DTO.MemoFile[] =>
        oldList.map((data) =>
          data.id === id ? { ...data, scan_status } : data
        );

      switch (file_type) {
        case 'file':
          return {
            ...state,
            memoFilesList: getNewList(state.memoFilesList)
          };
        case 'attachment':
          return {
            ...state,
            memoAttachmentList: getNewList(state.memoAttachmentList)
          };
        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export default memoReducer;
