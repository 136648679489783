import { combineReducers } from 'redux';

import global from '../global/global.reducer';
import user from './userReducer';
import memo from '../memo/memo.reducer';
import memoSharing from '../memoSharing/memoSharing.reducer';
import memoCreation from '../memoCreation/memoCreation.reducer';
import tree from '../tree/tree.reducer';
import treeVersion from '../treeVersion/treeVersion.reducer';
import treeAccess from '../treeAccess/treeAccess.reducer';
import file from '../file/file.reducer';
import clientIndustry from './clientIndustryReducer';
import table from '../table/table.reducer';
import feature from '../feature/feature.reducer';
import countrySettings from '../country-settings/country-settings.reducer';

const rootReducer = combineReducers({
  // short hand property names
  global,
  clientIndustry,
  user,
  memo,
  memoSharing,
  memoCreation,
  tree,
  treeVersion,
  treeAccess,
  file,
  table,
  feature,
  countrySettings
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
