import { addSeconds, isAfter, isSameSecond } from 'date-fns';

import { USER_AUTHORIZED } from 'shared/constants/loginConstants';

export function getFromLocalStorage<T = null>(key: string): T | null {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
}

export function getFromSessionStorage<T = null>(key: string): T | null {
  const localStorageValue = sessionStorage.getItem(key);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
}

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const setToLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, JSON.stringify(value));

export const setToSessionStorage = (key: string, value: any) =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const calculateEndTime = (time = 0) => {
  if (time === 0) {
    removeFromLocalStorage(USER_AUTHORIZED);
  }

  return addSeconds(new Date(), time).toISOString();
};

export const hasTimeExpired = (timeString: string) => {
  return (
    isAfter(new Date(), new Date(timeString)) ||
    isSameSecond(new Date(), new Date(timeString))
  );
};

export const getDifferenceInSecondsFromNow = (
  dateStringToSubtractSeconds: string
) => {
  const startDate = new Date();
  const endDate = new Date(dateStringToSubtractSeconds);
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
};
