export enum NodeType {
  Purpose = 'purpose',
  Background = 'background',
  Parent = 'parent',
  Child = 'child',
  Statement = 'statement'
}

export const initialTreeVersionInstance: DTO.TreeVersionInstance = {
  countries: [],
  node_set: {},
  leaf_nodes: [],
  root_node: '',
  tree_id: '',
  refilled: false,
  tree_version_created_timestamp: '',
  tree_version_creator_user_id: '',
  tree_version_id: '',
  tree_version_is_active: false,
  tree_version_number: 0,
  tree_version_draft_id: null
};
