import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function clientIndustryReducer(
  state = initialState.clientIndustry,
  action
) {
  switch (action.type) {
    case types.LOAD_ALL_CLIENT_INDUSTRY_SUCCESS:
      return {
        ...state,
        clientIndustryList: action.payload,
        isLoadedClientIndustryList: true,
        isLoadingClientIndustryList: false
      };
    case types.CHECK_ALL_CLIENT_INDUSTRY_LOADED:
      return {
        ...state,
        isLoadedClientIndustryList: false,
        isLoadingClientIndustryList: true
      };
    case types.LOAD_CLIENT_INDUSTRY_SUCCESS:
      return {
        ...state,
        clientIndustryInstance: action.payload
      };
    case types.CREATE_CLIENT_INDUSTRY_SUCCESS:
      return state;

    default:
      return state;
  }
}
