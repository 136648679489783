import { Reducer } from 'redux';

import {
  ACTIVATE_VERSION_SUCCESS,
  CHECK_CREATE_TREE_VERSION_LOADED,
  CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED,
  CHECK_CREATE_TREE_VERSION_ERROR,
  CREATE_TREE_VERSION_SUCCESS,
  LOAD_ACTIVATE_VERSION_LOADING,
  LOAD_ACTIVE_TREE_VERSION_SUCCESS,
  LOAD_ALL_TREE_VERSIONS_SUCCESS,
  LOAD_COUNTRIES_SUCCESS,
  LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS,
  LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING,
  LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS,
  TreeVersionActionTypes,
  TreeVersionState,
  GET_TREE_VERSION_DRAFT_PENDING,
  GET_TREE_VERSION_DRAFT_FULFILLED,
  GET_TREE_VERSION_DRAFT_REJECTED,
  POST_TREE_VERSION_DRAFT_PENDING,
  POST_TREE_VERSION_DRAFT_FULFILLED,
  POST_TREE_VERSION_DRAFT_REJECTED,
  DELETE_TREE_VERSION_DRAFT_PENDING,
  DELETE_TREE_VERSION_DRAFT_FULFILLED,
  DELETE_TREE_VERSION_DRAFT_REJECTED,
  LOAD_COUNTRIES_PENDING
} from './treeVersion.types';
import { initialTreeVersionInstance } from '../../shared/constants/treeConstants';
import { IMPORT_FILE_SUCCESS } from '../file/file.types';

const initialState: TreeVersionState = {
  treeVersionInstance: initialTreeVersionInstance,
  isTreeInstanceLoading: false,
  treeVersionList: [],
  countries: {},
  isActivateVersionLoading: false,
  isCountriesLoaded: false,
  isCountriesLoading: false,
  isSavedTreeVersionLoading: false,
  isTreeVersionListByTreeIdLoaded: false,
  isTreeVersionListByTreeIdLoading: false,
  savedTreeVersion: {},
  treeVersionListByTreeId: [],
  treeVersionDraft: null,
  treeVersionDraftLoading: false
};

const treeVersionReducer: Reducer<TreeVersionState, TreeVersionActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case LOAD_ALL_TREE_VERSIONS_SUCCESS:
      return {
        ...state,
        treeVersionList: action.payload
      };
    case LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING:
      return {
        ...state,
        isTreeVersionListByTreeIdLoaded: false,
        isTreeVersionListByTreeIdLoading: true
      };
    case LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS:
      return {
        ...state,
        treeVersionListByTreeId: action.payload,
        isTreeVersionListByTreeIdLoaded: true,
        isTreeVersionListByTreeIdLoading: false
      };
    case LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS:
      return {
        ...state,
        treeVersionInstance: action.payload[0],
        isTreeInstanceLoading: false
      };
    case CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED:
      return {
        ...state,
        isTreeInstanceLoading: true
      };
    case LOAD_ACTIVATE_VERSION_LOADING:
      return {
        ...state,
        isActivateVersionLoading: true
      };
    case ACTIVATE_VERSION_SUCCESS:
      return {
        ...state,
        isActivateVersionLoading: false
      };
    case LOAD_ACTIVE_TREE_VERSION_SUCCESS:
      return {
        ...state,
        treeVersionInstance: action.payload
      };
    case IMPORT_FILE_SUCCESS:
      return {
        ...state,
        treeVersionInstance: action.payload
      };
    case CREATE_TREE_VERSION_SUCCESS: {
      const savedTreeVersion = action.payload;
      savedTreeVersion.success = true;
      return {
        ...state,
        savedTreeVersion,
        isSavedTreeVersionLoading: false
      };
    }
    case CHECK_CREATE_TREE_VERSION_ERROR:
      return {
        ...state,
        isSavedTreeVersionLoading: false
      };
    case CHECK_CREATE_TREE_VERSION_LOADED:
      return {
        ...state,
        isSavedTreeVersionLoading: true
      };
    case LOAD_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        isCountriesLoaded: true,
        isCountriesLoading: false
      };
    case LOAD_COUNTRIES_PENDING:
      return {
        ...state,
        isCountriesLoading: action.payload
      };
    case GET_TREE_VERSION_DRAFT_PENDING:
      return {
        ...state,
        treeVersionDraftLoading: true
      };
    case GET_TREE_VERSION_DRAFT_FULFILLED:
      return {
        ...state,
        treeVersionDraft: action.payload,
        treeVersionDraftLoading: false
      };
    case GET_TREE_VERSION_DRAFT_REJECTED:
      return {
        ...state,
        treeVersionDraftLoading: false
      };

    case POST_TREE_VERSION_DRAFT_PENDING:
      return {
        ...state,
        treeVersionDraftLoading: true
      };
    case POST_TREE_VERSION_DRAFT_FULFILLED:
      return {
        ...state,
        treeVersionDraftLoading: false
      };
    case POST_TREE_VERSION_DRAFT_REJECTED:
      return {
        ...state,
        treeVersionDraftLoading: false
      };

    case DELETE_TREE_VERSION_DRAFT_PENDING:
      return {
        ...state,
        treeVersionDraftLoading: true
      };
    case DELETE_TREE_VERSION_DRAFT_FULFILLED:
      return {
        ...state,
        treeVersionDraft: null,
        treeVersionDraftLoading: false
      };
    case DELETE_TREE_VERSION_DRAFT_REJECTED:
      return {
        ...state,
        treeVersionDraftLoading: false
      };

    default:
      return state;
  }
};

export default treeVersionReducer;
