import React from 'react';
import { toast } from 'react-toastify';

import ToastScanFailed from '../../components/common/ToastScanFailed/ToastScanFailed';
import Toast from '../../components/common/Toast/Toast';
import { getFileScanStatusDescription } from './commonUtils';

export function toastSuccess(message: string) {
  return toast.success(<Toast type='success' message={message} />);
}

export function toastError(message: string) {
  return toast.error(<Toast type='error' message={message} />);
}

export function toastScanFailed(
  scanStatus: DTO.MemoFileScanStatus | undefined,
  onApply: VoidFunction
) {
  const message = getFileScanStatusDescription(scanStatus);
  if (!message) return;
  toast(<ToastScanFailed message={message} onApply={onApply} />, {
    pauseOnHover: true,
    closeButton: false,
    className: 'toastScanFailed'
  });
}
