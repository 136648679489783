import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { USER_PATH } from 'shared/constants/routesConstants';

import { selectShouldRedirectToFirstTabOfThePage } from './global.selectors';
import { setShouldGoToFirstTabOfThePage } from './global.actions';

export const useCallRedirectToFirstTab = (cb: VoidFunction) => {
  const callbackRef = useRef(cb);
  const firstMountRef = useRef<boolean>(true);
  callbackRef.current = cb;

  const trigger = useSelector(selectShouldRedirectToFirstTabOfThePage);

  useEffect(() => {
    if (!firstMountRef.current) {
      callbackRef.current();
    }
    firstMountRef.current = false;
  }, [trigger]);
};

const TabularViews = new Set<USER_PATH>([
  USER_PATH.MEMO,
  USER_PATH.TREE,
  USER_PATH.ADMIN
]);

export const useTrackNavToFirstTab = () => {
  const dispatch = useDispatch();

  return useCallback(
    (nextPath: USER_PATH) => {
      if (window.location.pathname === nextPath && TabularViews.has(nextPath)) {
        dispatch(setShouldGoToFirstTabOfThePage());
      }
    },
    [dispatch]
  );
};
