import { axiosService } from '../axios.service';

class additionalInfoApi {
  static saveMemoAdditionalInfo(
    memoAdditionalInfoList: MEMO.MemoAdditionalInfo[]
  ) {
    return axiosService
      .instance({
        method: 'post',
        url: 'memo_additional_info/',
        data: memoAdditionalInfoList
      })
      .then(({ data }) => data);
  }

  static getAdditionalInfo(key, node) {
    return axiosService
      .instance({
        url: `additional_info/?additional_info_title=${key}&node=${node}`
      })
      .then(({ data }) => data);
  }

  static getNodeInfo(node, treeVersion) {
    const treeVersionId = treeVersion;
    return axiosService
      .instance({
        url: `node/?node_number=${node}&tree_version=${treeVersionId}`
      })
      .then(({ data }) => data);
  }
}

export default additionalInfoApi;
