import _ from 'lodash';

import { USER_PATH } from 'shared/constants/routesConstants';

export const getAdditionalMemoInstance = (
  treeVersion: DTO.TreeVersionInstance
): MEMO.AdditionalInfoInstance => {
  const additionalInfoInstance: MEMO.AdditionalInfoInstance = {};

  _.entries(treeVersion.node_set).forEach(([_nodeKey, nodeValue]) => {
    const additionalInfoSet: Record<string, DTO.AdditionalInfo> = {};

    _.entries(nodeValue.additional_info_set).forEach(([infoKey, infoValue]) => {
      additionalInfoSet[infoKey] = { ...infoValue, checked: true };
    });

    additionalInfoInstance[nodeValue.node_number] = additionalInfoSet;
  });

  return additionalInfoInstance;
};

export const updateAdditionalInfoInstanceByKey = (
  additionalInfoInstance: MEMO.AdditionalInfoInstance,
  nodeNumber: number,
  key: string
) => {
  const newAdditionalInfoInstance = { ...additionalInfoInstance };
  const additionalInfoItem = newAdditionalInfoInstance[nodeNumber][key];
  const newAdditionalInfoItem = {
    ...additionalInfoItem,
    checked: !additionalInfoItem.checked
  };
  newAdditionalInfoInstance[nodeNumber][key] = newAdditionalInfoItem;

  return newAdditionalInfoInstance;
};

export const getEditMemoLinkWithTreeVersionId = (
  memoId: string | number,
  treeVersionId?: string
) =>
  `${USER_PATH.EDIT_MEMO.replace(':memoId', `${memoId}`)}${
    treeVersionId ? `?treeVersionId=${treeVersionId}` : ''
  }`;
