import {
  SET_IS_LOADED_TOKEN,
  LOAD_AUTHENTICATE_LOADING,
  LOAD_AUTHENTICATE_SUCCESS,
  LOAD_AUTHENTICATE_FAIL,
  LOAD_USERS_SUCCESS,
  LOAD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_ADMIN_SUCCESS,
  CHECK_APPROVAL_USERS_LOADING,
  LOAD_APPROVAL_USERS_SUCCESS,
  CHECK_USERS_LOADING,
  LOAD_ADMIN_DATA_SUCCESS,
  CHECK_ADMIN_DATA_LOADED,
  CHECK_UPDATE_ADMIN_LOADED,
  UPDATE_ADMIN_SUCCESS,
  SET_ADMIN_SEARCH_VALUE,
  SET_LAST_ADMIN_SEARCH_REQUEST_TIME,
  SET_USER_SETTINGS,
  SET_USER_SETTINGS_LOADING,
  ONBOARD_USERS_SUCCESS
} from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case SET_IS_LOADED_TOKEN:
      return {
        ...state,
        isTokenLoaded: true
      };
    case LOAD_AUTHENTICATE_LOADING:
      return {
        ...state,
        isLoading: true,
        isCurrentUserLoaded: false,
        errorStatus: null
      };
    case LOAD_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        isLoading: false,
        isCurrentUserLoaded: true,
        errorStatus: null
      };
    case LOAD_AUTHENTICATE_FAIL:
      return {
        ...state,
        currentUser: {},
        isLoading: false,
        isCurrentUserLoaded: true,
        errorStatus: action.payload
      };
    case CHECK_APPROVAL_USERS_LOADING:
      return {
        ...state,
        isUsersDataLoaded: false
      };
    case LOAD_APPROVAL_USERS_SUCCESS:
      return {
        ...state,
        usersData: action.payload,
        isUsersDataLoaded: true
      };
    case CHECK_USERS_LOADING:
      return {
        ...state,
        isUserDataListLoading: true,
        isUserDataListLoaded: false
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        userDataList: action.payload,
        isUserDataListLoading: false,
        isUserDataListLoaded: true
      };
    case LOAD_USER_SUCCESS:
      return { ...state, userDataInstance: action.payload };
    case DELETE_USER_SUCCESS:
    case DELETE_ADMIN_SUCCESS:
      return state;
    case LOAD_ADMIN_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        adminDataList: data,
        isAdminDataListLoading: false,
        isAdminDataListLoaded: true
      };
    }
    case CHECK_ADMIN_DATA_LOADED:
      return {
        ...state,
        isAdminDataListLoading: true,
        isAdminDataListLoaded: false
      };
    case CHECK_UPDATE_ADMIN_LOADED:
      return {
        ...state,
        isUpdateAdminLoading: action.payload
      };
    case UPDATE_ADMIN_SUCCESS: {
      const updatedAdmin = action.payload;
      const index = state.adminDataList.findIndex(
        (el) => el.user_email === updatedAdmin.user_email
      );
      const adminDataList = [...state.adminDataList];
      adminDataList[index] = updatedAdmin;
      return {
        ...state,
        adminDataList,
        isUpdateAdminLoading: false
      };
    }
    case ONBOARD_USERS_SUCCESS: {
      return {
        ...state,
        adminDataList: [...action.payload, ...state.adminDataList]
      };
    }
    case SET_ADMIN_SEARCH_VALUE:
      return {
        ...state,
        adminSearchValue: action.payload
      };
    case SET_LAST_ADMIN_SEARCH_REQUEST_TIME:
      return {
        ...state,
        lastAdminSearchRequestTime: action.payload
      };
    case SET_USER_SETTINGS:
      switch (action.payload.settingType) {
        case 'memoSectionAppearance':
          return {
            ...state,
            settings: {
              ...state.settings,
              memoAppearance: action.payload.data,
              memoAppearanceLoading: false
            }
          };

        default:
          return state;
      }

    case SET_USER_SETTINGS_LOADING:
      switch (action.payload.settingType) {
        case 'memoSectionAppearance':
          return {
            ...state,
            settings: {
              ...state.settings,
              memoAppearanceLoading: action.payload.loading
            }
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
