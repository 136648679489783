import { axiosService } from '../axios.service';

class clientIndustryApi {
  static getAllClientIndustry() {
    return axiosService
      .instance({
        url: 'industry/'
      })
      .then(({ data }) => data);
  }

  static getClientIndustry(clientIndustryId) {
    return axiosService
      .instance({
        url: `industry/${clientIndustryId}`
      })
      .then(({ data }) => data);
  }

  static postClientIndustry(clientIndustryData) {
    return axiosService
      .instance({
        url: 'industry/',
        data: clientIndustryData
      })
      .then(({ data }) => data);
  }
}

export default clientIndustryApi;
