import {
  IPageViewTelemetry,
  IEventTelemetry
} from '@microsoft/applicationinsights-web';

import {
  AppInsightsScreenTelemetryTypeE,
  AppInsightsEventTelemetryTypeE
} from './appInsights.enums';

export const getScreenTelemetry = (
  screen: AppInsightsScreenTelemetryTypeE
): IPageViewTelemetry => ({
  name: screen
});

export const getEventTelemetry = (
  screen: AppInsightsEventTelemetryTypeE
): IEventTelemetry => ({
  name: screen
});
