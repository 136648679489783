export const SET_TREE_VERSION_INSTANCE = 'SET_TREE_VERSION_INSTANCE';
export const SET_CURRENT_TREE_NAME = 'SET_CURRENT_TREE_NAME';
export const UPDATE_ADDITIONAL_INFO_INSTANCE =
  'UPDATE_ADDITIONAL_INFO_INSTANCE';
export const SET_ADDITIONAL_INFO_INSTANCE = 'SET_ADDITIONAL_INFO_INSTANCE';

export type MemoCreationState = Readonly<{
  treeVersionInstance: DTO.TreeVersionInstance;
  additionalInfoInstance: MEMO.AdditionalInfoInstance;
  currentTreeName: string;
}>;

export type MemoCreationActionTypes = any;
