import React, { FC, useEffect, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import cs from 'classnames';

import ClickAwayListener from 'react-click-away-listener';

import { useTrackNavToFirstTab } from 'store/global/global.hooks';
import { MenuItem } from 'shared/utils/commonUtils';
import './SidebarMenu.scss';

interface Props {
  menuItems: MenuItem[];
}

export const SidebarMenu: FC<Props> = ({ menuItems }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showName, setShowName] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpened) {
      timeoutRef.current = setTimeout(() => setShowName(true), 300);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowName(false);
    }
  }, [isOpened]);

  const trackNavToFirstTab = useTrackNavToFirstTab();

  return (
    <ClickAwayListener onClickAway={() => setIsOpened(false)}>
      <div className={cs('sidebar-menu')}>
        <div className='sidebar-menu__actions'>
          <div
            className='sidebar-menu__menu-button'
            role='presentation'
            onClick={() => {
              setIsOpened((opened) => !opened);
            }}
          >
            <div
              className={cs('sidebar-menu__hamburger', {
                'sidebar-menu__hamburger_close': isOpened
              })}
            >
              <div />
              <div />
              <div />
            </div>
          </div>
          <div
            className={cs('sidebar-menu__nav-list', {
              'sidebar-menu__nav-list--opened': isOpened
            })}
          >
            {menuItems.map((item: MenuItem) => {
              return (
                <NavLink
                  key={item.key}
                  to={item.url}
                  className='sidebar-menu__action'
                  activeClassName='sidebar-menu__action_active'
                  onClick={() => {
                    setIsOpened(false);
                    trackNavToFirstTab(item.url);
                  }}
                >
                  {item.icon}
                  {showName && (
                    <span className='sidebar-menu__item-name'>{item.name}</span>
                  )}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};
