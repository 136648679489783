import {
  AuthenticationResult,
  ClientAuthError,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  BrowserCacheLocation
} from '@azure/msal-browser';

import { COUNTRY_COOKIE_KEY } from 'shared/constants/commonConstants';
import { clearCookie, isLocalhost } from 'shared/utils/commonUtils';

const {
  AAD_CLIENT_ID = '',
  AUTHORITY_URL = '',
  AAD_TENANT_ID,
  APP_URL = ''
} = window.APP_CONFIG || {};

const msalLoginRequest: RedirectRequest = {
  scopes: ['User.Read']
};

class MsalAuthProvider extends PublicClientApplication {
  constructor() {
    super({
      auth: {
        clientId: AAD_CLIENT_ID,
        authority: `${AUTHORITY_URL}/${AAD_TENANT_ID}`,
        redirectUri: isLocalhost() ? `${APP_URL}/authcallback` : APP_URL,
        postLogoutRedirectUri: APP_URL,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: isLocalhost()
        ? {
            loggerOptions: {
              loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                  return;
                }
                switch (level) {
                  case LogLevel.Error:
                    console.error(message);
                    return;
                  case LogLevel.Info:
                    console.info(message);
                    return;
                  case LogLevel.Verbose:
                    console.debug(message);
                    return;
                  case LogLevel.Warning:
                    console.warn(message);
                }
              }
            }
          }
        : undefined
    });
    this.addEventCallback((event) => {
      if (!event.payload) return;

      const isLoginSuccess = event.eventType === EventType.LOGIN_SUCCESS;
      const isAcquireTokenSuccess =
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;

      if (isLoginSuccess || isAcquireTokenSuccess) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        this.setActiveAccount(account);
      }
    });
  }

  loginRedirect = async () => super.loginRedirect(msalLoginRequest);

  logoutRedirect = async () => {
    const account = this.getActiveAccount();
    clearCookie(COUNTRY_COOKIE_KEY);
    localStorage.clear();
    sessionStorage.clear();
    return super.logoutRedirect({
      account,
      postLogoutRedirectUri: this.config.auth.postLogoutRedirectUri,
      logoutHint: account?.idTokenClaims?.login_hint
    });
  };

  acquireTokenSilent = async () => {
    const account = this.getActiveAccount() || undefined;

    try {
      const res = await super.acquireTokenSilent({
        ...msalLoginRequest,
        account,
        forceRefresh: false
      });
      return res;
    } catch (error) {
      if (
        error instanceof InteractionRequiredAuthError ||
        error instanceof ClientAuthError
      ) {
        // fallback to interaction when silent call fails
        await super.acquireTokenRedirect({
          ...msalLoginRequest,
          loginHint: account?.idTokenClaims?.login_hint
        });
      }
      throw error;
    }
  };
}

export const authProvider = new MsalAuthProvider();
