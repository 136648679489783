export const START_REDIRECTION = '@global/START_REDIRECTION';
export const END_REDIRECTION = '@global/END_REDIRECTION';
export const SET_IS_FIRST_INIT = '@global/SET_IS_FIRST_INIT';
export const SET_GLOBAL_SETTING_PENDING = 'SET_GLOBAL_SETTING_PENDING';
export const SET_GLOBAL_SETTING_FULFILLED = 'SET_GLOBAL_SETTING_FULFILLED';
export const SET_GLOBAL_SETTING_REJECTED = 'SET_GLOBAL_SETTING_REJECTED';
export const SET_GLOBAL_ENVIRONMENT_CONFIG = 'SET_GLOBAL_ENVIRONMENT_CONFIG';
export const TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW =
  'TRACK_NAV_LINK_CLICKED_ON_TABULAR_VIEW';

export type GlobalState = Readonly<{
  redirect: boolean;
  redirectLink: string;
  isFirstInit: boolean;
  isUserAccessLoading: boolean;
  isAuditUserAccessAllowed: boolean;
  // INFO: should trigger redirection to the first tab on same page
  trackGoToFirstTabByNavLink: boolean;
}> &
  DTO.EnvironmentConfig;

export type LoadGlobalAction = Store.PaylodedAction<any>;

export type GlobalActionTypes = LoadGlobalAction;
